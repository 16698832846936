import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button } from 'reactstrap';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import DayOfWeekField from './Components/DayOfWeekField';
import DayOfMonthField from './Components/DayOfMonthField';
import MonthOfQuarterField from './Components/MonthOfQuarterField';
import WeekOfMonthField from './Components/WeekOfMonthField';
import MonthOfHalfYearField from './Components/MonthOfHalfYearField';
import MonthOfYearField from './Components/MonthOfYearField';
import { FormControl, FormLabel, TextField } from '@mui/material';
import * as constants from '../../../constants/RecurringTaskConstants';

export default function EditTaskRecurrenceModal(props) {
    const { open, close, currentRecurrenceString, setRecurrenceString, setSnackbarProperties } = props;

    const [currentMajorRecurrenceSelected, setCurrentMajorRecurrenceSelected] = useState('');
    const [dayOfWeek, setDayOfWeek] = useState('');
    const [dayOfMonth, setDayOfMonth] = useState('');
    const [quarterlyType, setQuarterlyType] = useState('');
    const [monthOfQuarter, setMonthOfQuarter] = useState('');
    const [weekOfMonth, setWeekOfMonth] = useState('');
    const [monthOfHalfYear, setMonthOfHalfYear] = useState('');
    const [monthOfYear, setMonthOfYear] = useState('');
    const [yearInterval, setYearInterval] = useState(1);

    function decodeRecurrenceString() {
        resetAllValues();
        const splitRecurrenceString = currentRecurrenceString.split('|');
        if(splitRecurrenceString[0] === constants.DAILY_TASK) {
            setCurrentMajorRecurrenceSelected(constants.DAILY_TASK);
        } else if(splitRecurrenceString[0] === constants.WEEKLY_TASK) {
            setCurrentMajorRecurrenceSelected(constants.WEEKLY_TASK);
            setDayOfWeek(splitRecurrenceString[1]);
        } else if(splitRecurrenceString[0] === constants.MONTHLY_TASK) {
            setCurrentMajorRecurrenceSelected(constants.MONTHLY_TASK);
            setDayOfMonth(splitRecurrenceString[1]);
        } else if(splitRecurrenceString[0] === constants.QUARTERLY_TASK) {
            setCurrentMajorRecurrenceSelected(constants.MONTHLY_TASK);
            if(splitRecurrenceString[1] === constants.NORMAL_QUARTERLY) {
                setQuarterlyType(constants.NORMAL_QUARTERLY);
                setMonthOfQuarter(splitRecurrenceString[2]);
                setDayOfMonth(splitRecurrenceString[3]);
            } else if(splitRecurrenceString[1] === constants.SPECIAL_QUARTERLY) {
                setQuarterlyType(constants.SPECIAL_QUARTERLY);
                setWeekOfMonth(splitRecurrenceString[2]);
                setDayOfWeek(splitRecurrenceString[3]);
                setMonthOfQuarter(splitRecurrenceString[4]);
            }
        } else if(splitRecurrenceString[0] === constants.SEMI_ANNUAL_TASK) {
            setCurrentMajorRecurrenceSelected(constants.SEMI_ANNUAL_TASK);
            setMonthOfHalfYear(splitRecurrenceString[1]);
            setDayOfMonth(splitRecurrenceString[2]);
        } else if(splitRecurrenceString[0] === constants.ANNUAL_TASK) {
            setCurrentMajorRecurrenceSelected(constants.ANNUAL_TASK);
            setMonthOfYear(splitRecurrenceString[1]);
            setDayOfMonth(splitRecurrenceString[2]);
        } else if(splitRecurrenceString[0] === constants.X_ANNUAL_TASK) {
            setCurrentMajorRecurrenceSelected(constants.X_ANNUAL_TASK);
            setMonthOfYear(splitRecurrenceString[1]);
            setDayOfMonth(splitRecurrenceString[2]);
            setYearInterval(splitRecurrenceString[3]);
        }
    }

    function resetAllValues() {
        setCurrentMajorRecurrenceSelected('');
        setDayOfWeek('');
        setDayOfMonth('');
        setQuarterlyType('');
        setMonthOfQuarter('');
        setWeekOfMonth('');
        setMonthOfHalfYear('');
        setMonthOfYear('');
        setYearInterval('');
    }

    function encodeRecurrenceString() {
        if(currentMajorRecurrenceSelected === constants.DAILY_TASK) {
            return constants.DAILY_TASK;
        } else if(currentMajorRecurrenceSelected === constants.WEEKLY_TASK) {
            return `${constants.WEEKLY_TASK}|${dayOfWeek}`;
        } else if(currentMajorRecurrenceSelected === constants.MONTHLY_TASK) {
            return `${constants.MONTHLY_TASK}|${dayOfMonth}`;
        } else if(currentMajorRecurrenceSelected === constants.QUARTERLY_TASK) {
            const startOfRecurrenceString = `${constants.QUARTERLY_TASK}|${quarterlyType}`;
            if(quarterlyType === constants.NORMAL_QUARTERLY) {
                return `${startOfRecurrenceString}|${monthOfQuarter}|${dayOfMonth}`;
            } else if(quarterlyType === constants.SPECIAL_QUARTERLY) {
                return `${startOfRecurrenceString}|${weekOfMonth}|${dayOfWeek}|${monthOfQuarter}`;
            }
        } else if(currentMajorRecurrenceSelected === constants.SEMI_ANNUAL_TASK) {
            return `${constants.SEMI_ANNUAL_TASK}|${monthOfHalfYear}|${dayOfMonth}`;
        } else if(currentMajorRecurrenceSelected === constants.ANNUAL_TASK) {
            return `${constants.ANNUAL_TASK}|${monthOfYear}|${dayOfMonth}`;
        } else if(currentMajorRecurrenceSelected === constants.X_ANNUAL_TASK) {
            return `${constants.X_ANNUAL_TASK}|${monthOfYear}|${dayOfMonth}|${yearInterval}`;
        } else {
            return '';
        }
    }

    function handleYearIntervalChange(event) {
        if(parseInt(event.target.value) < 2) {
            setYearInterval(2);
        } else {
            setYearInterval(event.target.value);
        }
    }

    function validate() {
        if(currentMajorRecurrenceSelected === constants.DAILY_TASK) {
            return true;
        } else if(currentMajorRecurrenceSelected === constants.WEEKLY_TASK) {
            return dayOfWeek !== undefined && dayOfWeek !== '';
        } else if(currentMajorRecurrenceSelected === constants.MONTHLY_TASK) {
            return dayOfMonth !== undefined && dayOfMonth !== '';
        } else if(currentMajorRecurrenceSelected === constants.QUARTERLY_TASK) {
            if(quarterlyType === constants.NORMAL_QUARTERLY) {
                return quarterlyType !== undefined && quarterlyType !== ''
                    && monthOfQuarter !== undefined && monthOfQuarter !== ''
                    && dayOfMonth !== undefined && dayOfMonth !== '';
            } else if(quarterlyType === constants.SPECIAL_QUARTERLY) {
                return quarterlyType !== undefined && quarterlyType !== ''
                    && weekOfMonth !== undefined && weekOfMonth !== ''
                    && dayOfWeek !== undefined && dayOfWeek !== ''
                    && monthOfQuarter !== undefined && monthOfQuarter !== '';
            }
        } else if(currentMajorRecurrenceSelected === constants.SEMI_ANNUAL_TASK) {
            return monthOfHalfYear !== undefined && monthOfHalfYear !== ''
                && dayOfMonth !== undefined && dayOfMonth !== '';
        } else if(currentMajorRecurrenceSelected === constants.ANNUAL_TASK) {
            return monthOfYear !== undefined && monthOfYear !== ''
            && dayOfMonth !== undefined && dayOfMonth !== '';
        } else if(currentMajorRecurrenceSelected === constants.X_ANNUAL_TASK) {
            return monthOfYear !== undefined && monthOfYear !== ''
            && dayOfMonth !== undefined && dayOfMonth !== ''
            && yearInterval !== undefined && yearInterval !== '';
        } else {
            return false;
        }
    }

    function handleSave() {
        if(validate()) {
            const recurrenceCode = encodeRecurrenceString();
            setRecurrenceString(recurrenceCode);
            close();
        } else {
            setSnackbarProperties('Validation error, ensure all required fields have a value', 'error')
        }

    }

    function handleCancel() {
        close();
    }

    useEffect(() => {
        if(open) {
            decodeRecurrenceString();
        }
    }, [open]);

    return (
        <Dialog open={open}
                sx={{ '& .MuiDialog-paper': { width: '100%' } }}
                maxWidth={'md'}
                fullWidth
        >
            <DialogTitle>
                Edit Recurrence
            </DialogTitle>
            <DialogContent>
                <RadioGroup
                    onChange={(event) => {
                        setCurrentMajorRecurrenceSelected(event.target.value);
                    }}
                    value={currentMajorRecurrenceSelected}
                >

                    <FormControlLabel value={constants.DAILY_TASK} control={<Radio />} label='Daily' />
                    <FormControlLabel value={constants.WEEKLY_TASK} control={<Radio />} label='Weekly' />
                    {currentMajorRecurrenceSelected === constants.WEEKLY_TASK && <Box sx={{marginLeft: '20px', padding: '15px'}} border={1} borderRadius={4}>
                        <DayOfWeekField value={dayOfWeek} onChange={(event) => setDayOfWeek(event.target.value)} />
                    </Box>}
                    <FormControlLabel value={constants.MONTHLY_TASK} control={<Radio />} label='Monthly' />
                    {currentMajorRecurrenceSelected === constants.MONTHLY_TASK && <Box sx={{marginLeft: '20px', padding: '15px'}} border={1} borderRadius={4}>
                        <DayOfMonthField value={dayOfMonth} onChange={(event) => setDayOfMonth(event.target.value)}/>
                    </Box>}
                    <FormControlLabel value={constants.QUARTERLY_TASK} control={<Radio />} label='Quarterly' />
                    {currentMajorRecurrenceSelected === constants.QUARTERLY_TASK && <Box sx={{marginLeft: '20px', padding: '15px'}} border={1} borderRadius={4}>
                        <RadioGroup onChange={(event) => setQuarterlyType(event.target.value)} value={quarterlyType}>
                            <FormControlLabel value={constants.NORMAL_QUARTERLY} control={<Radio />} label={'Normal'} />
                            {quarterlyType === constants.NORMAL_QUARTERLY && <Box>
                                <MonthOfQuarterField value={monthOfQuarter} onChange={(event) => setMonthOfQuarter(event.target.value)} />
                                <DayOfMonthField value={dayOfMonth} onChange={(event) => setDayOfMonth(event.target.value)} />
                            </Box>}
                            <FormControlLabel value={constants.SPECIAL_QUARTERLY} control={<Radio />} label={'Special'} />
                            {quarterlyType === constants.SPECIAL_QUARTERLY && <Box>
                                <WeekOfMonthField value={weekOfMonth} onChange={(event) => setWeekOfMonth(event.target.value)} />
                                <DayOfWeekField value={dayOfWeek} onChange={(event) => setDayOfWeek(event.target.value)} />
                                <MonthOfQuarterField value={monthOfQuarter} onChange={(event) => setMonthOfQuarter(event.target.value)} />
                            </Box> }
                        </RadioGroup>
                    </Box>}
                    <FormControlLabel value={constants.SEMI_ANNUAL_TASK} control={<Radio />} label='SemiAnnually' />
                    {currentMajorRecurrenceSelected === constants.SEMI_ANNUAL_TASK && <Box sx={{marginLeft: '20px', padding: '15px'}} border={1} borderRadius={4}>
                        <MonthOfHalfYearField value={monthOfHalfYear} onChange={(event) => setMonthOfHalfYear(event.target.value)} />
                        <DayOfMonthField value={dayOfMonth} onChange={(event) => setDayOfMonth(event.target.value)} />
                    </Box>}
                    <FormControlLabel value={constants.ANNUAL_TASK} control={<Radio />} label='Annually' />
                    {currentMajorRecurrenceSelected === constants.ANNUAL_TASK && <Box sx={{marginLeft: '20px', padding: '15px'}} border={1} borderRadius={4}>
                        <MonthOfYearField value={monthOfYear} onChange={(event) => setMonthOfYear(event.target.value)} />
                        <DayOfMonthField value={dayOfMonth} onChange={(event) => setDayOfMonth(event.target.value)} />
                    </Box>}
                    <FormControlLabel value={constants.X_ANNUAL_TASK} control={<Radio />} label='Yearly every X number of years' />
                    {currentMajorRecurrenceSelected === constants.X_ANNUAL_TASK && <Box sx={{marginLeft: '20px', padding: '15px'}} border={1} borderRadius={4}>
                        <MonthOfYearField value={monthOfYear} onChange={(event) => setMonthOfYear(event.target.value)} />
                        <DayOfMonthField value={dayOfMonth} onChange={(event) => setDayOfMonth(event.target.value)} />
                        <FormLabel>Year Interval</FormLabel>
                        <br/>
                        <FormControl sx={{m: 1, minWidth: 150}}>
                            <TextField type={'number'} value={yearInterval} onChange={handleYearIntervalChange} />
                        </FormControl>
                    </Box>}
                </RadioGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    )
}

EditTaskRecurrenceModal.propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    currentRecurrenceString: PropTypes.string.isRequired,
    setRecurrenceString: PropTypes.func.isRequired,
    setSnackbarProperties: PropTypes.func.isRequired,
};
