import { FormControl, FormLabel, Grid, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

export default function MonthOfQuarterField(props) {
    const { value, onChange } = props;

    const helperText = useMemo(() => {
        switch(value) {
        case 1:
            return 'i.e. January, April, July, October';
        case 2:
            return 'i.e. February, May, August, November';
        case 3:
            return 'i.e. March, June, September, December';
        }
    }, [value]);

    return(
        <Grid item>
            <FormLabel>Month of Quarter</FormLabel>
            <br/>
            <Grid sx={{display: 'flex'}}>
                <FormControl sx={{m: 1, minWidth: 150}}>
                    <Select
                        value={value}
                        onChange={onChange}
                    >
                        <MenuItem value={1}>1st</MenuItem>
                        <MenuItem value={2}>2nd</MenuItem>
                        <MenuItem value={3}>3rd</MenuItem>
                    </Select>
                </FormControl>
                <Typography sx={{fontStyle: 'italic', marginTop: '20px'}}>{helperText}</Typography>
            </Grid>

        </Grid>

    )
}

MonthOfQuarterField.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};
