import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import { Button, FormControl, Grid, Input, MenuItem, Select, TextField } from '@mui/material';
import { Label } from 'reactstrap';
import DialogActions from '@mui/material/DialogActions';
import { DataGridPro } from '@mui/x-data-grid-pro';
import CategoryApiService from '../../../api/CategoryApiService';
import { ok } from '../../../constants/apiStatusConstants';
import CustomColumnApiService from '../../../api/CustomColumnApiService';

const categoryApiService = new CategoryApiService();
const customColumnApiService = new CustomColumnApiService();

export default function EditCustomColumnModal (props) {
    const {
        open,
        currentCustomColumn,
        onCancel,
        onSave,
        setSnackbarProperties,
    } = props;
    const [customColumnName, setCustomColumnName] = useState('');
    const [dataType, setDataType] = useState('');
    const [dataLength, setDataLength] = useState(3600);
    const [description, setDescription] = useState('');
    const [columnOrder, setColumnOrder] = useState(1);
    const [inputType, setInputType] = useState('text');
    const [categories, setCategories] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [options, setOptions] = useState('');
    const [title, setTitle] = useState('Edit Custom Column');

    const dataTypes = ['string', 'boolean', 'int', 'date'];
    const inputTypes = ['text', 'textarea', 'select', 'checkbox', 'number', 'date'];
    const columns = [
        {
            field: 'ctName',
            headerName: 'Name',
            flex: 0.25,
        },
    ]

    useEffect(() => {
        if (open) {
            setCustomColumnName(currentCustomColumn?.customColumnName || '');
            setDataType(currentCustomColumn?.dataTypeFix || 'string');
            setDataLength(currentCustomColumn?.dataTypeLength || 3600);
            setDescription(currentCustomColumn?.description || '');
            setColumnOrder(currentCustomColumn?.columnOrder || 1);
            setOptions(currentCustomColumn?.options || '');

            if (currentCustomColumn?.inputType) {
                if (currentCustomColumn.inputType === 'option') {
                    setInputType('select');
                }
                else {
                    setInputType(currentCustomColumn.inputType)
                }
            }
            else {
                setInputType('text');
            }
            setSelectedCategories([]);

            populateCategories().then(() => {
                if (currentCustomColumn?.displayForCategory) {
                    setSelectedCategories(currentCustomColumn?.displayForCategory.split(','));
                }
                else {
                    setSelectedCategories([]);
                }
            });

            if (currentCustomColumn) {
                setTitle('Edit ' + currentCustomColumn.customColumnName + ' custom column');
            }
            else {
                setTitle('Create new custom column');
            }
        }
    }, [open, currentCustomColumn]);

    async function populateCategories () {
        let categoryResponse = await categoryApiService.GetCategories();
        setCategories(categoryResponse.data);
    }

    async function handleSave () {
        let newCustomColumn = getUpdatedCustomColumn();
        let customColumnResponse;

        if (!customColumnName)
        {
            setSnackbarProperties('Custom column name is required', 'error');
            return;
        }

        if (!currentCustomColumn) {
            customColumnResponse = await customColumnApiService.addCustomColumn(newCustomColumn);

            if (customColumnResponse.status === ok) {
                setSnackbarProperties('Successfully created new ' + customColumnName);
                onSave();
                return;
            }
        }
        else {
            newCustomColumn.customColumnId = currentCustomColumn.customColumnId;
            customColumnResponse = await customColumnApiService.updateCustomColumn(newCustomColumn);

            if (customColumnResponse.status === ok) {
                setSnackbarProperties('Successfully edited ' + customColumnName);
                onSave();
                return;
            }
        }

        setSnackbarProperties('Unable to save Custom Column, try again or contact an administrator', 'error');
    }

    function getUpdatedCustomColumn () {
        let displayCategories = selectedCategories.join(',');
        let finalDataType = dataType;

        if (dataType === 'string') {
            finalDataType = finalDataType + ',' + dataLength;
        }

        let finalInputType = inputType;

        if (finalInputType === 'select') {
            finalInputType = 'option';
        }

        return {
            customColumnName: customColumnName,
            displayForCategory: displayCategories,
            datatype: finalDataType,
            inputType: finalInputType,
            description: description,
            options: options,
            columnOrder: columnOrder,
        };
    }

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '175%' } }}
            maxWidth="lg"
            fullWidth
            open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Label>Custom Column Name</Label>
                                <TextField
                                    type="text"
                                    value={customColumnName}
                                    onChange={(event) => {
                                        setCustomColumnName(event.target.value);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Label>Data Type</Label>
                                <Select
                                    name="Data Type"
                                    value={dataType}
                                    onChange={(event) => {
                                        setDataType(event.target.value);
                                    }}
                                >
                                    {dataTypes.map((dataType) => (
                                        <MenuItem key={dataType} value={dataType}>
                                            {dataType}
                                        </MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {dataType === 'string' &&
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Label>Length</Label>
                                    <TextField
                                        type="number"
                                        value={dataLength}
                                        onChange={(event) => {
                                            setDataLength(parseInt(event.target.value));
                                        }}
                                    />
                                </FormControl>
                            </Grid>}
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Label>Input Type</Label>
                                <Select
                                    name="Input Type"
                                    value={inputType}
                                    onChange={(event) => {
                                        setInputType(event.target.value);
                                    }}
                                >
                                    {inputTypes.map((inputType) => (
                                        <MenuItem key={inputType} value={inputType}>
                                            {inputType}
                                        </MenuItem>))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {inputType === 'select' &&
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Label>Options (separate options with '|')</Label>
                                    <TextField
                                        type="text"
                                        value={options}
                                        onChange={(event) => {
                                            setOptions(event.target.value);
                                        }}
                                    />
                                </FormControl>
                            </Grid>}
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Label>Description</Label>
                                <TextField
                                    type="text"
                                    value={description}
                                    onChange={(event) => {
                                        setDescription(event.target.value);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Label>Column Order</Label>
                                <TextField
                                    type="number"
                                    value={columnOrder}
                                    onChange={(event) => {
                                        setColumnOrder(parseInt(event.target.value));
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Label>Task Categories</Label>
                                <Box sx={{ height: 350, width: '100%', display: 'block' }}>
                                    <DataGridPro
                                        autoHeight
                                        checkboxSelection
                                        rows={categories}
                                        columns={columns}
                                        getRowId={(row) => row.ctId}
                                        onSelectionModelChange={(params) => setSelectedCategories(params)}
                                        selectionModel={selectedCategories}
                                    />
                                </Box>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant="contained" onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}
