import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';

const ITEM_HEIGHT = 48;

const QueryEditMenuButton = (props) => {
    const { params, setCurrentSelectedQuery, setShowEditDialog, setShowDeleteDialog } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };
    const onEditClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking

        const api = params.api;
        const thisRow = {};

        api
            .getAllColumns()
            .filter((c) => c.field !== '__check__' && !!c)
            .forEach(
                (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
            );

        setCurrentSelectedQuery(thisRow.queryId);
        setShowEditDialog(true);
        handleClose();
    };

    const onDeleteClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking

        const api = params.api;
        const thisRow = {};

        api
            .getAllColumns()
            .filter((c) => c.field !== '__check__' && !!c)
            .forEach(
                (c) => (thisRow[c.field] = params.getValue(params.id, c.field)),
            );

        setCurrentSelectedQuery(thisRow.queryId);
        setShowDeleteDialog(true);
        handleClose();
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return <div>
        <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id="long-menu"
            MenuListProps={{
                'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                },
            }}
        >

            <MenuItem key={1} onClick={(e) => {
                onEditClick(e);
            }}>
                Edit
            </MenuItem>
            <MenuItem key={2} onClick={(e) => {
                onDeleteClick(e);
            }}>
                Delete
            </MenuItem>

        </Menu>
    </div>;
};

QueryEditMenuButton.propTypes = {
    params: PropTypes.object.isRequired,
    setCurrentSelectedQuery: PropTypes.func.isRequired,
    setShowEditDialog: PropTypes.func.isRequired,
    setShowDeleteDialog: PropTypes.func.isRequired,
};
export default QueryEditMenuButton;
