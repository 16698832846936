export class UserAdminEmailNotificationsDto {
    /**
     * @type{number}
     */
    usEnableNotifications;

    /**
     * @type{number}
     */
    usAutoSubscribe;

    /**
     * @type{number}
     */
    usAutoSubscribeOwnTasks;

    /**
     * @type{number}
     */
    usAutoSubscribeReportedTasks;

    /**
     * @type{number}
     */
    usSendNotificationsToSelf;

    /**
     * @type{number}
     */
    usReportedNotifications;

    /**
     * @type{number}
     */
    usAssignedNotifications;

    /**
     * @type{number}
     */
    usSubscribedNotifications;

    /**
     * @type{number}
     */
    usAutoSubscribeAuditorTasks;

    /**
     * @type{number}
     */
    usAutoSubscribeVerifierTasks;

    constructor () {
        this.usEnableNotifications = 1;
        this.usAutoSubscribe = 1;
        this.usAutoSubscribeOwnTasks = 1;
        this.usAutoSubscribeReportedTasks = 1;
        this.usReportedNotifications = 1;
        this.usAssignedNotifications = 1;
        this.usSubscribedNotifications = 1;
        this.usAutoSubscribeAuditorTasks = 1;
        this.usAutoSubscribeVerifierTasks = 1;
    }
}
