import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Button } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { DataGridPro } from '@mui/x-data-grid-pro';
import AuditChecklistHistoryApiService from '../../api/AuditChecklistHistoryApiService';
import { Link, useNavigate } from 'react-router-dom';

const auditChecklistHistoryApiService = new AuditChecklistHistoryApiService();
export default function AuditChecklistHistoryModal (props) {
    const {
        currentSelectedAuditChecklist,
        open,
        onCancel,
    } = props;

    const navigate = useNavigate();

    const [auditChecklists, setAuditChecklists] = useState([]);
    const [loading, setLoading] = useState(false);

    const columns = [
        {
            field: 'chCompletedBy',
            headerName: 'Completed By',
            flex: 1,
        },
        {
            field: 'chCompletionDatetime',
            headerName: 'Completed On',
            flex: 2,
        },
        {
            field: 'auditChecklistHistoryItems',
            headerName: 'Tasks Created',
            flex: 2,
            renderCell: (params) => {
                let tasksCreated = 0;

                for (const checklist of params.value) {
                    if (checklist.taskId) {
                        tasksCreated++;
                    }
                }

                return tasksCreated;
            }
        },
    ]

    const subGridColumns = [
        { field: 'hiPrompt', headerName: 'Prompt', flex: 2 },
        {
            field: 'isTaskCompleted',
            headerName: 'Passed?',
            flex: 0.5,
            renderCell: (params) => (params.row.taskId ? 'No' : 'Yes'),
        },
        {
            field: 'taskId',
            headerName: 'Task',
            flex: 1,
            renderCell: (params) => {
                return (
                    <Link
                        to={`/Task/${params.value}`}
                        style={{
                            textDecoration: 'underline',
                            color: 'blue',
                            cursor: 'pointer',
                        }}
                    >
                        {params.value}
                    </Link>
                );
            },
        }
    ]

    useEffect(() => {
        if (open) {
            setAuditChecklists([]);
            setLoading(true);
            auditChecklistHistoryApiService.getAuditChecklistHistory(currentSelectedAuditChecklist.acId).then((auditChecklistHistoryResponse) => {
                setAuditChecklists(auditChecklistHistoryResponse.data);
                setLoading(false);
            });
        }
    }, [open]);

    function handleCancel () {
        onCancel();
    }
    const CustomRowRenderer = ({ row: rowProp }) => (
        <div>
            <DataGridPro
                rows={rowProp.auditChecklistHistoryItems}
                columns={subGridColumns}
                getRowId={(row) => row.id}
                autoHeight
                disableColumnMenu
            />
        </div>
    );


    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '175%', height: '100vh' }}}
            maxWidth='xl'
            fullWidth
            open={open}>
            <DialogTitle>{currentSelectedAuditChecklist.acTitle} History</DialogTitle>
            <DialogTitle style={{ fontSize: '16px' }}>{currentSelectedAuditChecklist.projectName}</DialogTitle>
            <DialogContent dividers>
                <DataGridPro
                    rows={auditChecklists}
                    columns={columns}
                    loading={loading}
                    getRowId={(row) => row.chId}
                    getRowHeight={() => 'auto'}
                    getDetailPanelContent={CustomRowRenderer}
                />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
