import React from 'react';
import { ButtonGroup } from '@mui/material';
import { BootstrapButton } from './TaskButtonBarButton';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

const TaskHistoryButtonBar = ({ inlineImagesButtonText, changeHistoryButtonText, handleInlineImagesButton, handleChangeHistoryButton, style }) => {
    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            '& > *': {
                m: 1,
            },
        }}>
            <ButtonGroup
                style={style}
                size="small">
                <BootstrapButton variant="contained" size="small" onClick={handleInlineImagesButton}>{inlineImagesButtonText}</BootstrapButton>
                <BootstrapButton variant="contained" size="small" onClick={handleChangeHistoryButton}>{changeHistoryButtonText}</BootstrapButton>
            </ButtonGroup>
        </Box>
    );
};

TaskHistoryButtonBar.propTypes = {
    inlineImagesButtonText: PropTypes.string.isRequired,
    changeHistoryButtonText: PropTypes.string.isRequired,
    handleInlineImagesButton: PropTypes.func.isRequired,
    handleChangeHistoryButton: PropTypes.func.isRequired,
    style: PropTypes.object.isRequired,
};

export default TaskHistoryButtonBar;
