import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Box, Button, FormLabel, TextField } from '@mui/material';
import { Row } from 'reactstrap';
import DialogActions from '@mui/material/DialogActions';
import PriorityDto from '../../../dtos/Priorities/PriorityDto';
import { HexColorPicker } from 'react-colorful';
import PriorityApiService from '../../../api/PriorityApiService';

export default function ModifyPriorityModal(props) {
    const { onClose, open, selectedPriority, setSnackbarProperties, priorityApiService, onCancel } = props;

    const [priorityName, setPriorityName] = useState('');
    const [sortSequence, setSortSequence] = useState(0);
    const [backgroundColor, setBackgroundColor] = useState('');

    const [nameError, setNameError] = useState('');
    const [sortSequenceError, setSortSequenceError] = useState('');

    async function handleSave() {
        const priorityDto = new PriorityDto();
        priorityDto.prId = selectedPriority?.prId ?? 0;
        priorityDto.prName = priorityName;
        priorityDto.prSortSeq = sortSequence;
        priorityDto.prBackgroundColor = backgroundColor;

        if(validateInfo() && selectedPriority && selectedPriority.prId) {
            const updatedPriority = await priorityApiService.updatePriority(priorityDto);

            if(updatedPriority.success) {
                setSnackbarProperties('Updated Priority');
                resetModal();
            } else {
                setSnackbarProperties('Unable to update priority: ' + updatedPriority.message, 'error');
            }
        } else if(validateInfo()) {
            const newPriority = await priorityApiService.createPriority(priorityDto);

            if(newPriority.success) {
                setSnackbarProperties('Added new priority');
                resetModal();
            } else {
                setSnackbarProperties('Unable to add new priority: ' + newPriority.message, 'error');
            }
        }
    }

    function validateInfo() {
        let errorMessage = '';

        if(priorityName === null || priorityName === '') {
            errorMessage = 'Priority name is Required';
        }

        setNameError(!priorityName);

        if(sortSequence < 1) {
            errorMessage += (errorMessage.length > 0 ? ', ' : '') + 'Sort Sequence must be greater than 0';
        }

        setSortSequenceError(sortSequence < 1);

        if(backgroundColor === null || backgroundColor === '' || backgroundColor.length !== 7) {
            setBackgroundColor('#FFFFFF');
        }

        if(errorMessage.length > 0) {
            setSnackbarProperties('Priority validation failed: ' + errorMessage, 'error');
            return false;
        } else {
            return true;
        }
    }

    function resetModal() {
        setSortSequence(0);
        setPriorityName('');
        setBackgroundColor('');
        onClose();
    }

    useEffect(() => {
        if(open) {
            setPriorityName(selectedPriority?.prName ?? '');
            setSortSequence(selectedPriority?.prSortSeq ?? 0);
            setBackgroundColor(selectedPriority?.prBackgroundColor ?? '');
        }
    }, [selectedPriority, open])

    return(
        <Dialog
            open={open}
            sx={{ '& .MuiDialog-paper': { width: '100%' } }}
            maxWidth={'lg'}
            fullWidth
        >
            <DialogTitle>{selectedPriority?.prName ? 'Edit ' + selectedPriority.prName : 'Add New Priority'}</DialogTitle>
            <DialogContent dividers>
                <Box>
                    <Row>
                        <FormLabel>Priority Name</FormLabel>
                        <br/>
                        <TextField
                            error={nameError}
                            sx={{ width: '100%' }}
                            autoComplete={'Priority Name'}
                            value={priorityName}
                            onChange={(event) => setPriorityName(event.target.value)}
                        />
                    </Row>
                    <Row>
                        <FormLabel>Sort Sequence</FormLabel>
                        <br/>
                        <TextField
                            error={sortSequenceError}
                            sx={{ width: '100%' }}
                            type={'number'}
                            autoComplete={'Sort Sequence'}
                            value={sortSequence}
                            onChange={(event) => setSortSequence(event.target.value)}
                        />
                    </Row>
                    <br/>
                    <Row>
                        <FormLabel>Choose Background Color</FormLabel>
                        <HexColorPicker color={backgroundColor} onChange={setBackgroundColor} />
                        <TextField
                            sx={{ width: '100%' }}
                            value={backgroundColor}
                            onChange={(event) => setBackgroundColor(event.target.value)}
                        ></TextField>
                    </Row>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant={'contained'} onClick={onCancel}>Cancel</Button>
                <Button variant={'contained'} onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}

ModifyPriorityModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedPriority: PropTypes.objectOf(PriorityDto),
    setSnackbarProperties: PropTypes.func.isRequired,
    priorityApiService: PropTypes.objectOf(PriorityApiService).isRequired,
    onCancel: PropTypes.func.isRequired,
}
