import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CategoryDto from '../../../dtos/Categories/CategoryDto';
import CategoryApiService from '../../../api/CategoryApiService';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Box, Button, FormLabel, TextField } from '@mui/material';
import { Row } from 'reactstrap';
import DialogActions from '@mui/material/DialogActions';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function ModifyCategoryModal (props) {
    const { onClose, open, selectedCategory, setSnackbarProperties, categoryApiService, onCancel } = props;

    const [categoryName, setCategoryName] = useState('');
    const [sortSequence, setSortSequence] = useState(0);
    const [recurring, setRecurring] = useState(false);

    const [nameError, setNameError] = useState('');
    const [sortSequenceError, setSortSequenceError] = useState('');

    async function handleSave() {
        const categoryDto = new CategoryDto();
        categoryDto.ctId = selectedCategory?.ctId ?? 0;
        categoryDto.ctName = categoryName;
        categoryDto.ctSortSeq = sortSequence;
        categoryDto.recurring = recurring;

        if(validateInfo() && selectedCategory && selectedCategory.ctId) {
            const updatedCategory = await categoryApiService.updateCategory(categoryDto);

            if(updatedCategory.success) {
                setSnackbarProperties('Updated Category');
                resetModal();
            } else {
                setSnackbarProperties('Unable to update category: ' + updatedCategory.message, 'error');
            }
        } else if(validateInfo()) {
            const newCategory = await categoryApiService.createCategory(categoryDto);

            if(newCategory.success) {
                setSnackbarProperties('Added new category');
                resetModal();
            } else {
                setSnackbarProperties('Unable to add new category: ' + newCategory.message, 'error');
            }
        }
    }

    function validateInfo() {
        let errorMessage = '';

        if(categoryName === null || categoryName === '') {
            errorMessage = 'Category name is required';
            setNameError('Category name must not be empty');
        }

        setNameError(!categoryName);

        if(sortSequence < 1) {
            errorMessage += (errorMessage.length > 0 ? ', ' : '') + 'Sort Sequence must be greater than 0';
        }

        setSortSequenceError(sortSequence < 1);

        if(errorMessage.length > 0) {
            setSnackbarProperties('Priority validation failed: ' + errorMessage, 'error');
            return false;
        } else {
            return true;
        }
    }

    function resetModal() {
        setSortSequence(0);
        setCategoryName('');
        onClose();
    }

    useEffect(() => {
        if(open) {
            setCategoryName(selectedCategory?.ctName ?? '');
            setSortSequence(selectedCategory?.ctSortSeq ?? 0);
            setRecurring(selectedCategory?.recurring ?? false);
        }
    }, [selectedCategory, open])

    return(
        <Dialog
            open={open}
            sx={{ '& .MuiDialog-paper': { width: '100%' } }}
            maxWidth={'lg'}
            fullWidth
        >
            <DialogTitle>{selectedCategory?.ctName ? 'Edit ' + selectedCategory.ctName : 'Add New Category'}</DialogTitle>
            <DialogContent dividers>
                <Box>
                    <Row>
                        <FormLabel>Category Name</FormLabel>
                        <br/>
                        <TextField
                            error={nameError}
                            sx={{ width: '100%' }}
                            autoComplete={'Category Name'}
                            value={categoryName}
                            onChange={(event) => setCategoryName(event.target.value)}
                        />
                    </Row>
                    <Row>
                        <FormLabel>Sort Sequence</FormLabel>
                        <br/>
                        <TextField
                            error={sortSequenceError}
                            sx={{ width: '100%' }}
                            type={'number'}
                            autoComplete={'Sort Sequence'}
                            value={sortSequence}
                            onChange={(event) => setSortSequence(event.target.value)}
                        />
                    </Row>
                    <Row>
                        <FormControlLabel
                            control={<Checkbox checked={recurring}
                                               onChange={() => setRecurring(!recurring)}
                        />}
                            label="Recurring"
                        />
                    </Row>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant={'contained'} onClick={onCancel}>Cancel</Button>
                <Button variant={'contained'} onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}

ModifyCategoryModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedCategory: PropTypes.objectOf(CategoryDto),
    setSnackbarProperties: PropTypes.func.isRequired,
    categoryApiService: PropTypes.objectOf(CategoryApiService).isRequired,
    onCancel: PropTypes.func.isRequired,
}
