import React, { useEffect, useState } from 'react';
import EditMenuButton from '../../Modal/EditMenuButton';
import { Row } from 'reactstrap';
import { Box, Button, FormControl } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import ConfirmDeleteDialog from '../../Modal/ConfirmDelete';
import PropTypes from 'prop-types';
import StatusApiService from '../../../api/StatusApiService';
import ModifyStatusModal from './ModifyStatusModal';

const Statuses = (props) => {
    const { setSnackbarProperties } = props;

    const [statuses, setStatuses] = useState([]);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState();

    const columns = [
        {
            field: 'stId',
            headerName: 'Id',
            flex: 0.5,
        },
        {
            field: 'stName',
            headerName: 'Status Name',
            flex: 2,
        },
        {
            field: 'stSortSeq',
            headerName: 'Sort Sequence',
            flex: 2,
        },
        {
            field: 'edit',
            headerName: 'edit',
            flex: 0.5,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return <EditMenuButton
                    params={params}
                    setShowDeleteDialog={setShowDeleteDialog}
                    setCurrentSelectedRecord={setSelectedStatus}
                    onEditFunc={() => {
                        setShowEditDialog(true);
                        setSelectedStatus(params.row)
                    }}
                />;
            },
        }
    ];

    const statusApiService = new StatusApiService();

    async function getStatuses () {
        const statuses = await statusApiService.getStatuses();
        setStatuses(statuses.data);
    }

    async function onDeleteClick(value) {
        if (!value) {
            setShowDeleteDialog(false);
            return;
        }

        if (selectedStatus) {
            const deletedStatus = await statusApiService.deleteStatus(selectedStatus.stId);
            if (deletedStatus && deletedStatus.success) {
                setSnackbarProperties('Deleted Status', 'warning');
                await getStatuses();
            }
        }

        setShowDeleteDialog(false);
    }

    function handleEditClose() {
        setShowEditDialog(false);
        setSelectedStatus(null);
    }

    useEffect(() => {
        getStatuses();
    }, [showEditDialog])

    return(
        <div>
            <Row>
                <h2>Statuses</h2>
            </Row>
            <Row>
                <div style={{ display: 'inline-block', justifyContent: 'space-between' }}>
                    <FormControl sx={{ m: 1, minWidth: 120, float: 'left' }}>
                        <Button size={'large'} variant="contained" onClick={() => setShowEditDialog(true) }>Create new Status</Button>
                    </FormControl>
                </div>
            </Row>
            <Box sx={{ height: '80vh', width: '100%', display: 'block'}}>
                <DataGridPro
                    columns={columns}
                    rows={statuses}
                    getRowId={(row) => row.stId}
                    getRowHeight={() => 'auto'}
                />
            </Box>
            <ConfirmDeleteDialog
                onClose={onDeleteClick}
                open={showDeleteDialog}
            />
            <ModifyStatusModal
                open={showEditDialog}
                onClose={handleEditClose}
                selectedStatus={selectedStatus}
                setSnackbarProperties={setSnackbarProperties}
                statusApiService={statusApiService}
                onCancel={handleEditClose}
            />
        </div>
    );
}

Statuses.propTypes = {
    setSnackbarProperties: PropTypes.func,
}

export default Statuses;
