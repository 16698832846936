import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import SimpleOrganizationDto from '../../../dtos/Organizations/SimpleOrganizationDto';
import { useEffect, useState } from 'react';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

export default function QueryOrgSelection(props) {
    const { open, selectedOrgs, setSelectedOrgs, selectableOrgs, close } = props;

    const[currentSelectedOrgs, setCurrentSelectedOrgs] = useState(selectedOrgs);

    function closeModal() {
        close();
    }

    function clickDone() {
        setSelectedOrgs(currentSelectedOrgs);
        closeModal();
    }

    useEffect(() => {
        if(open) {
            setCurrentSelectedOrgs(selectedOrgs);
        }
    }, [open]);

    return(
        <Dialog open={open}>
            <DialogTitle>Select Organizations</DialogTitle>
            <DialogContent>
                <FormControl sx={{m: 1, width: 300}}>
                    <Autocomplete
                        multiple={true}
                        selectOnFocus
                        clearOnBlur
                        options={selectableOrgs}
                        autoHighlight
                        getOptionLabel={(option) => option.ogName}
                        value={currentSelectedOrgs}
                        onChange={(event, newValue) => setCurrentSelectedOrgs(newValue)}
                        renderOption={(props, thisOption) => {
                            return <MenuItem key={thisOption.ogId} value={thisOption.ogId} {...props}>
                                <Checkbox checked={currentSelectedOrgs.some((org) => org.ogId === thisOption.ogId)} />
                                <ListItemText primary={thisOption.ogName} />
                            </MenuItem>;
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label={'Organizations'}/>
                        )}

                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} onClick={closeModal}>Cancel</Button>
                <Button variant={'contained'} onClick={clickDone}>Done</Button>
            </DialogActions>
        </Dialog>
    )
}

QueryOrgSelection.propTypes = {
    open: PropTypes.bool.isRequired,
    selectedOrgs: PropTypes.arrayOf(SimpleOrganizationDto).isRequired,
    setSelectedOrgs: PropTypes.func.isRequired,
    selectableOrgs: PropTypes.arrayOf(SimpleOrganizationDto).isRequired,
    close: PropTypes.func.isRequired,
};
