export default class BasicUserDto {
    /**
     * @type {number}
     */
    userId;

    /**
     * @type {string}
     */
    username;

    /**
     *
     * @param userId {number}
     * @param username {string}
     */
    constructor (userId, username) {
        this.userId = userId;
        this.username = username;
    }
}
