import React, { useEffect, useState } from 'react';
import { FormLabel, Grid, MenuItem, Select, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Textarea } from '@mui/joy';
import { UserAdminMainSettingsDto } from '../../../../dtos/Users/UserAdminMainSettingsDto';
import TaskQueryDto from '../../../../dtos/TaskQueries/TaskQueryDto';
import TaskQueryApiService from '../../../../api/TaskQueryApiService';

export default function UserMainSettings (props) {
    const {
        userId, userAdminMainSettings, setUserAdminMainSettings, orgs, checkProjectUserXRefs, orgError, usernameError, passwordError, firstNameError,
        lastNameError, isCurrentUserSuperAdmin, userPassword, setUserPassword, confirmPassword, setConfirmPassword, editAdmin, setSnackbarProperties
    } = props;

    const[possibleTaskQueries, setPossibleTaskQueries] = useState([]);

    const taskQueryApiService = new TaskQueryApiService();

    const handleFieldChange = (field, value) => {
        const updatedSettings = { ...userAdminMainSettings };

        updatedSettings[field] = value;

        setUserAdminMainSettings(updatedSettings);
    };

    async function getPossibleTaskQueries() {
        if(userAdminMainSettings.usOrg) {
            const response = await taskQueryApiService.getTaskQueriesForOrg(userAdminMainSettings.usOrg);
            if(response && response.data) {
                setPossibleTaskQueries(response.data);
            } else {
                setSnackbarProperties('Unable to get task queries', 'error');
            }
        }

    }

    useEffect(() => {
        void getPossibleTaskQueries();
    }, [userAdminMainSettings]);

    const gridItemSx = {marginBottom: '20px', width: '100%'};

    return (
        <Grid container spacing={2}>
            { editAdmin && <Grid item sx={gridItemSx}>
                <FormLabel error={orgError}>Organization</FormLabel>
                <Select
                    error={orgError}
                    sx={{ width: 1 }}
                    value={userAdminMainSettings?.usOrg}
                    onChange={(event) => {
                        checkProjectUserXRefs(event.target.value);
                        handleFieldChange('usOrg', event.target.value);
                    }}
                >
                    {orgs.map((org) => {
                        return (
                            <MenuItem
                                key={org.ogId}
                                value={org.ogId}
                            >
                                {org.ogName}
                            </MenuItem>
                        );
                    })}
                </Select>
            </Grid>
            }

            <Grid item sx={gridItemSx}>
                {isCurrentUserSuperAdmin && editAdmin &&
                    <FormControlLabel control={<Checkbox checked={Boolean(userAdminMainSettings.usAdmin)} onChange={() => { handleFieldChange('usAdmin', userAdminMainSettings.usAdmin ? 0 : 1); }}/>} label="Super Admin" />}
            </Grid>
            <Grid item sx={gridItemSx}>
                {isCurrentUserSuperAdmin && editAdmin &&
                    <FormControlLabel control={<Checkbox checked={Boolean(userAdminMainSettings.isOrgAdmin)} onChange={() => { handleFieldChange('isOrgAdmin', !userAdminMainSettings.isOrgAdmin); }}/>} label="Organization Admin" />}
            </Grid>
            <Grid item sx={gridItemSx}>
                <FormLabel error={usernameError}>Username</FormLabel>
                <br/>
                <TextField
                    disabled={!editAdmin}
                    error={usernameError}
                    sx={{ width: '100%' }}
                    autoComplete='new-password'
                    value={userAdminMainSettings.usUsername}
                    onChange={(event) => { handleFieldChange('usUsername', event.target.value); }}
                />
            </Grid>
            {
                userId === 0 &&
                <Grid>
                    <Grid item sx={gridItemSx}>
                        <FormLabel error={passwordError}>Password</FormLabel>
                        <br/>
                        <TextField
                            error={passwordError}
                            sx={{ width: '100%' }}
                            type='password'
                            autoComplete='new-password'
                            value={userPassword}
                            onChange={(event) => { setUserPassword(event.target.value); }}
                        />
                    </Grid>
                    <br/>
                    <Grid item sx={gridItemSx}>
                        <FormLabel error={passwordError}>Confirm Password</FormLabel>
                        <br/>
                        <TextField
                            error={passwordError}
                            sx={{ width: '100%' }}
                            type='password'
                            autoComplete='off'
                            value={confirmPassword}
                            onChange={(event) => { setConfirmPassword(event.target.value); }}
                        />
                    </Grid>
                    <br/>
                </Grid>
            }
            <Grid item sx={gridItemSx}>
                <FormLabel error={firstNameError}>First Name</FormLabel>
                <br/>
                <TextField
                    error={firstNameError}
                    sx={{ width: '100%' }}
                    value={userAdminMainSettings.usFirstname}
                    onChange={(event) => handleFieldChange('usFirstname', event.target.value)}
                />
            </Grid>
            <Grid item sx={gridItemSx}>
                <FormLabel error={lastNameError}>Last Name</FormLabel>
                <br/>
                <TextField
                    error={lastNameError}
                    sx={{ width: '100%' }}
                    value={userAdminMainSettings.usLastname}
                    onChange={(event) => { handleFieldChange('usLastname', event.target.value); }}
                />
            </Grid>
            { editAdmin && <Grid item sx={gridItemSx}>
                <FormControlLabel control={<Checkbox checked={userAdminMainSettings.usActive} onChange={() => { handleFieldChange('usActive', !userAdminMainSettings.usActive); }}/>} label="Active" />
            </Grid> }
            { editAdmin && <Grid item sx={gridItemSx}>
                <FormControlLabel control={<Checkbox checked={userAdminMainSettings.usAddEnabled} onChange={() => { handleFieldChange('usAddEnabled', !userAdminMainSettings.usAddEnabled); }}/>} label="Allowed to add new tasks" />
            </Grid> }
            <Grid item sx={gridItemSx}>
                <FormLabel>Default Task Query</FormLabel>
                <Select
                    disabled={!userAdminMainSettings?.usOrg}
                    sx={{ width: 1 }}
                    value={userAdminMainSettings.usDefaultQuery}
                    onChange={(event) => {
                        handleFieldChange('usDefaultQuery', event.target.value);
                    }}
                >
                    {possibleTaskQueries.map((taskQuery) => {
                        return (
                            <MenuItem
                                key={taskQuery.queryId}
                                value={taskQuery.queryId}
                            >
                                {taskQuery.queryName}
                            </MenuItem>
                        );
                    })}
                </Select>
            </Grid>
            <Grid item sx={gridItemSx}>
                <FormLabel>Tasks Per Page</FormLabel>
                <br/>
                <TextField
                    sx={{ width: '100%' }}
                    inputProps={{ inputProps: { min: 0 } }}
                    type='number'
                    value={userAdminMainSettings.usTasksPerPage}
                    onChange={(event) => { handleFieldChange('usTasksPerPage', event.target.value); }}
                />
            </Grid>
            <Grid item sx={gridItemSx}>
                <FormLabel>Email</FormLabel>
                <br/>
                <TextField
                    disabled={!editAdmin}
                    sx={{ width: '100%' }}
                    value={userAdminMainSettings.usEmail}
                    onChange={(event) => { handleFieldChange('usEmail', event.target.value); }}
                />
            </Grid>
            <Grid item sx={gridItemSx}>
                <FormLabel>Outgoing Email Signature</FormLabel>
                <br/>
                <Textarea
                    minRows={3}
                    value={userAdminMainSettings.usSignature}
                    onChange={(event) => { handleFieldChange('usSignature', event.target.value); }}
                />
            </Grid>
        </Grid>
    );
}

UserMainSettings.propTypes = {
    userAdminMainSettings: PropTypes.objectOf(UserAdminMainSettingsDto).isRequired,
    setUserAdminMainSettings: PropTypes.func.isRequired,
    orgs: PropTypes.array,
    userPassword: PropTypes.string,
    setUserPassword: PropTypes.func,
    confirmPassword: PropTypes.string,
    setConfirmPassword: PropTypes.func,
    checkProjectUserXRefs: PropTypes.func,
    orgError: PropTypes.bool,
    usernameError: PropTypes.bool,
    passwordError: PropTypes.bool,
    firstNameError: PropTypes.bool.isRequired,
    lastNameError: PropTypes.bool.isRequired,
    isCurrentUserSuperAdmin: PropTypes.bool.isRequired,
    editAdmin: PropTypes.bool.isRequired,
    taskQueries: PropTypes.arrayOf(TaskQueryDto),
};
