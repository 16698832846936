import React from 'react';
import { ButtonGroup } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SkipPreviousOutlinedIcon from '@mui/icons-material/SkipPreviousOutlined';
import SkipNextOutlinedIcon from '@mui/icons-material/SkipNextOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SubscriptionsOutlinedIcon from '@mui/icons-material/SubscriptionsOutlined';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import { BootstrapButton } from './TaskButtonBarButton';
import PropTypes from 'prop-types';

const TaskButtonBar = ({
    nextTaskId,
    previousTaskId,
    onDeleteClick,
    handleCopyTask,
    handlePrintTask,
    handleSubscribers,
    handleGetNotifications,
    handleRelationships,
    handleSendEmail,
    handleAddAttachment,
    currentTaskId,
    handleNewTask,
    isAdmin,
    isSubscribed,
}) => {
    const isTaskIdNull = (currentTaskId === undefined || currentTaskId <= 0 || isNaN(currentTaskId));

    return (
        <div className={'task-button-bar'}>
            <ButtonGroup
                size="small">
                <BootstrapButton
                    variant="contained"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={handleNewTask}
                >
                        Add New Task
                </BootstrapButton>
                <BootstrapButton
                    variant="contained"
                    size="small"
                    startIcon={<SkipPreviousOutlinedIcon />}
                    href={'/Task/' + previousTaskId}
                    disabled={isTaskIdNull || previousTaskId === undefined || previousTaskId < 1}
                >
                        Previous
                </BootstrapButton>
                <BootstrapButton
                    variant="contained"
                    size="small"
                    startIcon={<SkipNextOutlinedIcon />}
                    href={'/Task/' + nextTaskId}
                    disabled={isTaskIdNull || nextTaskId === undefined || nextTaskId < 1}
                >
                    Next
                </BootstrapButton>
                <BootstrapButton
                    variant="contained"
                    size="small"
                    startIcon={<ContentCopyOutlinedIcon /> }
                    disabled={isTaskIdNull}
                    onClick={handleCopyTask}
                >
                    Create Copy
                </BootstrapButton>
                <BootstrapButton
                    variant="contained"
                    size="small"
                    startIcon={<PrintOutlinedIcon />}
                    disabled={isTaskIdNull}
                    onClick={handlePrintTask}
                >
                    Print
                </BootstrapButton>
                <BootstrapButton
                    variant="contained"
                    size="small"
                    startIcon={<DeleteOutlineOutlinedIcon />}
                    onClick={onDeleteClick}
                    disabled={isTaskIdNull}
                >
                    Delete
                </BootstrapButton>
                <BootstrapButton
                    variant="contained"
                    size="small"
                    startIcon={<SubscriptionsOutlinedIcon />}
                    disabled={isTaskIdNull || !isAdmin}
                    onClick={handleSubscribers}
                >
                    Subscribers
                </BootstrapButton>
                <BootstrapButton
                    variant="contained"
                    size="small"
                    startIcon={<NotificationsActiveOutlinedIcon />}
                    disabled={isTaskIdNull}
                    onClick={handleGetNotifications}
                >
                    {isSubscribed ? 'Stop Notifications' : 'Get Notifications'}
                </BootstrapButton>
                <BootstrapButton
                    variant="contained"
                    size="small"
                    startIcon={<LinkOutlinedIcon />}
                    disabled={isTaskIdNull}
                    onClick={handleRelationships}
                >
                    Relationships
                </BootstrapButton>
                <BootstrapButton
                    variant="contained"
                    size="small"
                    startIcon={<EmailOutlinedIcon />}
                    disabled={true || isTaskIdNull}
                    onClick={handleSendEmail}
                >
                    Send Email
                </BootstrapButton>
                <BootstrapButton
                    variant="contained"
                    size="small"
                    startIcon={<AttachFileOutlinedIcon />}
                    onClick={handleAddAttachment}
                    disabled={isTaskIdNull}
                >
                    Add Attachment
                </BootstrapButton>
            </ButtonGroup>
        </div>
    );
};

TaskButtonBar.propTypes = {
    nextTaskId: PropTypes.number,
    previousTaskId: PropTypes.number,
    onDeleteClick: PropTypes.func.isRequired,
    handleCopyTask: PropTypes.func.isRequired,
    handlePrintTask: PropTypes.func.isRequired,
    handleSubscribers: PropTypes.func.isRequired,
    handleGetNotifications: PropTypes.func.isRequired,
    handleRelationships: PropTypes.func.isRequired,
    handleSendEmail: PropTypes.func.isRequired,
    handleAddAttachment: PropTypes.func.isRequired,
    currentTaskId: PropTypes.number,
    handleNewTask: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isSubscribed: PropTypes.bool.isRequired,
};

export default TaskButtonBar;
