import apiService from './apiService';

export default class PriorityApiService {
    /**
     * Gets all priorities
     * @return {Promise<ServiceResponse<Array<PriorityDto>>>}
     */
    async GetPriorities() {
        const priorities = await apiService.getData('api/Priority');

        return priorities.data;
    }

    /**
     * Add a new priority
     * @param priorityDto {PriorityDto}
     * @return {Promise<ServiceResponse<number>>}
     */
    async createPriority(priorityDto) {
        const newId = await apiService.postData('api/Priority', priorityDto);

        return newId.data;
    }

    /**
     * Update a priority
     * @param priorityDto {PriorityDto}
     * @return {Promise<ServiceResponse<PriorityDto>>}
     */
    async updatePriority(priorityDto) {
        const updatedPriority = await apiService.putData('api/Priority', priorityDto);

        return updatedPriority.data;
    }

    /**
     * Delete a priority
     * @param priorityId {number}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async deletePriority(priorityId) {
        const deletedPriority = await apiService.deleteData('api/Priority/' + priorityId);

        return deletedPriority.data;
    }
}
