import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { FormLabel } from '@mui/material';

export default function ConfirmDeleteDialogHazard (props) {
    const { onClose, open, deleteTitle, deleteLabel } = props;

    const handleCancel = () => {
        onClose(false);
    };

    const handleOk = () => {
        onClose(true);
    };

    const getDialogTitle = () => {
        return deleteTitle || 'Confirm Delete';
    };

    const getDeleteLabel = () => {
        return deleteLabel || 'Are you sure you wish to delete?';
    };

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 435 } }}
            maxWidth="xs"
            open={open}
        >
            <DialogTitle>{getDialogTitle()}</DialogTitle>
            <DialogContent dividers>
                <FormLabel>{getDeleteLabel()}</FormLabel>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel}>
                    Cancel
                </Button>
                <Button onClick={handleOk} color="error">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmDeleteDialogHazard.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    deleteTitle: PropTypes.string.isRequired,
    deleteLabel: PropTypes.string.isRequired,
};
