import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { FormLabel, TextField, MenuItem, Grid } from '@mui/material';
import SkipNextOutlinedIcon from '@mui/icons-material/SkipNextOutlined';
import FastForwardIcon from '@mui/icons-material/FastForward';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import SkipPreviousOutlinedIcon from '@mui/icons-material/SkipPreviousOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TaskQueryApiService from '../../../api/TaskQueryApiService';
import StatusApiService from '../../../api/StatusApiService';
import * as queryConstants from '../../../constants/queryConstants';
import { Row } from 'reactstrap';
import authenticationManager from '../../../auth/AuthenticationManager';
import OrgApiService from '../../../api/OrgApiService';
import QueryOrgSelection from './QueryOrgSelection';

export default function QueryColumnSelection (props) {
    const { open, setIsOpen, queryId, setSnackbarProperties, ...other } = props;

    const [notSelectedColumns, setNotSelectedColumns] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [possibleNotSelectedColumns, setPossibleNotSelectedColumns] = useState([]);
    const [possibleSelectedColumns, setPossibleSelectedColumns] = useState([]);
    const [queryDescription, setQueryDescription] = useState('');
    const [queryName, setQueryName] = useState('');
    const [isPrivateQuery, setIsPrivateQuery] = useState(false);
    const [isPublicQuery, setIsPublicQuery] = useState(false);
    const [isWhereAssignedToMe, setIsWhereAssignedToMe] = useState(false);
    const [hasStatusFilter, setHasStatusFilter] = useState(false);
    const [statusIdFilter, setStatusIdFilter] = useState(0);
    const [statuses, setStatuses] = useState([]);
    const [modalTitle, setModalTitle] = useState('');
    const [hasCreatedWithinFilter, setHasCreatedWithinFilter] = useState(false);
    const [createdWithinFilterDays, setCreatedWithinFilterDays] = useState(0);
    const [isNotStatusFilter, setIsNotStatusFilter] = useState(false);
    const [isOverdueFilter, setIsOverdueFilter] = useState(false);
    const [selectableOrgs, setSelectableOrgs] = useState([]);
    const [selectedOrgs, setSelectedOrgs] = useState([]);
    const [openOrgSelection, setOpenOrgSelection] = useState(false);

    const [queryNameError, setQueryNameError] = useState(false);

    const selectSizeClassName = 'col-xs-5';
    const selectButtonsSizeClassName = 'col-xs-2';

    const taskQueryApiService = new TaskQueryApiService();
    const statusApiService = new StatusApiService();
    const orgApiService = new OrgApiService();

    const handleCancel = () => {
        setIsOpen(false);
        resetModal();
    };

    async function getColumnsForQuery (id) {
        const possibleColumns = await populateAllPossibleColumns();
        const response = await taskQueryApiService.getTaskQuery(id);

        /**
         * @type {?TaskQueryDataDto}
         */
        const data = response.data;

        const taskColumnsArray = data.taskColumns.split(',');
        const allTaskColumns = [];

        taskColumnsArray.forEach((value) => allTaskColumns.push(value));

        const theseNotSelectedColumns = possibleColumns.slice();

        allTaskColumns.forEach((value) => theseNotSelectedColumns.splice(theseNotSelectedColumns.indexOf(value), 1));

        if (data.taskWhere) {
            parseWhereClauses(data.taskWhere);
        }

        setPossibleSelectedColumns(allTaskColumns);
        setPossibleNotSelectedColumns(theseNotSelectedColumns);
        setSelectedColumns([]);
        setNotSelectedColumns([]);
        setQueryName(data.taskQueryName);
        setQueryDescription(data.taskQueryDescription);
        setIsPrivateQuery(data.privateQuery);
        setIsPublicQuery(data.publicQuery);
        setSelectedOrgs(data.organizations);
    }

    async function populateAllPossibleColumns () {
        const response = await taskQueryApiService.getAllQueryColumns();
        const data = response.data;
        const parsedData = data.split(',');
        const processedParsedData = [];

        parsedData.forEach((value) => {
            if (value !== '') {
                processedParsedData.push(value);
            }
        });

        return processedParsedData;
    }

    async function getStatuses () {
        const response = await statusApiService.getStatuses();
        setStatuses(response.data);
    }

    function parseWhereClauses (whereClauses) {
        const clauses = whereClauses.split(',');

        clauses.forEach((value) => {
            if (value === queryConstants.AssignedToMe) {
                setIsWhereAssignedToMe(true);
            }

            if (value.includes(queryConstants.WhereStatus)) {
                const statusFilter = value.split('=');
                if (statusFilter.length === 2) {
                    setHasStatusFilter(true);
                    setStatusIdFilter(statusFilter[1]);
                    setIsNotStatusFilter(statusFilter[0].includes('!'));
                }
            }

            if (value.includes(queryConstants.CreatedWithin)) {
                const createdFilter = value.split('=');
                if (createdFilter.length === 2) {
                    setHasCreatedWithinFilter(true);
                    setCreatedWithinFilterDays(createdFilter[1]);
                }
            }

            if (value === queryConstants.Overdue) {
                setIsOverdueFilter(true);
            }
        });
    }

    function createWhereClause () {
        let whereClause = '';

        if (isWhereAssignedToMe) {
            whereClause += queryConstants.AssignedToMe;
        }

        if (hasStatusFilter && statusIdFilter) {
            const notStatusFilter = isNotStatusFilter ? '!' : '';
            const statusFilterClause = queryConstants.WhereStatus + notStatusFilter + '=' + statusIdFilter;
            whereClause += whereClause === '' ? statusFilterClause : ',' + statusFilterClause;
        }

        if (hasCreatedWithinFilter && createdWithinFilterDays > 0) {
            const createdWithinClause = queryConstants.CreatedWithin + '=' + createdWithinFilterDays;
            whereClause += whereClause === '' ? createdWithinClause : ',' + createdWithinClause;
        }

        if (isOverdueFilter) {
            whereClause += whereClause === '' ? queryConstants.Overdue : ',' + queryConstants.Overdue;
        }

        return whereClause === '' ? null : whereClause;
    }

    async function getSelectableOrgs() {
        if(authenticationManager.isSuperAdmin()) {
            const orgs = await orgApiService.getSelectableOrgs();
            if(orgs && orgs.success) {
                setSelectableOrgs(orgs.data);
            }
        }
    }

    useEffect(() => {
        async function populateQuery () {
            if (open) {
                setQueryNameError(false);
                const possibleColumns = await populateAllPossibleColumns();
                await getSelectableOrgs();
                await getStatuses();
                if (queryId === 0) {
                    setQueryDescription('');
                    setQueryName('');
                    setPossibleSelectedColumns([]);
                    setPossibleNotSelectedColumns(possibleColumns);
                    setIsWhereAssignedToMe(false);
                    setIsPrivateQuery(false);
                    setIsPublicQuery(false);
                    setHasStatusFilter(false);
                    setStatusIdFilter(0);
                    setHasCreatedWithinFilter(false);
                    setCreatedWithinFilterDays(0);
                    setModalTitle('Add New Query');
                } else {
                    await getColumnsForQuery(queryId);
                    setModalTitle('Edit Query');
                }
            }
        }

        populateQuery();
    }, [open]);

    const resetModal = () => {
        setQueryName('');
        setQueryDescription('');
        setPossibleSelectedColumns([]);
        setPossibleNotSelectedColumns([]);
        setSelectedColumns([]);
        setNotSelectedColumns([]);
        setStatusIdFilter(0);
        setHasStatusFilter(false);
        setIsPrivateQuery(false);
        setIsPublicQuery(false);
        setIsWhereAssignedToMe(false);
        setCreatedWithinFilterDays(0);
        setHasCreatedWithinFilter(false);
        setIsOverdueFilter(false);
    };

    const handleChangeMultipleNotSelected = (event) => {
        const { options } = event.target;
        const value = [];
        Array.prototype.forEach.call(options, (option) => {
            if (option.selected) {
                value.push(option.value);
            }
        });

        setNotSelectedColumns(value);
    };

    const handleChangeMultipleSelected = (event) => {
        const { options } = event.target;
        const value = [];

        Array.prototype.forEach.call(options, (option) => {
            if (option.selected) {
                value.push(option.value);
            }
        });

        setSelectedColumns(value);
    };

    const moveAllToSelected = () => {
        const newSelectedColumns = possibleSelectedColumns;
        possibleNotSelectedColumns.forEach((value) => newSelectedColumns.push(value));

        setPossibleSelectedColumns(newSelectedColumns);
        setPossibleNotSelectedColumns([]);
    };
    const moveToSelected = () => {
        const newSelectedColumns = possibleSelectedColumns;
        let newNotSelectedColumns = possibleNotSelectedColumns;

        notSelectedColumns.forEach((value) => {
            newSelectedColumns.push(value);
            newNotSelectedColumns = newNotSelectedColumns.filter(function (notSelected) {
                return notSelected !== value;
            });
        });

        setPossibleSelectedColumns(newSelectedColumns);
        setPossibleNotSelectedColumns(newNotSelectedColumns);
        setNotSelectedColumns([]);
    };
    const moveToUnselected = () => {
        const newNotSelectedColumns = possibleNotSelectedColumns;
        let newSelectedColumns = possibleSelectedColumns;

        selectedColumns.forEach((value) => {
            newNotSelectedColumns.push(value);
            newSelectedColumns = newSelectedColumns.filter(function (selected) {
                return selected !== value;
            });
        });

        setPossibleNotSelectedColumns(newNotSelectedColumns);
        setPossibleSelectedColumns(newSelectedColumns);
        setSelectedColumns([]);
    };
    const moveAllToUnselected = () => {
        const newNotSelectedColumns = possibleNotSelectedColumns;

        possibleSelectedColumns.forEach((value) => newNotSelectedColumns.push(value));

        setPossibleNotSelectedColumns(newNotSelectedColumns);
        setPossibleSelectedColumns([]);
    };

    function validateQuery () {
        let errorMessage = '';
        if (possibleSelectedColumns.length === 0) {
            errorMessage += 'You must select at least one column';
        }

        if (queryName === '') {
            errorMessage += errorMessage.length === 0 ? 'Query Name is required' : ', Query Name is required';
        }

        setQueryNameError(queryName === '');

        if (errorMessage.length > 0) {
            setSnackbarProperties(errorMessage, 'error');
        }

        return errorMessage.length === 0;
    }

    async function handleSave () {
        /**
         * @type {TaskQueryDataDto}
         */
        const taskQuery = {};

        if (validateQuery()) {
            taskQuery.taskQueryName = queryName;
            taskQuery.taskQueryDescription = queryDescription;
            taskQuery.privateQuery = isPrivateQuery;
            taskQuery.publicQuery = isPublicQuery;
            taskQuery.taskQueryId = queryId;
            taskQuery.taskWhere = createWhereClause();
            taskQuery.organizations = authenticationManager.isSuperAdmin() ? selectedOrgs : [];

            let taskColumns = '';
            possibleSelectedColumns.forEach((value) => {
                taskColumns += taskColumns === '' ? value : ',' + value;
            });

            taskQuery.taskColumns = taskColumns;

            let response;
            if (taskQuery.taskQueryId > 0) {
                response = await taskQueryApiService.updateTaskQuery(queryId, taskQuery);
                if (response.success) {
                    setSnackbarProperties('Successfully updated Query');
                }
            } else {
                response = await taskQueryApiService.addTaskQuery(taskQuery);
                if (response.success) {
                    setSnackbarProperties('Successfully added new Query');
                }
            }

            if (!response.success) {
                setSnackbarProperties('Error saving query: ' + response.message, 'error');
            }

            setIsOpen(false);
            resetModal();
        }
    }

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '150%' } }}
            maxWidth="md"
            fullWidth
            open={open}
            {...other}
        >
            <DialogTitle>{modalTitle}</DialogTitle>
            <DialogContent dividers>
                <FormLabel>Query Name</FormLabel>
                <br/>
                <TextField
                    error={queryNameError}
                    fullWidth
                    value={queryName}
                    onChange={(newValue) => {
                        setQueryName(newValue.target.value);
                    }
                    }
                ></TextField>
                <br/><br/>
                <FormLabel>Query Description</FormLabel>
                <br/>
                <TextField
                    fullWidth
                    value={queryDescription}
                    onChange={(newValue) => {
                        setQueryDescription(newValue.target.value);
                    }
                    }
                ></TextField>
                <br/><br/>
                <FormLabel>Move columns to the right that you wish to display</FormLabel>
                <br/><br/>
                <Grid container>
                    <Grid item size={'medium'} style={{ float: 'left' }}>

                        <select
                            className={'select-query-columns'}
                            multiple
                            value={notSelectedColumns}
                            // @ts-ignore Typings are not considering `native`
                            onChange={handleChangeMultipleNotSelected}
                        >
                            {possibleNotSelectedColumns.map((name) => {
                                return <option className={'select-query-option'} key={name} value={name}>
                                    {name}
                                </option>;
                            },

                            )}
                        </select>
                    </Grid>
                    <Grid item className={selectButtonsSizeClassName} style={{ float: 'left', marginTop: '30px' }}>
                        <Button className={'query-move-column-button'} startIcon={<FastForwardIcon /> } onClick={moveAllToSelected}></Button>
                        <br/>
                        <Button className={'query-move-column-button'} startIcon={<SkipNextOutlinedIcon /> } onClick={moveToSelected} disabled={notSelectedColumns.length === 0}></Button>
                        <br/>
                        <Button className={'query-move-column-button'} startIcon={<SkipPreviousOutlinedIcon /> } onClick={moveToUnselected} disabled={selectedColumns.length === 0}></Button>
                        <br/>
                        <Button className={'query-move-column-button'} startIcon={<FastRewindIcon /> } onClick={moveAllToUnselected}></Button>
                    </Grid>
                    <Grid item className={selectSizeClassName} style={{ float: 'left', marginBottom: '10px' }}>
                        <select
                            className={'select-query-columns'}
                            multiple
                            value={selectedColumns}
                            onChange={handleChangeMultipleSelected}
                        >
                            {possibleSelectedColumns.map((name) => {
                                return <option className={'select-query-option'} key={name} value={name}>
                                    {name}
                                </option>;
                            },

                            )}
                        </select>
                    </Grid>
                </Grid>
                <Grid>
                    <Grid item sx={{marginBottom: '25px'}}>
                        <Row>
                            <FormControlLabel control={<Checkbox disabled={isPublicQuery} checked={isPrivateQuery} onChange={() => setIsPrivateQuery(!isPrivateQuery)}/>} label='Only usable and visible by you (Private Query)' />
                        </Row>
                    </Grid>
                    { authenticationManager.isSuperAdmin() &&
                        <Grid item sx={{marginBottom: '25px'}}>
                            <Row>
                                <FormControlLabel disabled={isPrivateQuery} control={<Checkbox checked={isPublicQuery} onChange={() => setIsPublicQuery(!isPublicQuery)}/>} label='Visible to All Orgs (Public Query)' />
                            </Row>
                        </Grid>
                    }
                    { authenticationManager.isSuperAdmin() &&
                        <Grid item sx={{marginBottom: '25px'}}>
                                <Button
                                    disabled={isPublicQuery || isPrivateQuery}
                                    variant={'contained'}
                                    onClick={() => setOpenOrgSelection(true)}
                                >
                                    Select Organizations that need visibility
                                </Button>
                        </Grid>
                    }
                    <Grid item sx={{marginBottom: '25px'}}>
                        <FormControlLabel control={<Checkbox checked={isWhereAssignedToMe} onChange={() => setIsWhereAssignedToMe(!isWhereAssignedToMe)}/>} label={'Where Task is assigned to me'} />
                    </Grid>
                    <Grid item sx={{marginBottom: '25px'}}>
                        <FormControlLabel control={<Checkbox checked={isOverdueFilter} onChange={() => setIsOverdueFilter(!isOverdueFilter)}/>} label={'Where Task is Overdue'} />
                    </Grid>
                    <Grid item className={'float-md-none'} sx={{marginBottom: '25px'}}>
                        <FormControlLabel control={<Checkbox checked={hasStatusFilter} onChange={() => setHasStatusFilter(!hasStatusFilter)}/>} label={'Where Status is: '} />
                        <FormControlLabel control={<Checkbox checked={isNotStatusFilter} onChange={() => setIsNotStatusFilter(!isNotStatusFilter)}/>} label={'Not'} />
                        <Select
                            disabled={!hasStatusFilter}
                            displayEmpty
                            value={statusIdFilter}
                            onChange={(event) => setStatusIdFilter(event.target.value)}
                        >
                            <MenuItem key={0} value={0}>Please select a status</MenuItem>
                            {statuses.map((value) => {
                                return <MenuItem key={value.stId} value={value.stId}>
                                    {value.stName}
                                </MenuItem>;
                            })}
                        </Select>
                    </Grid>
                    <Grid item className={'float-md-none'}>
                        <FormControlLabel
                            control={<Checkbox checked={hasCreatedWithinFilter} onChange={() => setHasCreatedWithinFilter(!hasCreatedWithinFilter)} />}
                            label={'Created Within (days):'}
                        />
                        <TextField
                            type={'number'}
                            value={createdWithinFilterDays}
                            disabled={!hasCreatedWithinFilter}
                            onChange={(event) => event.target.value > 0 ? setCreatedWithinFilterDays(event.target.value) : null}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel}>
                    Cancel
                </Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
            <QueryOrgSelection
                open={openOrgSelection}
                close={() => setOpenOrgSelection(false)}
                selectableOrgs={selectableOrgs}
                selectedOrgs={selectedOrgs}
                setSelectedOrgs={setSelectedOrgs}
            />
        </Dialog>
    );
}

QueryColumnSelection.propTypes = {
    open: PropTypes.bool.isRequired,
    setIsOpen: PropTypes.func.isRequired,
    queryId: PropTypes.number.isRequired,
    setSnackbarProperties: PropTypes.func,
};
