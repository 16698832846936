import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { FormControl, FormLabel, Popover, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import TaskApiService from '../../api/TaskApiService';
import { FormGroup } from 'reactstrap';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { DataGridPro } from '@mui/x-data-grid-pro';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDeleteDialog from './ConfirmDelete';
import PropTypes from 'prop-types';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

const taskApiService = new TaskApiService();

const SIBLING_TASK = 0;
const CHILD_TASK = 1;
const PARENT_TASK = 2;

export default function TaskRelationshipModal (props) {
    const { onClose, open, taskId, setSnackbarProperties, ...other } = props;
    const [relatedTaskId, setRelatedTaskId] = useState('');
    const [description, setDescription] = useState('');

    /**
     * @type Array<TaskRelationshipDto>
     */
    const [relatedTasks, setRelatedTasks] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedDirection, setSelectedDirection] = useState();
    const [selectedRelationship, setSelectedRelationship] = useState();
    const [anchorEl, setAnchorEl] = useState(null);
    const [otherTaskDescription, setOtherTaskDescription] = useState();
    const [openPopover, setOpenPopover] = useState(false);

    async function handlePopoverOpen (event) {
        setAnchorEl(event.currentTarget);
        const result = await taskApiService.getTaskDescription(event.currentTarget.getAttribute('task-id'));
        setOpenPopover(true);
        setOtherTaskDescription(result.data);
    }

    function handlePopoverClose () {
        setOtherTaskDescription('');
        setOpenPopover(false);
        setAnchorEl(null);
    }

    function getRelationshipFromInt (direction) {
        if (direction === SIBLING_TASK) {
            return 'Sibling Task';
        } else if (direction === CHILD_TASK) {
            return 'Child Task';
        } else if (direction === PARENT_TASK) {
            return 'Parent Task';
        }
    }

    const [columns] = useState([
        {
            field: 'baseTask',
            headerName: 'Base Task',
            flex: 1,
            renderCell: (params) => {
                return <Link
                    underline='always'
                    target='_blank'
                    href={'/Task/' + params.row.baseTask}
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    task-id={params.row.baseTask}
                >
                    {params.row.baseTask}
                </Link>;
            },
        },
        {
            field: 'relatedTask',
            headerName: 'Related Task',
            flex: 1,
            renderCell: (params) => {
                return <Link
                    underline='always'
                    target='_blank'
                    href={'/Task/' + params.row.relatedTask}
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    task-id={params.row.relatedTask}
                >
                    {params.row.relatedTask}
                </Link>;
            },
        },
        {
            field: 'description',
            headerName: 'Description',
            minWidth: 110,
            flex: 2,
        },
        {
            field: 'direction',
            headerName: 'Type',
            flex: 1,
            renderCell: (params) => {
                return <label>{getRelationshipFromInt(params.row.direction)}</label>;
            },
        },
        {
            field: 'delete',
            headerName: '',
            renderCell: (params) => {
                return <IconButton
                    aria-label={'Delete'}
                    onClick={() => {
                        setShowDeleteDialog(true);
                        setSelectedRelationship(params.row);
                    }
                    }
                >
                    <DeleteIcon />
                </IconButton>;
            },
        },
    ]);

    const saveNewRelationship = async () => {
        const newRelationship = {
            baseTask: taskId,
            relatedTask: relatedTaskId,
            description,
            direction: selectedDirection,
        };
        const response = await taskApiService.addRelatedTask(newRelationship);
        if (response.success) {
            setSnackbarProperties('Successfully created Task Relationship');
            resetModalValues();
        } else {
            setSnackbarProperties('Error trying to create Task Relationship:' + response.message, 'error');
        }
    };

    function resetModalValues () {
        setRelatedTaskId('');
        setSelectedDirection(null);
        setDescription('');
    }

    const confirmDelete = async (value) => {
        if (value) {
            const deletedRelationshipResult = await taskApiService.deleteRelatedTask(selectedRelationship);
            if (deletedRelationshipResult.success) {
                setSnackbarProperties('Task Relationship deleted');
                await GetUpdateTaskRelationships();
            } else {
                // Notification that it wasn't successful
                setSnackbarProperties('Unable to delete Task Relationship: ' + deletedRelationshipResult.message, 'error');
            }
        }
        setShowDeleteDialog(false);
    };

    const handleCancel = () => {
        resetModalValues();
        onClose(false);
    };

    const GetUpdateTaskRelationships = async () => {
        const relationshipsResponse = await taskApiService.getRelatedTasks(taskId);
        if (relationshipsResponse.success) {
            setRelatedTasks(relationshipsResponse.data);
        } else {
            setSnackbarProperties('Unable to retrieve Task Relationship: ' + relationshipsResponse.message, 'error');
        }
    };

    useEffect(() => {
        if(open) {
            async function getData () {
                await GetUpdateTaskRelationships();
            }
            void getData();
        }

    }, [open]);

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: 1000, maxHeight: 1000 } }}
            maxWidth="md"
            open={open}
            {...other}
        >
            <DialogTitle>Task Relationships</DialogTitle>
            <DialogContent
                dividers
                style={{ width: '100%' }}
            >
                <FormGroup>
                    <FormControl>
                        <FormLabel>Related Task ID:</FormLabel>
                        <TextField
                            value={relatedTaskId}
                            onChange={(event) => {
                                setRelatedTaskId(event.target.value);
                            }}
                        />
                    </FormControl>

                </FormGroup>
                <FormGroup>
                    <FormControl>
                        <FormLabel>Description</FormLabel>
                        <TextField
                            value={description}
                            onChange={(event) => {
                                setDescription(event.target.value);
                            }}
                        />
                    </FormControl>

                </FormGroup>
                <RadioGroup
                    value={selectedDirection}
                    onChange={(event) => setSelectedDirection(event.target.value)}
                >
                    <FormControlLabel control={<Radio />} label={'Related Task ID is sibling'} value={0}/>
                    <FormControlLabel control={<Radio />} label={'Related Task ID is child'} value={1}/>
                    <FormControlLabel control={<Radio />} label={'Related Task ID is parent'} value={2}/>
                </RadioGroup>

                <Button
                    onClick={saveNewRelationship}
                    variant='contained'
                >
                    Save New Relationship
                </Button>
                <br/><br/>
                <div style={{ height: 300, width: '100%' }}>
                    <DataGridPro
                        columns={columns}
                        rows={relatedTasks}
                        getRowId={(row) => row.id}
                    />
                </div>

            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleCancel} variant='contained'>
                    Close
                </Button>
            </DialogActions>
            <ConfirmDeleteDialog onClose={confirmDelete} open={showDeleteDialog} />
            <Popover
                open={openPopover}
                sx={{
                    pointerEvents: 'none',
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{ p: 1 }}>{otherTaskDescription}</Typography>
            </Popover>
        </Dialog>
    );
}

TaskRelationshipModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    taskId: PropTypes.number.isRequired,
    setSnackbarProperties: PropTypes.func.isRequired,
};
