import apiService from './apiService';
import axios from 'axios';
import authenticationManager from '../auth/AuthenticationManager';

class UploadFilesService {
    async uploadTaskAttachment (file, taskId) {
        const formData = new FormData();

        formData.append('taskId', taskId);
        formData.append('file', file);
        formData.append('description', file.description ?? '');

        const _headers = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        await authenticationManager.checkTokenExpiration();
        const token = await authenticationManager.getAccessToken();
        if (token) {
            _headers.headers.Authorization = 'Bearer ' + token;
        }

        return await axios.post('/api/Task/uploadTaskAttachment', formData, _headers)
            .catch(err => {
                return err.response;
            });
    }

    async uploadAttachmentForTaskRecurrence(file, taskRecurrenceId) {
        const formData = new FormData();

        formData.append('taskRecurrenceId', taskRecurrenceId);
        formData.append('file', file);

        return await apiService.postFormData('api/Task/uploadAttachmentForTaskRecurrence', formData).catch(err => {
            return err.response;
        });
    }

    async uploadHazardAttachment (file, hazardId) {
        const formData = new FormData();

        formData.append('hazardId', hazardId);
        formData.append('file', file);
        formData.append('filename', file.name);
        formData.append('ContentType', file.type);
        formData.append('description', file.description);

        return await apiService.postFormData('/api/Hazard/uploadHazardAttachment', formData)
            .catch(err => {
                return err.response;
            });
    }

    async deleteHazardAttachment (attachmentLinkId) {
        const deleteHazardAttachmentResponse = await apiService.deleteData('/api/Hazard/deleteHazardAttachment/' + attachmentLinkId);
        return deleteHazardAttachmentResponse.data;
    }

    async downloadHazardAttachment (attachment, hazardId) {
        const formData = new FormData();

        formData.append('hazardId', hazardId);
        formData.append('attachmentLinkId', attachment.attachmentLinkId);
        formData.append('url', attachment.url);
        formData.append('filename', attachment.filename);

        return await apiService.getData('/api/Hazard/downloadHazardAttachment?attachmentLinkId=' + attachment.attachmentLinkId, {
            responseType: 'arraybuffer',
        });
    }
}

export default new UploadFilesService();
