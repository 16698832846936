import { FormControl, FormLabel, Grid, Paper, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import TaskRecurrenceDto from '../../../../dtos/Task/TaskRecurrenceDto';

export default function PastRecurrenceDetailsPanel(props) {
    const { row } = props;
    return(
        <Stack
            sx={{ py: 2, height: '100%', boxSizing: 'border-box'}}
        >
            <Paper sx={{flex: 1, mx: 'auto', width: '90%', p: 1}}>
                <Stack>
                    <Grid container>
                        <FormControl>
                            <FormLabel>Completion Notes</FormLabel>
                            {row.completionNotes}
                        </FormControl>
                    </Grid>
                    <Grid container sx={{marginTop: '20px'}}>
                        <FormControl>
                            <FormLabel>Attachments</FormLabel>
                            {row.attachments.map((attachment) => {
                                return <a href={attachment.url} target={'_blank'} rel="noreferrer">{attachment.filename}</a>
                            })}
                        </FormControl>
                    </Grid>
                </Stack>
            </Paper>
        </Stack>
    )
}

PastRecurrenceDetailsPanel.propTypes = {
    row: PropTypes.objectOf(TaskRecurrenceDto),
};
