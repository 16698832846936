export class UserAdminDto {
    /**
     * @type{number}
     */
    usId;

    /**
     * @type{UserAdminMainSettingsDto}
     */
    mainSettingsDto;

    /**
     * @type{UserAdminEmailNotificationsDto}
     */
    emailNotificationsDto;

    /**
     * @type{UserAdminPermissionsDto}
     */
    permissionsDto;

    /**
     * @type{number}
     */
    usCreatedUser;

    /**
     * @type{Date}
     */
    usMostRecentLoginDatetime;

    /**
     * @type{SimpleOrganizationDto}
     */
    userOrg;
}
