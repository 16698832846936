import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import TaskApiService from '../../../api/TaskApiService';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGridPro } from '@mui/x-data-grid-pro';
import PastRecurrenceDetailsPanel from './Components/PastRecurrenceDetailsPanel';

export default function RecurrencePastCompletionsModal(props) {
    const { open, close, taskApiService, setSnackbarProperties, taskId } = props;

    const[recurrences, setRecurrences] = useState([]);

    const columns = [
        {
            field: 'due',
            headerName: 'Due Date',
            flex: 1,
            renderCell: (params) => params.row.due.substring(0, 10),
            valueGetter: (params) => params.row.due.substring(0, 10),
        },
        {
            field: 'completed',
            headerName: 'Completed',
            flex: 1,
            renderCell: (params) => params.row.completed.substring(0, 10),
            valueGetter: (params) => params.row.completed.substring(0, 10),
        },
        {
            field: 'completedByUser.username',
            headerName: 'Completed By',
            flex: 2,
            renderCell: (params) => {
                return params.row.completedByUser.username;
            }
        }
    ];

    async function loadRecurrences() {
        const recurrencesResponse = await taskApiService.getCompletedRecurrencesForTask(taskId);

        if(recurrencesResponse.success) {
            setRecurrences(recurrencesResponse.data);
        } else if (recurrencesResponse.message) {
            setSnackbarProperties(`Error loading past recurrences: ${recurrencesResponse.message}`, 'error');
        } else if(recurrencesResponse) {
            setSnackbarProperties(`Error loading past recurrences: ${recurrencesResponse}`, 'error');
        }
    }

    const getDetailPanelHeight = React.useCallback(() => 400, []);
    const getDetailPanelContent = React.useCallback(({row}) => <PastRecurrenceDetailsPanel row={row} />, []);

    useEffect(() => {
        if(open) {
            loadRecurrences();
        }
    }, [open]);

    return(
        <Dialog open={open}
                sx={{ '& .MuiDialog-paper': { width: '100%' } }}
                maxWidth={'md'}
                fullWidth
        >
            <DialogTitle>Past Completions</DialogTitle>
            <DialogContent>
                <Box sx={{height: '500px'}}>
                    <DataGridPro
                        columns={columns}
                        rows={recurrences}
                        getRowHeight={() => 'auto'}
                        getDetailPanelHeight={getDetailPanelHeight}
                        getDetailPanelContent={getDetailPanelContent}
                    />
                </Box>

            </DialogContent>
            <DialogActions>
                <Button onClick={close}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

RecurrencePastCompletionsModal.propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    taskApiService: PropTypes.objectOf(TaskApiService),
    setSnackbarProperties: PropTypes.func.isRequired,
    taskId: PropTypes.string.isRequired,
};
