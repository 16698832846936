export const DATE = 'date';
export const INT = 'integer';
export const TEXT_FIELD = 'text';
export const TEXT_AREA = 'textarea';
export const DOUBLE = 'double';
export const USERS = 'users';
export const OPTION = 'option';
export const ORGANIZATION = 'organization';
export const CATEGORY = 'category';
export const CATEGORY_OPTION = 'Category';

export const TASK_FIELD_LABEL = 'fieldLabel';
export const PROJECTS = 'Location/Project';
