export default class TaskRecurrenceDto {
    /**
     * @type{number}
     */
    id;

    /**
     * @type{number}
     */
    taskId;

    /**
     * @type{string}
     */
    recurrenceString;

    /**
     * @type{Date}
     */
    due;

    /**
     * @type{Date}
     */
    completed;

    /**
     * @type{string}
     */
    completionNotes;

    /**
     * @type{BasicUserDto}
     */
    completedByUser;

    /**
     * @type{Array{AttachmentDto}}
     */
    attachments;

    constructor () {
        this.id = 0;
        this.completed = null;
        this.completionNotes = '';
        this.completedByUser = null;
        this.attachments = [];
    }

}
