import React, { useState } from 'react';
import { Button, FormLabel, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { UserAdminDto } from '../../../../dtos/Users/UserAdminDto';
import UserApiService from '../../../../api/UserApiService';

export default function UserChangePassword (props) {
    const { user, setSnackbarProperties } = props;
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);

    const successMessage = 'Successfully changed password';

    const userApiService = new UserApiService();

    async function changePassword () {
        if (!newPassword) {
            setPasswordError('You must enter a value!');
            setSnackbarProperties('You must enter a value!', 'error');
            return;
        }
        if (newPassword === confirmNewPassword) {
            resetError();
            const changePasswordResponse = await userApiService.changeUserPassword(user.usId, newPassword);
            if (changePasswordResponse && changePasswordResponse.success) {
                setPasswordChangeSuccess();
            } else {
                setSnackbarProperties('Unable to update password: ' + changePasswordResponse.message, 'error');
            }
        } else {
            setPasswordError('Passwords must match!');
            setSnackbarProperties('Passwords must match!', 'error');
        }
    }

    function resetError () {
        setError(false);
        setErrorMessage('');
    }

    function setPasswordError (message) {
        setError(true);
        setChangePasswordSuccess(false);
        setErrorMessage(message);
    }

    function setPasswordChangeSuccess () {
        setSnackbarProperties('Password updated!');
        setNewPassword('');
        setConfirmNewPassword('');
        setChangePasswordSuccess(true);
        setError(false);
    }

    return (
        <div>
            {error && <FormLabel sx={{ color: 'red' }}>{errorMessage}</FormLabel>}
            {changePasswordSuccess && <FormLabel sx={{ color: 'green' }}>{successMessage}</FormLabel>}
            <div>
                <FormLabel>New Password</FormLabel>
                <br/>
                <TextField
                    error={error}
                    type='password'
                    value={newPassword}
                    onChange={(event) => { setNewPassword(event.target.value); }}
                />
            </div>
            <br/>
            <div>
                <FormLabel>Confirm New Password</FormLabel>
                <br/>
                <TextField
                    error={error}
                    type='password'
                    value={confirmNewPassword}
                    onChange={(event) => { setConfirmNewPassword(event.target.value); }}
                />
            </div>
            <br/>
            <Button
                variant='contained'
                onClick={changePassword}
            >
                Change Password
            </Button>
        </div>
    );
}

UserChangePassword.propTypes = {
    user: PropTypes.objectOf(UserAdminDto),
    setSnackbarProperties: PropTypes.func.isRequired,
};
