export default class TaskHistoryDto {
    /**
     * @type{string}
     */
    taskChange;

    /**
     * @type{Date}
     */
    taskChangeTime;

    /**
     * @type{number}
     */
    taskId;

    /**
     * @type{string}
     */
    taskChangeType;

    /**
     * @type{string}
     */
    taskChangeUser;

    /**
     * @type{string}
     */
    url;

    /**
     * @type{string}
     */
    contentType;

    /**
     * @type{number}
     */
    taskChangeId;
}
