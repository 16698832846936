import apiService from './apiService';

export default class TaskQueryApiService {
    async getTaskQueries () {
        const taskData = await apiService.getData('api/Query');
        return taskData.data.data;
    }

    /**
     * Gets available task queries for an org
     * @param orgId {number}
     * @return {Promise<ServiceResponse<Array<TaskQueryDto>>>}
     */
    async getTaskQueriesForOrg(orgId) {
        const queryData = await apiService.getData(`api/Query/forOrg/${orgId}`);
        return queryData.data;
    }

    /**
     * Gets default query for user
     * @return {Promise<ServiceResponse<TaskQueryDto>>}
     */
    async getUserDefaultQuery() {
        const defaultQuery = await apiService.getData('api/Query/default');
        return defaultQuery.data;
    }

    /**
     * Gets all columns that can be queried
     * @return {Promise<ServiceResponse<String>>}
     */
    async getAllQueryColumns () {
        const response = await apiService.getData('api/Query/GetAllColumns');
        return response.data;
    }

    /**
     * Gets all available queries
     * @return {Promise<ServiceResponse<Array<TaskQueryDto>>>}
     */
    async getAllQueries () {
        const response = await apiService.getData('api/Query');
        return response.data;
    }

    /**
     * Updates a Query
     * @return {Promise<ServiceResponse<TaskQueryDto>>}
     */
    async updateTaskQuery (currentSelectedQuery, taskQuery) {
        const response = await apiService.putData('api/Query/' + currentSelectedQuery, taskQuery);
        return response.data;
    }

    /**
     *
     * @param taskQuery {TaskQueryDataDto}
     * @return {Promise<ServiceResponse<TaskQueryDto>>}
     */
    async addTaskQuery (taskQuery) {
        const response = await apiService.postData('api/Query', taskQuery);
        return response.data;
    }

    /**
     * Gets the data for a task query
     * @param taskQueryId {number}
     * @return {Promise<ServiceResponse<TaskQueryDataDto>>}
     */
    async getTaskQuery (taskQueryId) {
        const response = await apiService.getData('api/Query/' + taskQueryId);
        return response.data;
    }

    /**
     * Deletes a task query
     * @param taskQueryId {number}
     * @return {Promise<ServiceResponse<TaskQueryDto>>}
     */
    async deleteTaskQuery (taskQueryId) {
        const response = await apiService.deleteData('api/Query/' + taskQueryId);
        return response.data;
    }
}
