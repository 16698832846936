import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Box, Button, FormLabel, TextField } from '@mui/material';
import { Row } from 'reactstrap';
import DialogActions from '@mui/material/DialogActions';
import PropTypes from 'prop-types';
import StatusApiService from '../../../api/StatusApiService';
import { StatusDto } from '../../../dtos/Statuses/StatusDto';

export default function ModifyStatusModal(props) {
    const { onClose, open, selectedStatus, setSnackbarProperties, statusApiService, onCancel } = props;

    const [statusName, setStatusName] = useState('');
    const [sortSequence, setSortSequence] = useState(0);

    const [nameError, setNameError] = useState('');
    const [sortSequenceError, setSortSequenceError] = useState('');

    async function handleSave() {
        const statusDto = new StatusDto();
        statusDto.stId = selectedStatus?.stId ?? 0;
        statusDto.stName = statusName;
        statusDto.stSortSeq = sortSequence;

        if(validateInfo() && selectedStatus && selectedStatus.stId) {
            const updatedStatus = await statusApiService.updateStatus(statusDto);

            if(updatedStatus.success) {
                setSnackbarProperties('Updated Status');
                resetModal();
            } else {
                setSnackbarProperties('Unable to update status: ' + updatedStatus.message, 'error');
            }
        } else if(validateInfo()) {
            const newStatus = await statusApiService.createStatus(statusDto);

            if(newStatus.success) {
                setSnackbarProperties('Added new status');
                resetModal();
            } else {
                setSnackbarProperties('Unable to add new status: ' + newStatus.message, 'error');
            }
        }
    }

    function validateInfo() {
        let errorMessage = '';

        if(statusName === null || statusName === '') {
            errorMessage += 'Priority name must not be empty.';
        }

        setNameError(!statusName);

        if(sortSequence < 1) {
            errorMessage += (errorMessage.length > 0 ? ', ' : '') + 'Sort Sequence must be greater than 0';
        }

        setSortSequenceError(sortSequence < 1);

        if(errorMessage.length > 0) {
            setSnackbarProperties('Priority validation failed: ' + errorMessage, 'error');
            return false;
        } else {
            return true;
        }
    }

    function resetModal() {
        setSortSequence(0);
        setStatusName('');
        onClose();
    }

    useEffect(() => {
        if(open) {
            setStatusName(selectedStatus?.stName ?? '');
            setSortSequence(selectedStatus?.stSortSeq ?? 0);
        }
    }, [selectedStatus, open])

    return(
        <Dialog
            open={open}
            sx={{ '& .MuiDialog-paper': { width: '100%' } }}
            maxWidth={'lg'}
            fullWidth
        >
            <DialogTitle>{selectedStatus?.stName ? 'Edit ' + selectedStatus.stName : 'Add New Status'}</DialogTitle>
            <DialogContent dividers>
                <Box>
                    <Row>
                        <FormLabel>Status Name</FormLabel>
                        <br/>
                        <TextField
                            error={nameError}
                            sx={{ width: '100%' }}
                            autoComplete={'Status Name'}
                            value={statusName}
                            onChange={(event) => setStatusName(event.target.value)}
                        />
                    </Row>
                    <Row>
                        <FormLabel>Sort Sequence</FormLabel>
                        <br/>
                        <TextField
                            error={sortSequenceError}
                            sx={{ width: '100%' }}
                            type={'number'}
                            autoComplete={'Sort Sequence'}
                            value={sortSequence}
                            onChange={(event) => setSortSequence(event.target.value)}
                        />
                    </Row>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant={'contained'} onClick={onCancel}>Cancel</Button>
                <Button variant={'contained'} onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}

ModifyStatusModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedStatus: PropTypes.objectOf(StatusDto),
    setSnackbarProperties: PropTypes.func.isRequired,
    statusApiService: PropTypes.objectOf(StatusApiService).isRequired,
    onCancel: PropTypes.func.isRequired,
}
