export class UserAdminPermissionsDto {
    /**
     * @type{boolean}
     */
    usViewRiskManagement;

    /**
     * @type{boolean}
     */
    usModifyRiskManagement;

    /**
     * @type{Array<ProjectUserXRefDto>}
     */
    projectUserXRefs;

    constructor () {
        this.usViewRiskManagement = false;
        this.usModifyRiskManagement = false;
        this.projectUserXRefs = [];
    }
}
