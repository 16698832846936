import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Layout from './components/Layout';
import './custom.css';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

LicenseInfo.setLicenseKey('538e654e49e22b0f166ea32fd5c1c061Tz01OTkwMCxFPTE3MDgwNTI0OTAzMDIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const vertical = 'top';
const horizontal = 'center';
const Alert = React.forwardRef(function Alert (props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const App = () => {
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        const jquery = document.createElement('script');
        jquery.src = 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/jquery.min.js';
        jquery.async = true;

        const bootstrap = document.createElement('script');
        bootstrap.src = 'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js';
        bootstrap.async = true;

        const popper = document.createElement('script');
        popper.src = 'https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js';
        popper.async = true;

        document.body.appendChild(jquery);
        document.body.appendChild(bootstrap);
        document.body.appendChild(popper);
    });

    function resetSnackbar () {
        setSnackbarMessage('');
        setSnackbarSeverity('success');
    }

    function closeSnackbar (event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    }

    function setSnackbarProperties (message, severity) {
        setSnackbarMessage(message ?? '');
        setSnackbarSeverity(severity ?? 'success');
        setSnackbarOpen(true);
    }

    return (
        <>
            <Layout setSnackbarProperties={setSnackbarProperties}>
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        return <Route key={index} {...rest} element={React.cloneElement(element, { setSnackbarProperties })}/>;
                    })}
                </Routes>
            </Layout>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={5000}
                key={'topcenter'}
                anchorOrigin={{ vertical, horizontal }}
                onClose={closeSnackbar}
                TransitionProps={{
                    onExited: () => {
                        resetSnackbar();
                    }
                }}
            >
                <Alert
                    severity={snackbarSeverity}
                    sx={{ width: '100%' }}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default App;
