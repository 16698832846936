import React, { useEffect, useState } from 'react';
import CategoryApiService from '../../../api/CategoryApiService';
import { DataGridPro } from '@mui/x-data-grid-pro';
import EditMenuButton from '../../Modal/EditMenuButton';
import PropTypes from 'prop-types';
import ConfirmDeleteDialog from '../../Modal/ConfirmDelete';
import { Row } from 'reactstrap';
import { Box, Button, FormControl } from '@mui/material';
import ModifyCategoryModal from './ModifyCategoryModal';

const Categories = (props) => {
    const { setSnackbarProperties } = props;

    const [categories, setCategories] = useState([]);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState();

    const columns = [
    {
        field: 'ctId',
            headerName: 'Id',
        flex: 0.5,
    },
    {
        field: 'ctName',
            headerName: 'Category Name',
        flex: 2,
    },
    {
        field: 'ctSortSeq',
        headerName: 'Sort Sequence',
        flex: 2,
    },
    {
        field: 'recurring',
        headerName: 'Recurring?',
        renderCell: (params) => {
            return params.row.recurring ? 'Yes' : 'No';
        },
    },
    {
        field: 'edit',
            headerName: 'edit',
        flex: 0.5,
        filterable: false,
        sortable: false,
        renderCell: (params) => {
            return <EditMenuButton
                params={params}
                setShowDeleteDialog={setShowDeleteDialog}
                setCurrentSelectedRecord={setSelectedCategory}
                onEditFunc={() => {
                    setShowEditDialog(true);
                    setSelectedCategory(params.row)
                }}
            />;
        },
    }
    ];

    const categoryApiService = new CategoryApiService();

    async function getCategories () {
        const categories = await categoryApiService.GetCategories();
        setCategories(categories.data);
    }

    async function onDeleteClick(value) {
        if (!value) {
            setShowDeleteDialog(false);
            return;
        }

        if (selectedCategory) {
            const deletedCategory = await categoryApiService.deleteCategory(selectedCategory.ctId);
            if (deletedCategory && deletedCategory.success) {
                setSnackbarProperties('Deleted Category', 'warning');
                await getCategories();
            }
        }

        setShowDeleteDialog(false);
    }

    function handleEditClose() {
        setShowEditDialog(false);
        setSelectedCategory(null);
        getCategories();
    }

    useEffect(() => {
        getCategories();
    }, [])

    return(
        <div>
            <Row>
                <h2>Categories</h2>
            </Row>
            <Row>
                <div style={{ display: 'inline-block', justifyContent: 'space-between' }}>
                    <FormControl sx={{ m: 1, minWidth: 120, float: 'left' }}>
                        <Button size={'large'} variant="contained" onClick={() => setShowEditDialog(true) }>Create new Category</Button>
                    </FormControl>
                </div>
            </Row>
            <Box sx={{ height: '80vh', width: '100%', display: 'block'}}>
                <DataGridPro
                    columns={columns}
                    rows={categories}
                    getRowId={(row) => row.ctId}
                    getRowHeight={() => 'auto'}
                />
            </Box>
            <ConfirmDeleteDialog
                onClose={onDeleteClick}
                open={showDeleteDialog}
            />
            <ModifyCategoryModal
                open={showEditDialog}
                onClose={handleEditClose}
                selectedCategory={selectedCategory}
                setSnackbarProperties={setSnackbarProperties}
                categoryApiService={categoryApiService}
                onCancel={handleEditClose}
            />
        </div>
    );
}

Categories.propTypes = {
    setSnackbarProperties: PropTypes.func,
}
export default Categories;
