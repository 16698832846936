import React, { useEffect, useState } from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import { Button, FormControl, MenuItem, Select } from '@mui/material';
import ConfirmDeleteDialog from './Modal/ConfirmDelete';
import TaskApiService from '../api/TaskApiService';
import TaskQueryApiService from '../api/TaskQueryApiService';
import { Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import EditMenuButton from './Modal/EditMenuButton';
import { useSelector, useDispatch } from 'react-redux';
import { updateTaskColumns, updateTaskFilters, updateTaskQuery } from '../store/correcttrackSlice';

const taskApiService = new TaskApiService();
const taskQueryService = new TaskQueryApiService();

const Tasks = () => {
    const [tasks, setTasks] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [columns, setColumns] = useState([]);
    const [pageSize, setPageSize] = useState(25);
    const currentQuery = useSelector((state) => state.correcttrack.taskQueryId);
    const [queries, setQueries] = useState([]);
    const [currentSelectedTask, setCurrentSelectedTask] = useState();
    const [currentFilters, setCurrentFilters] = useState(useSelector((state) => state.correcttrack.taskFilters));
    const [currentVisibleFields, setCurrentVisibleFields] = useState(useSelector((state) => state.correcttrack.taskColumns));

    const dispatch = useDispatch();

    const navigate = useNavigate();

    function updateFilters(filterModel) {
        if(!loading) {
            setCurrentFilters(filterModel);
            dispatch(updateTaskFilters(filterModel));
        }
    }

    function updateVisibleColumns(columnsModel) {
        if(!loading) {
            setCurrentVisibleFields(columnsModel);
            dispatch(updateTaskColumns(columnsModel));
        }
    }

    const onDeleteClick = async (value) => {
        if (value) {
            await taskApiService.deleteTask(currentSelectedTask.Id);
            setTasks(tasks.filter(function (item) {
                return item.id !== currentSelectedTask.Id;
            }));
            setShowDeleteDialog(false);
        } else {
            setShowDeleteDialog(false);
        }
    };

    async function populateTasks (queryToUse) {
        let response;
        if (typeof queryToUse === 'undefined' || queryToUse === 0) {
            response = await taskApiService.getTasksByQuery(0);
        } else {
            response = await taskApiService.getTasksByQuery(queryToUse);
        }

        if (response != null) {
            const columnHeaders = response.taskColumnDefinitions;
            setColumnsForTable(columnHeaders);
            setTasks(response.tasks);
        }

        setLoading(false);
    }

    function setColumnsForTable (columnHeader) {
        const columnsToSet = [];
        columnHeader.forEach((header) =>
            columnsToSet.push(
                { field: header.columnDefinition,
                    headerName: header.columnName,
                    flex: 1,
                    renderCell: (params) =>
                    {
                        if(header.isCustomColumn) {
                            if(params.row.customColumnData && params.row.customColumnData.length > 0) {
                                return params.row.customColumnData.find(obj => obj.columnName === header.columnName)?.columnValue ?? '';
                            } else {
                                return '';
                            }
                        } else {
                            return params.row[header.columnDefinition];
                        }
                    },
                    valueGetter: (params) =>
                    {
                        if(header.isCustomColumn) {
                            if(params.row.customColumnData && params.row.customColumnData.length > 0) {
                                return params.row.customColumnData.find(obj => obj.columnName === header.columnName)?.columnValue ?? '';
                            } else {
                                return '';
                            }
                        } else {
                            return params.row[header.columnDefinition];
                        }
                    }
                }));

        const menuColumn = {
            field: 'Edit',
            headerName: 'Edit',
            flex: 0.5,
            renderCell: (params) => {
                return <EditMenuButton
                    setShowDeleteDialog={setShowDeleteDialog}
                    setCurrentSelectedRecord={setCurrentSelectedTask}
                    params={params}
                    onEditFunc={() => {
                        navigate(`/Task/${params.row.id}`);
                    }}
                />;
            },
        };
        columnsToSet.push(menuColumn);
        setColumns(columnsToSet);
    }

    async function populateQueries () {
        const taskQueries = await taskQueryService.getTaskQueries();
        const defaultQuery = await taskQueryService.getUserDefaultQuery();
        let userDefaultQueryId = 0;
        if(defaultQuery && defaultQuery.data && defaultQuery.data.queryId) {
            userDefaultQueryId = defaultQuery.data.queryId;
        }
        if(currentQuery === null || currentQuery === undefined || currentQuery === '') {
            dispatch(updateTaskQuery(userDefaultQueryId));
        } else {
            userDefaultQueryId = currentQuery;
        }
        if (taskQueries != null) {
            const queries = processQueries(taskQueries);
            setQueries(queries);
        }

        return userDefaultQueryId;
    }

    function processQueries (queries) {
        const processedQueries = [];
        processedQueries.push({value: 'Default Query', id: 0})

        queries.forEach((query) => {
            const thisQuery = {};
            thisQuery.value = query.queryName;
            thisQuery.id = query.queryId;
            processedQueries.push(thisQuery);
        });
        return processedQueries;
    }

    async function setupData () {
        const defaultQuery = await populateQueries();
        await populateTasks(defaultQuery);
    }

    useEffect(() => {
        void setupData();
        }, []);

    return (
        <div>
            <Row>
                <div style={{ display: 'inline-block', justifyContent: 'space-between' }}>
                    <FormControl sx={{ m: 1, minWidth: 250, float: 'left' }}>
                        <Select
                            value={currentQuery}
                            autoWidth
                            onChange={async (newValue) => {
                                if (newValue.target.value > 0) {
                                    dispatch(updateTaskQuery(newValue.target.value));
                                    setLoading(true);
                                    await populateTasks(newValue.target.value);
                                    setLoading(false);
                                }
                            }
                            }
                        >
                            {queries?.map(option => {
                                return (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.value}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 120, float: 'right' }}>
                        <Button size={'large'} variant="contained" onClick={() => navigate('/Task/0')}>Create new task</Button>
                    </FormControl>
                </div>
            </Row>
            <Row>
                <Box sx={{ height: '80vh', width: '100%', display: 'block' }}>
                    <DataGridPro
                        rows={tasks}
                        columns={columns}
                        loading={loading}
                        pagination
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        getRowId={(row) => row.id}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        filterModel={currentFilters}
                        onFilterModelChange={(newFilterModel) => updateFilters(newFilterModel)}
                        columnVisibilityModel={currentVisibleFields}
                        onColumnVisibilityModelChange={(newColumnsModel) => updateVisibleColumns(newColumnsModel)}
                        getRowHeight={() => 'auto'}
                        rowsPerPageOptions={[10, 25, 50, 100, 200]}
                    />
                </Box>
            </Row>

            <ConfirmDeleteDialog
                id="confirm-delete"
                keepMounted
                value={currentSelectedTask}
                open={showDeleteDialog}
                onClose={onDeleteClick}
            ></ConfirmDeleteDialog>
        </div>
    );
};
export default Tasks;
