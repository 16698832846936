import { FormControl, FormLabel, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

export default function WeekOfMonthField(props) {
    const { value, onChange } = props;

    return (
        <Box>
            <FormLabel>Week of Month</FormLabel>
            <br/>
            <FormControl sx={{m: 1, minWidth: 150}}>
                <Select
                    value={value}
                    onChange={onChange}
                >
                    <MenuItem value={'1'}>1st</MenuItem>
                    <MenuItem value={'2'}>2nd</MenuItem>
                    <MenuItem value={'3'}>3rd</MenuItem>
                    <MenuItem value={'4'}>4th</MenuItem>
                    <MenuItem value={'50'}>Last</MenuItem>
                </Select>
            </FormControl>
        </Box>

    )
}
