import apiService from './apiService';
import authenticationManager from '../auth/AuthenticationManager';

export default class OrgApiService {
    /**
     * Gets a list of all selectableOrgs
     * @return {Promise<ServiceResponse<Array<SimpleOrganizationDto>>>}
     */
    async getSelectableOrgs () {
        const orgData = await apiService.getData('api/Organization/SelectableOrgs');
        return orgData.data;
    }

    async getOrgs () {
        const orgData = await apiService.getData('api/Organization');
        return orgData.data;
    }

    async updateOrganization (newOrganization) {
        const updateOrganizationResponse = await apiService.putData('api/Organization/' + newOrganization.ogId, newOrganization);
        return updateOrganizationResponse.data;
    }

    async addOrganization (newOrganization) {
        const addOrganizationResponse = await apiService.postData('api/Organization', newOrganization);
        return addOrganizationResponse.data;
    }

    async getUsersOrg() {
        const userOrgResponse = await apiService.getData(`api/Organization/user/${authenticationManager.getUserId()}`);

        return userOrgResponse.data;
    }
}
