import authenticationManager from './AuthenticationManager';
import apiService from '../api/apiService';

class AuthService {
    _baseUrl = '/api/Auth/';

    async login (username, password) {
        const result = await apiService.postData(this._baseUrl + 'login', {
            username,
            password,
        });
        if (result.status === 200) {
            authenticationManager.updateToken(result.data.data.token, result.data.data.refreshToken, username);
        }
        return result;
    }

    async refresh (refreshToken, username) {
        const result = await apiService.getRefreshToken(this._baseUrl + 'refresh', {
            refreshtoken: refreshToken,
            username,
        });
        if (result.status === 200) {
            authenticationManager.updateToken(result.data.data.token, result.data.data.refreshToken, username);
            return result;
        } else {
            authenticationManager.logout();
            window.location.href = '/';
        }
    }

    async logout () {
        const user = authenticationManager.getUsername();
        const refreshToken = authenticationManager.getRefreshToken();

        const result = await apiService.postData(this._baseUrl + 'logout', {
            username: user,
            refreshtoken: refreshToken,
        });
        await result;
        if (result.status === 200) {
            authenticationManager.logout();
            return true;
        }
        return false;
    }

    /**
     * Request a password reset
     * @param username {string}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async requestResetPassword(username) {
        const result = await apiService.postData(`${this._baseUrl}/reset-password/${username}`, null);

        return result.data;
    }

    /**
     * Submit password reset
     * @param passwordRequest {PasswordResetSubmissionDTO}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async submitChangedPassword(passwordRequest) {
        const result = await apiService.postData(`${this._baseUrl}/reset-user-password`, passwordRequest);

        return result.data;
    }
}

const authService = new AuthService();
export default authService;
