import React, { useEffect, useState } from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import { Button, FormControl, TextField } from '@mui/material';
import ConfirmDeleteDialog from './Modal/ConfirmDelete';
import { Row } from 'reactstrap';
import UserApiService from '../api/UserApiService';
import UsersEditMenuButton from './Admin/Users/UsersEditMenuButton';
import ModifyUserModal from './Modal/Admin/ModifyUserModal';
import OrgApiService from '../api/OrgApiService';
import PropTypes from 'prop-types';
import TaskQueryApiService from '../api/TaskQueryApiService';
import usersCopy from 'bootstrap/js/src/dom/selector-engine';

const userApiService = new UserApiService();
const orgApiService = new OrgApiService();

const CONFIRM_CONTENT_DEACTIVATE = 'Are you sure you want to deactivate this user?';
const CONFIRM_CONTENT_ACTIVATE = 'Are you sure you want to activate this user?';

const CONFIRM_TITLE_DEACTIVATE = 'Deactivate User';
const CONFIRM_TITLE_ACTIVATE = 'Activate User';

const Users = (props) => {
    const { setSnackbarProperties } = props;
    const [users, setUsers] = useState([]);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(100);
    const [currentSelectedUser, setCurrentSelectedUser] = useState();
    const [openAddEditUserModal, setOpenAddEditUserModal] = useState(false);
    const [possibleOrgs, setPossibleOrgs] = useState([]);
    const [quickSearchText, setQuickSearchText] = useState('');
    const [searchedUsers, setSearchedUsers] = useState([]);

    const columns = [
        {
            field: 'usId',
            headerName: 'Id',
            flex: 0.5,
        },
        {
            field: 'mainSettingsDto.usUsername',
            headerName: 'Username',
            flex: 2,
            valueGetter: (params) => {
                return params.row.mainSettingsDto.usUsername;
            },
        },
        {
            field: 'mainSettingsDto.usFirstname',
            headerName: 'First Name',
            flex: 2,
            valueGetter: (params) => {
                return params.row.mainSettingsDto.usFirstname;
            },
        },
        {
            field: 'mainSettingsDto.usLastname',
            headerName: 'Last Name',
            flex: 2,
            valueGetter: (params) => {
                return params.row.mainSettingsDto.usLastname;
            },
        },
        {
            field: 'mainSettingsDto.usEmail',
            headerName: 'Email',
            flex: 2,
            valueGetter: (params) => {
                return params.row.mainSettingsDto.usEmail;
            },
        },
        {
            field: 'mainSettingsDto.usActive',
            headerName: 'Active',
            flex: 1,
            valueGetter: (params) => {
                return params.row.mainSettingsDto.usActive;
            },
            renderCell: (params) => {
                if (params.row.mainSettingsDto.usActive > 0) {
                    return 'Yes';
                } else {
                    return 'No';
                }
            },
        },
        {
            field: 'userAdminMainSettings.usOrg',
            headerName: 'Organization',
            flex: 1,
            valueGetter: (params) => {
                return params.row.userOrg?.ogName ?? '';
            },
            renderCell: (params) => {
                return params.row.userOrg?.ogName ?? '';
            }
        },
        {
            field: 'edit',
            headerName: 'edit',
            flex: 0.5,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return <UsersEditMenuButton
                    params={params}
                    setShowDeleteDialog={setShowDeleteDialog}
                    setCurrentSelectedUser={setCurrentSelectedUser}
                    setShowEditDialog={setOpenAddEditUserModal}
                    isActive={Boolean(params.row.mainSettingsDto.usActive)}
                />;
            },
        },
    ];

    const onDeleteClick = async (value) => {
        if (!value) {
            setShowDeleteDialog(false);
            return;
        }
        if (currentSelectedUser?.mainSettingsDto.usActive) {
            const deactivate = await userApiService.deactivateUser(currentSelectedUser.usId);
            if (deactivate && deactivate.success) {
                setSnackbarProperties('Deactivated User', 'warning');
                updateUserActive(currentSelectedUser.usId, false);
            }
        } else {
            const activate = await userApiService.activateUser(currentSelectedUser.usId);
            if (activate && activate.success) {
                setSnackbarProperties('Activated User');
                updateUserActive(currentSelectedUser?.usId, true);
            }
        }
        setShowDeleteDialog(false);
    };

    /**
     * Updates user in state
     * @param userId {number}
     * @param wasActivated {boolean}
     */
    function updateUserActive (userId, wasActivated) {
        const usersCopy = JSON.parse(JSON.stringify(users));
        usersCopy.find(x => {
            return x.usId === userId;
        }).mainSettingsDto.usActive = wasActivated ? 1 : 0;
        setUsers(usersCopy);
        resetSearchedUsers(usersCopy);
    }

    async function populateUsers () {
        const response = await userApiService.getUsers();
        if (response != null && response.success) {
            setUsers(response.data);
            setSearchedUsers(response.data);
        }
    }

    async function getPossibleOrgs () {
        const response = await orgApiService.getSelectableOrgs();
        if (response && response.success) {
            setPossibleOrgs(response.data);
        } else {
            setSnackbarProperties('Unable to get orgs', 'error');
        }
    }

    function handleSearchChange(event) {
        const searchText = event.target.value;
        setQuickSearchText(searchText);
        setSearchedUsers(users.filter((user) => user.mainSettingsDto.usUsername.includes(searchText) || (parseInt(searchText) > 0 && user.usId === parseInt(searchText)) || user.mainSettingsDto.usFirstname.includes(searchText) || user.mainSettingsDto.usLastname.includes(searchText) || user.mainSettingsDto.usEmail.includes(searchText)));
    }

    function resetSearchedUsers(updatedUsers) {
        setSearchedUsers(updatedUsers.filter((user) => user.mainSettingsDto.usUsername.includes(quickSearchText) || (parseInt(quickSearchText) > 0 && user.usId === parseInt(quickSearchText)) || user.mainSettingsDto.usFirstname.includes(quickSearchText) || user.mainSettingsDto.usLastname.includes(quickSearchText) || user.mainSettingsDto.usEmail.includes(quickSearchText)));
    }

    useEffect(() => {
        async function setupData () {
            setLoading(true);
            await populateUsers();
            await getPossibleOrgs();
            setLoading(false);
        }

        setupData();
    }, []);

    return (
        <div>
            <Row>
                <h2>Users</h2>
            </Row>
            <Row>
                <div style={{ display: 'inline-block', justifyContent: 'space-between' }}>
                    <FormControl sx={{ m: 1, minWidth: 120, float: 'left' }}>
                        <Button size={'large'} variant="contained" onClick={() => setOpenAddEditUserModal(true) }>Create new User</Button>
                    </FormControl>
                    <FormControl sx={{ m: 1, minWidth: 200, float: 'right'}}>
                        <TextField
                            value={quickSearchText}
                            onChange={handleSearchChange}
                            label={'Quick Search Users'}
                        />
                    </FormControl>
                </div>
            </Row>
            <Row>
                <Box sx={{ height: '80vh', width: '100%', display: 'block' }}>
                    <DataGridPro
                        rows={searchedUsers}
                        columns={columns}
                        loading={loading}
                        pagination
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        getRowId={(row) => row.usId}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        getRowHeight={() => 'auto'}
                        rowsPerPageOptions={[10, 25, 50, 100, 200]}
                    />
                </Box>
            </Row>

            <ConfirmDeleteDialog
                id="confirm-delete"
                keepMounted
                value={currentSelectedUser}
                open={showDeleteDialog}
                title={currentSelectedUser?.usActive ? CONFIRM_TITLE_DEACTIVATE : CONFIRM_TITLE_ACTIVATE}
                content={currentSelectedUser?.usActive ? CONFIRM_CONTENT_DEACTIVATE : CONFIRM_CONTENT_ACTIVATE}
                onClose={onDeleteClick}
            />
            <ModifyUserModal
                open={openAddEditUserModal}
                orgs={possibleOrgs}
                selectedUser={currentSelectedUser}
                onClose={async (response) => {
                    if (response) {
                        setOpenAddEditUserModal(false);
                        setCurrentSelectedUser(null);
                        await populateUsers();
                    }
                }}
                onCancel={() => {
                    setOpenAddEditUserModal(false);
                    setCurrentSelectedUser(null);
                }}
                setSnackbarProperties={setSnackbarProperties}
                userApiService={userApiService}
                setCurrentSelectedUser={setCurrentSelectedUser}
            />
        </div>
    );
};

Users.propTypes = {
    setSnackbarProperties: PropTypes.func,
};
export default Users;
