import apiService from './apiService';

export default class LocationApiService {

    async getLocations () {
        const locationData = await apiService.getData('api/Project');
        return locationData.data;
    }

    async getLocationsForOrg(orgId) {
        const locationForOrgData = await apiService.getData('api/Project/forOrg/' + orgId);
        return locationForOrgData.data;
    }

    async getFullLocationsForOrg(orgId) {
        const locationForOrgData = await apiService.getData(`api/Project/forOrg/full/${orgId}`);

        return locationForOrgData.data;
    }

    async getPossibleFullLocationsForTask(taskId) {
        const locationForTaskData = await apiService.getData(`api/Project/forTask/full/${taskId}`);

        return locationForTaskData.data;
    }

    async getPossibleFullLocationsForNewTask() {
        const locationsForNewTask = await apiService.getData(`api/Project/forNewTask/full`);

        return locationsForNewTask.data;
    }

    async addLocation (newLocation) {
        const addLocationResponse = await apiService.postData('api/Project', newLocation);
        return addLocationResponse.data;
    }

    async updateLocation (updatedLocation) {
        const updateLocationResponse = await apiService.putData('api/Project/' + updatedLocation.pjId, updatedLocation);
        return updateLocationResponse.data;
    }

    async deleteLocation (deletedLocation) {
        const deleteLocationResponse = await apiService.deleteData('api/Project/' + deletedLocation.pjId);
        return deleteLocationResponse.data;
    }
}
