export class UserAdminMainSettingsDto {
    /**
     * @type{string}
     */
    usUsername;

    /**
     * @type{string}
     */
    usFirstname;

    /**
     * @type{string}
     */
    usLastname;

    /**
     * @type{string}
     */
    usEmail;

    /**
     * @type{number}
     */
    usAdmin;

    /**
     * @type{number}
     */
    usDefaultQuery;

    /**
     * @type{boolean}
     */
    isOrgAdmin;

    /**
     * @type{number}
     */
    usActive;

    /**
     * @type{number}
     */
    usBugsPerPage;

    /**
     * @type{number}
     */
    usForcedProject;

    /**
     * @type{number}
     */
    usOrg;

    /**
     * @type{string}
     */
    usSignature;

    /**
     * @type{number}
     */
    usUseFckeditor;

    /**
     * @type{number}
     */
    usEnableBugListPopups;

    /**
     * @type{string}
     */
    password;

    /**
     * @type{boolean}
     */
    usAddEnabled;

    /**
     * @type{number}
     */
    usTasksPerPage;

    constructor () {
        this.usOrg = 0;
        this.userPassword = '';
        this.confirmPassword = '';
        this.usAdmin = 0;
        this.username = '';
        this.usFirstname = '';
        this.usLastname = '';
        this.usActive = 1;
        this.usAddEnabled = false;
        this.usTasksPerPage = 25;
        this.usEmail = '';
        this.signature = '';
        this.isOrgAdmin = false;
    }
}
