import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button, FormControl, Grid, Input, MenuItem, Select } from '@mui/material';
import { Label } from 'reactstrap';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import OrgApiService from '../../../api/OrgApiService';
import authenticationManager from '../../../auth/AuthenticationManager';
import { compareObjects } from '../../Utilities';
import LocationApiService from '../../../api/LocationApiService';
import { entityAlreadyExists, ok } from '../../../constants/apiStatusConstants';

const orgApiService = new OrgApiService();
const locationApiService = new LocationApiService();

export default function EditLocationModal (props) {
    const {
        open,
        currentLocation,
        onCancel,
        onSave,
        setSnackbarProperties,
    } = props;

    const [locationName, setLocationName] = useState('');
    const [active, setActive] = useState(0);
    const [projectDefault, setProjectDefault] = useState(0);
    const [description, setDescription] = useState('');
    const [organizations, setOrganizations] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState(undefined);
    const [title, setTitle] = useState('Create a new Location');

    const populateOrganizations = async () => {
        let orgResponse = await orgApiService.getOrgs();

        if (orgResponse != null)
        {
            setOrganizations(orgResponse.data);
            if (currentLocation)
            {
                setSelectedOrganization(orgResponse.data.find(org => org.ogId === currentLocation.pjOrg));
            }
        }
    }

    useEffect(() => {
        if (open) {
            if (currentLocation)
            {
                setTitle('Edit ' + currentLocation.pjName + ' Location');
            }
            else
            {
                setTitle('Create a new Location');
            }

            setLocationName(currentLocation?.pjName || '');
            setActive(currentLocation?.pjActive || 0);
            setProjectDefault(currentLocation?.pjDefault || 0);
            setDescription(currentLocation?.pjDescription || '');
            setSelectedOrganization(undefined);

            if (authenticationManager.isSuperAdmin())
            {
                void populateOrganizations();
            }
        }
    }, [open, currentLocation]);

    async function handleSave () {
        let newLocation = getUpdatedLocation();
        let locationResponse;

        if (!locationName)
        {
            setSnackbarProperties('Location name is required', 'error');
            return;
        }

        if (!currentLocation) {
            locationResponse = await locationApiService.addLocation(newLocation);

            if (locationResponse.status === ok)
            {
                setSnackbarProperties('Successfully created new ' + newLocation.pjName);
                onSave();
                return;
            }
        }
        else if (!compareObjects(newLocation, currentLocation)) {
            newLocation.pjId = currentLocation.pjId;

            locationResponse = await locationApiService.updateLocation(newLocation);

            if (locationResponse.status === ok)
            {
                setSnackbarProperties('Successfully updated ' + newLocation.pjName);
                onSave();
                return;
            }
        }
        else
        {
            onSave();
            return;
        }

        if (locationResponse.status === entityAlreadyExists)
        {
            setSnackbarProperties('Location with that name already exists', 'error');
        }
        else
        {
            setSnackbarProperties('Unable to save Location, please try again or contact an administrator.', 'error');
        }
    }

    function getUpdatedLocation () {
        return {
            pjName: locationName,
            pjActive: active,
            pjDefault: projectDefault,
            pjDescription: description,
            pjOrg: selectedOrganization?.ogId,
        };
    }

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '175%' } }}
            maxWidth="lg"
            fullWidth
            open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Label htmlFor="orgName">Location Name</Label>
                                <Input
                                    type="text"
                                    value={locationName}
                                    onChange={(event) => {
                                        setLocationName(event.target.value);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox checked={active === 1}
                                                   onChange={() => setActive(active === 0 ? 1 : 0)}
                                />}
                                label="Active"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox checked={projectDefault === 1}
                                                   onChange={() => setProjectDefault(projectDefault === 0 ? 1 : 0)}
                                />}
                                label="Default Selection in &quot;Location&quot; Dropdown"
                            />
                        </Grid>
                        {authenticationManager.isSuperAdmin() &&
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Label htmlFor="organization">Organization</Label>
                                    <Select
                                        name="organization"
                                        id="organization"
                                        value={selectedOrganization ? selectedOrganization.ogName : ''}
                                        onChange={(event) => {
                                            setSelectedOrganization(organizations.find(org => org.ogName === event.target.value));
                                        }}
                                    >
                                        {organizations.map((org) => (<MenuItem key={org.ogId} value={org.ogName}>
                                            {org.ogName}
                                        </MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Label htmlFor="orgName">Description</Label>
                                <textarea
                                    value={description}
                                    onChange={(event) => {
                                        setDescription(event.target.value);
                                    }}
                                    rows={4}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant="contained" onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}
