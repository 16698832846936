import React, { useEffect, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import EditMenuButton from '../../Modal/EditMenuButton';
import PropTypes from 'prop-types';
import ConfirmDeleteDialog from '../../Modal/ConfirmDelete';
import { Row } from 'reactstrap';
import { Box, Button, FormControl, FormLabel } from '@mui/material';
import ModifyPriorityModal from './ModifyPriorityModal';
import PriorityApiService from '../../../api/PriorityApiService';

const Priorities = (props) => {
    const { setSnackbarProperties } = props;

    const [priorities, setPriorities] = useState([]);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedPriority, setSelectedPriority] = useState();

    const columns = [
        {
            field: 'prId',
            headerName: 'Id',
            flex: 0.5,
        },
        {
            field: 'prName',
            headerName: 'Priority Name',
            flex: 2,
        },
        {
            field: 'prSortSeq',
            headerName: 'Sort Sequence',
            flex: 2,
        },
        {
            field: 'prBackgroundColor',
            headerName: 'Background Color',
            renderCell: (params) => {
                return(<label style={{ backgroundColor: params.row.prBackgroundColor }}>{params.row.prBackgroundColor}</label>)
            },
            flex: 2,
        },
        {
            field: 'edit',
            headerName: 'edit',
            flex: 0.5,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return <EditMenuButton
                    params={params}
                    setShowDeleteDialog={setShowDeleteDialog}
                    setCurrentSelectedRecord={setSelectedPriority}
                    onEditFunc={() => {
                        setShowEditDialog(true);
                        setSelectedPriority(params.row)
                    }}
                />;
            },
        }
    ];

    const priorityApiService = new PriorityApiService();

    async function getPriorities () {
        const priorities = await priorityApiService.GetPriorities();
        setPriorities(priorities.data);
    }

    async function onDeleteClick(value) {
        if (!value) {
            setShowDeleteDialog(false);
            return;
        }

        if (selectedPriority) {
            const deletedPriority = await priorityApiService.deletePriority(selectedPriority.prId);
            if (deletedPriority && deletedPriority.success) {
                setSnackbarProperties('Deleted Priority', 'warning');
                await getPriorities();
            }
        }

        setShowDeleteDialog(false);
    }

    function handleEditClose() {
        setShowEditDialog(false);
        setSelectedPriority(null);
    }

    useEffect(() => {
        getPriorities();
    }, [showEditDialog])

    return(
        <div>
            <Row>
                <h2>Priorities</h2>
            </Row>
            <Row>
                <div style={{ display: 'inline-block', justifyContent: 'space-between' }}>
                    <FormControl sx={{ m: 1, minWidth: 120, float: 'left' }}>
                        <Button size={'large'} variant="contained" onClick={() => setShowEditDialog(true) }>Create new Priority</Button>
                    </FormControl>
                </div>
            </Row>
            <Box sx={{ height: '80vh', width: '100%', display: 'block'}}>
                <DataGridPro
                    columns={columns}
                    rows={priorities}
                    getRowId={(row) => row.prId}
                    getRowHeight={() => 'auto'}
                />
            </Box>
            <ConfirmDeleteDialog
                onClose={onDeleteClick}
                open={showDeleteDialog}
            />
            <ModifyPriorityModal
                open={showEditDialog}
                onClose={handleEditClose}
                selectedPriority={selectedPriority}
                setSnackbarProperties={setSnackbarProperties}
                priorityApiService={priorityApiService}
                onCancel={handleEditClose}
            />
        </div>
    );
}

Priorities.propTypes = {
    setSnackbarProperties: PropTypes.func,
}

export default Priorities;
