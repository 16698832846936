
function compareObjects (obj1, obj2) {
    for (const prop in obj1) {
        if (Object.prototype.hasOwnProperty.call(obj1, prop)) {
            if (obj1[prop] !== obj2[prop]) {
                return false;
            }
        }
    }
    return true;
}

module.exports = {
    compareObjects,
}
