import { FormControl, FormLabel, Grid, Select } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

export default function MonthOfHalfYearField(props) {
    const { value, onChange } = props;

    const helperText = useMemo(() => {
        switch(value) {
        case 1:
            return 'i.e. January and July';
        case 2:
            return 'i.e. February and August';
        case 3:
            return 'i.e. March and September';
        case 4:
            return 'i.e. April and October';
        case 5:
            return 'i.e. May and November';
        case 6:
            return 'i.e. June and December';
        default:
            return '';
        }
    }, [value])

    return(
        <Grid item>
            <FormLabel>Month of the Semi-annual Period</FormLabel>
            <br/>
            <Grid sx={{display: 'flex'}}>
                <FormControl sx={{m: 1, minWidth: 150}}>
                    <Select value={value} onChange={onChange}>
                        <MenuItem value={1}>1st</MenuItem>
                        <MenuItem value={2}>2nd</MenuItem>
                        <MenuItem value={3}>3rd</MenuItem>
                        <MenuItem value={4}>4th</MenuItem>
                        <MenuItem value={5}>5th</MenuItem>
                        <MenuItem value={6}>6th</MenuItem>
                    </Select>
                </FormControl>
                <Typography sx={{fontStyle: 'italic', marginTop: '20px'}}>{helperText}</Typography>
            </Grid>


        </Grid>
    )
}

MonthOfHalfYearField.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};
