export default class ResourceDto {
    /**
     * @type{number}
     */
    rsId;

    /**
     * @type{string}
     */
    rsTitle;

    /**
     * @type{string}
     */
    rsShortDescription;

    /**
     * @type{Date}
     */
    rsCreatedUtc;

    /**
     * @type{Date}
     */
    rsUpdatedUtc;

    /**
     * @type{string}
     */
    rsContent;

    /**
     * @type{number}
     */
    rsResourceTypeId;

    /**
     * @type{ResourceTypeDto}
     */
    rsResourceType;

    /**
     * @type{number}
     */
    resourceAttachmentId;

    /**
     * @type{ResourceAttachmentDto}
     */
    resourceAttachment;

    /**
     * @type{number}
     */
    resourceImageId;

    /**
     * @type{ResourceAttachmentDto}
     */
    resourceImage;

    /**
     * @type{Array<ResourceTopicDto>}
     */
    resourceTopics;

    constructor () {
        this.rsId = 0;
        this.rsTitle = '';
        this.rsShortDescription = '';
        this.rsContent = ''
        this.rsResourceTypeId = 0;
        this.resourceLink = '';
        this.resourceImageLink = '';
        this.resourceTopics = [];
    }
}
