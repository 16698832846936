import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { Input } from '@mui/material';

export default function FileUploadModal (props) {
    const { onClose, open, imageButton, acceptFile, ...other } = props;
    const [labelText] = React.useState('Click or drag to upload file');
    const [isDragOver, setIsDragOver] = React.useState(false);
    const [isMouseOver, setIsMouseOver] = React.useState(false);
    const [imageUrl, setImageUrl] = React.useState();
    const [isFileSelected, setIsFileSelected] = React.useState(false);
    const [fileName, setFileName] = React.useState('');
    const [description, setDescription] = React.useState('');

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragOver(true);
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragOver(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragOver(false);
        setIsMouseOver(false);
        handleChange(e);
    };

    const handleDragEnd = (e) => {
        e.preventDefault();
        setIsDragOver(false);
        setIsMouseOver(false);
    };

    const handleChange = (event) => {
        if (isDragOver) {
            const file = event.dataTransfer.files[0];
            setFileName(file.name);
            setImageUrl(file);
            setIsFileSelected(true);
        } else if (imageButton && event.target.files[0]) {
            setFileName(event.target.files[0].name);
            setImageUrl(event.target.files[0]);
            setIsFileSelected(true);
        }
    };

    const handleCancel = () => {
        onClose(false);
        setIsFileSelected(false);
        setFileName('');
    };

    const handleOk = () => {
        imageUrl.description = description;
        onClose(imageUrl);
    };

    return (
        <Dialog
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                },
                '& .MuiInput-root': {
                    width: '90%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: '16px',
                },
            }}
            maxWidth="xs"
            open={open}
            {...other}
        >
            <DialogTitle variant="h5">Upload an Attachment</DialogTitle>
            <Typography variant="h6" sx={{
                width: '90%',
                marginLeft: 'auto',
                marginRight: 'auto',
            }}>
                Description
            </Typography>
            <Input
                type="text"
                value={description}
                onChange={(event) => {
                    setDescription(event.target.value);
                }}
            />
            <input
                onChange={handleChange}
                accept={acceptFile}
                id="file-upload"
                type="file"
                hidden
            />
            <label htmlFor="file-upload"
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                onDragEnd={handleDragEnd}
            >
                <Box bgcolor={'#fff'}>
                    {(imageButton || isDragOver || isMouseOver) && (
                        <>
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    '& > *': {
                                        m: 1,
                                    },
                                }}
                            >
                                <CloudUploadIcon fontSize="large" />
                                <Typography>{labelText}</Typography>
                            </Box>
                            <Box
                                style={{
                                    display: isFileSelected ? 'flex' : 'none',
                                }}
                            >
                                <Typography>{fileName}</Typography>
                            </Box>
                        </>
                    )}
                </Box>
            </label>
            <DialogActions>
                <Button autoFocus onClick={handleCancel}>
                    Cancel
                </Button>
                <Button onClick={handleOk}>Upload</Button>
            </DialogActions>
        </Dialog>
    );
}

FileUploadModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    imageButton: PropTypes.bool.isRequired,
    acceptFile: PropTypes.func,
};
