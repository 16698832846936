import apiService from './apiService';

export default class StatusApiService {
    /**
     * Gets All Statuses
     * @return {Promise<ServiceResponse<Array<StatusDto>>>}
     */
    async getStatuses () {
        const statuses = await apiService.getData('api/Status');
        return statuses.data;
    }

    /**
     * Add a new status
     * @param statusDto {StatusDto}
     * @return {Promise<ServiceResponse<number>>}
     */
    async createStatus(statusDto) {
        const newId = await apiService.postData('api/Status', statusDto);

        return newId.data;
    }

    /**
     * Update a status
     * @param statusDto {StatusDto}
     * @return {Promise<ServiceResponse<StatusDto>>}
     */
    async updateStatus(statusDto) {
        const updatedStatus = await apiService.putData('api/Status', statusDto);

        return updatedStatus.data;
    }

    /**
     * Delete a status
     * @param statusId {number}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async deleteStatus(statusId) {
        const deletedStatus = await apiService.deleteData('api/Status/' + statusId);

        return deletedStatus.data;
    }

    /**
     * Get the closed status
     * @return {Promise<ServiceResponse<StatusDto>>}
     */
    async getClosedStatus() {
        const closedStatus = await apiService.getData('api/Status/closed');

        return closedStatus.data;
    }
}
