export class StatusDto {
    /**
     * @type{number}
     */
    stId;

    /**
     * @type{string}
     */
    stName;

    /**
     * @type{int}
     */
    stSortSeq;

    constructor () {
        this.stId = 0;
        this.stName = '';
        this.stSortSeq = 0;
    }
}
