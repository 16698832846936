import { createSlice } from '@reduxjs/toolkit';

export const correcttrackSlice = createSlice({
    name: 'correcttrack',
    initialState: {
        taskFilters: {items: [{}]},
        hazardFilters: {items: [{}]},
        taskQueryId: '',
        hazardColumns: {
            hzId: true,
            hzOrganizationName: true,
            hzProject: true,
            hzRiskSource: true,
            hzDepartmentName: true,
            hzProcess: true,
            hzJob: true,
            hzTask: true,
            hzEquipmentId: true,
            hzHazard: true,
            hzAspect: true,
            hzDefect: true,
            hzRiskScoreUncontrolled: true,
            hzRiskLevelUncontrolled: true,
            hzRiskScoreControlled: true,
            hzRisKLevelControlled: true
        },
        taskColumns: undefined,
    },
    reducers: {
        updateTaskFilters: (state, action) => {
            state.taskFilters = action.payload;
        },
        updateHazardFilters: (state, action) => {
            state.hazardFilters = action.payload;
        },
        updateTaskQuery: (state, action) => {
            state.taskQueryId = action.payload;
        },
        updateHazardColumns: (state, action) => {
            state.hazardColumns = action.payload;
        },
        updateTaskColumns: (state, action) => {
            state.taskColumns = action.payload;
        }
    }
});

export const { updateTaskFilters, updateHazardFilters, updateTaskQuery, updateHazardColumns, updateTaskColumns } = correcttrackSlice.actions;

export default correcttrackSlice.reducer;
