import React, { useEffect, useState } from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import OrgApiService from '../api/OrgApiService';
import EditMenuButton from './Modal/EditMenuButton';
import Typography from '@mui/material/Typography';
import { Button, FormControl } from '@mui/material';
import EditOrganizationModal from './Modal/Admin/EditOrganizationModal';
import ConfirmDeleteDialogHazard from './Modal/ConfirmDeleteHazard';
import apiService from '../api/apiService';

const orgApiService = new OrgApiService();

const Organizations = (props) => {
    const { setSnackbarProperties } = props;
    const [organizations, setOrganizations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageSize, setPageSize] = useState(100);
    const [currentSelectedOrganization, setCurrentSelectedOrganization] = useState();
    const [openEditOrganizationModal, setOpenEditOrganizationModal] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const columns = [
        {
            field: 'ogId',
            headerName: 'Id',
            flex: 0.5,
        },
        {
            field: 'ogName',
            headerName: 'Name',
            flex: 1,
        },
        {
            field: 'ogActive',
            headerName: 'Active',
            flex: 0.5,
            renderCell: (params) => {
                if (params.row.ogActive > 0) {
                    return 'Yes';
                } else {
                    return 'No';
                }
            },
        },
        {
            field: 'ogCanSearch',
            headerName: 'Can Search',
            flex: 0.5,
            renderCell: (params) => {
                if (params.row.ogCanSearch > 0) {
                    return 'Yes';
                } else {
                    return 'No';
                }
            },
        },
        {
            field: 'OgNonAdminsCanUse',
            headerName: 'Non-Admin Use',
            flex: 0.5,
            renderCell: (params) => {
                if (params.row.OgNonAdminsCanUse > 0) {
                    return 'Yes';
                } else {
                    return 'No';
                }
            },
        },
        {
            field: 'OgCanOnlySeeOwnReported',
            headerName: 'See Only Own Tasks',
            flex: 0.5,
            renderCell: (params) => {
                if (params.row.OgCanOnlySeeOwnReported > 0) {
                    return 'Yes';
                } else {
                    return 'No';
                }
            },
        },
        {
            field: 'ogOtherOrgsPermissionLevel',
            headerName: 'Other Org Permission Level',
            flex: 1,
            renderCell: (params) => {
                if (params.row.ogOtherOrgsPermissionLevel === 0) {
                    return 'None';
                } else if (params.row.ogOtherOrgsPermissionLevel === 1) {
                    return 'View';
                } else if (params.row.ogOtherOrgsPermissionLevel === 2) {
                    return 'Add/Edit';
                }
            },
        },
        {
            field: 'OgExternalUser',
            headerName: 'External',
            flex: 0.5,
            renderCell: (params) => {
                if (params.row.OgExternalUser > 0) {
                    return 'Yes';
                } else {
                    return 'No';
                }
            },
        },
        {
            field: 'ogCanBeAssignedTo',
            headerName: 'Can Be Assigned',
            flex: 0.5,
            renderCell: (params) => {
                if (params.row.ogCanBeAssignedTo > 0) {
                    return 'Yes';
                } else {
                    return 'No';
                }
            },
        },
        {
            field: 'ogStatusFieldPermissionLevel',
            headerName: 'Status Permission Level',
            flex: 1,
            renderCell: (params) => {
                if (params.row.ogStatusFieldPermissionLevel === 0) {
                    return 'None';
                } else if (params.row.ogStatusFieldPermissionLevel === 1) {
                    return 'View';
                } else if (params.row.ogStatusFieldPermissionLevel === 2) {
                    return 'Add/Edit';
                }
            },
        },
        {
            field: 'ogAssignedToFieldPermissionLevel',
            headerName: 'Assigned To Permission Level',
            flex: 1,
            renderCell: (params) => {
                if (params.row.ogAssignedToFieldPermissionLevel === 0) {
                    return 'None';
                } else if (params.row.ogAssignedToFieldPermissionLevel === 1) {
                    return 'View';
                } else if (params.row.ogAssignedToFieldPermissionLevel === 2) {
                    return 'Add/Edit';
                }
            },
        },
        {
            field: 'ogPriorityFieldPermissionLevel',
            headerName: 'Priority To Permission Level',
            flex: 1,
            renderCell: (params) => {
                if (params.row.ogPriorityFieldPermissionLevel === 0) {
                    return 'None';
                } else if (params.row.ogPriorityFieldPermissionLevel === 1) {
                    return 'View';
                } else if (params.row.ogPriorityFieldPermissionLevel === 2) {
                    return 'Add/Edit';
                }
            },
        },
        {
            field: 'ogDomain',
            headerName: 'Domain',
            flex: 1,
        },
        {
            field: 'edit',
            headerName: 'edit',
            flex: 0.5,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                return <EditMenuButton
                    params={params}
                    setShowDeleteDialog={setShowDeleteDialog}
                    setCurrentSelectedRecord={setCurrentSelectedOrganization}
                    setShowEditDialog={setOpenEditOrganizationModal}
                    onEditFunc={() => {
                        setOpenEditOrganizationModal(true);
                        setCurrentSelectedOrganization(params.row);
                    }}
                />;
            },
        },
    ];

    async function populateOrganizations () {
        const response = await orgApiService.getOrgs();
        if (response != null && response.success) {
            setOrganizations(response.data);
        }
    }

    function onDeleteOrganizationClick (deleteValue) {
        if (deleteValue) {
            apiService.deleteData('api/Organization/' + currentSelectedOrganization.ogId).then(async () => {
                await populateOrganizations();
            });
        }

        setShowDeleteDialog(false);
    };

    const dialogTitle = () => {
        return 'Confirm Delete Organization ' + currentSelectedOrganization?.ogName;
    };

    const dialogLabel = () => {
        return 'Are you sure you want to delete the Organization ' + currentSelectedOrganization?.ogName + '?';
    };

    useEffect(() => {
        async function setupOrganizations () {
            setLoading(true);
            await populateOrganizations();
            setLoading(false);
        }

        setupOrganizations();
    }, []);

    return (
        <div>
            <Typography variant="h4" sx={{ marginBottom: '16px' }}>
                Organizations
            </Typography>
            <FormControl sx={{ marginBottom: '8px' }}>
                <Button size={'large'} variant="contained" onClick={() => setOpenEditOrganizationModal(true)} > Create new Organization</Button>
            </FormControl>
            <Box sx={{ height: '80vh', width: '100%', display: 'block' }}>
                <DataGridPro
                    rows={organizations}
                    columns={columns}
                    loading={loading}
                    pagination
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    getRowId={(row) => row.ogId}
                    components={{
                        Toolbar: GridToolbar,
                    }}
                    getRowHeight={() => 'auto'}
                    rowsPerPageOptions={[10, 25, 50, 100, 200]}
                />
            </Box>
            <EditOrganizationModal
                open={openEditOrganizationModal}
                currentOrganization={currentSelectedOrganization}
                setSnackbarProperties={setSnackbarProperties}
                onCancel={() => {
                    setOpenEditOrganizationModal(false);
                    setCurrentSelectedOrganization(null);
                }}
                onSave={async () => {
                    await populateOrganizations();
                    setOpenEditOrganizationModal(false);
                    setCurrentSelectedOrganization(null);
                }}
            />
            <ConfirmDeleteDialogHazard
                id="confirm-delete"
                keepMounted
                value={currentSelectedOrganization}
                open={showDeleteDialog}
                onClose={onDeleteOrganizationClick}
                deleteTitle={dialogTitle()}
                deleteLabel={dialogLabel()}
            ></ConfirmDeleteDialogHazard>
        </div>
    );
};
export default Organizations;
