import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Backdrop, Box, Button } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import UserMainSettings from './Admin/UserSettingTabs/UserMainSettings';
import authenticationManager from '../../auth/AuthenticationManager';
import UserEmailNotificationSettings from './Admin/UserSettingTabs/UserEmailNotificationSettings';
import UserChangePassword from './Admin/UserSettingTabs/UserChangePassword';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import React, { useEffect, useState } from 'react';
import UserApiService from '../../api/UserApiService';
import TabPanel from './TabPanel';
import { UserAdminMainSettingsDto } from '../../dtos/Users/UserAdminMainSettingsDto';
import { UserAdminEmailNotificationsDto } from '../../dtos/Users/UserAdminEmailNotificationsDto';
import CircularProgress from '@mui/material/CircularProgress';
import TaskQueryApiService from '../../api/TaskQueryApiService';

export default function UserSelfServiceModal(props) {
    const {open, setSnackbarProperties, onClose} = props;

    const[loading, setLoading] = useState(true);
    const[thisUser, setThisUser] = useState();
    const[tabValue, setTabValue] = useState(0);
    const[userMainSettings, setUserMainSettings] = useState(new UserAdminMainSettingsDto());
    const[userEmailNotifications, setUserEmailNotifications] = useState(new UserAdminEmailNotificationsDto());
    const[applyNotificationChangesRetroactively, setApplyNotificationChangesRetroactively] = useState(false);
    const[firstNameError, setFirstNameError] = useState(false);
    const[lastNameError, setLastNameError] = useState(false);

    const userApiService = new UserApiService();
    const taskQueryApiService = new TaskQueryApiService();

    async function loadThisUser() {
        setLoading(true);
        const userData = await userApiService.getThisUser(authenticationManager.getUserId());
        if(userData.success) {
            setThisUser(userData.data);
            setUserMainSettings(userData.data.mainSettingsDto);
            setUserEmailNotifications(userData.data.emailNotificationsDto);
        } else {
            setSnackbarProperties(`Error: ${userData.message ?? userData}`, 'error');
        }
        setLoading(false);
    }

    function a11yProps (index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function handleChange(event, newValue) {
        setTabValue(newValue);
    }

    function handleCancel() {
        onClose();
        setTabValue(0);
    }

    async function handleSave () {
        setLoading(true);
        if(validate()) {
            const updatedUser = JSON.parse(JSON.stringify(thisUser));
            updatedUser.mainSettingsDto = userMainSettings;
            updatedUser.permissionsDto = thisUser.permissionsDto;
            updatedUser.emailNotificationsDto = userEmailNotifications;
            const userUpdateResponse = await userApiService.saveUser(updatedUser);
            if (userUpdateResponse && userUpdateResponse.success) {
                setSnackbarProperties('Successfully updated your settings');
                onClose();
            } else {
                setSnackbarProperties('Unable to update settings: ' + userUpdateResponse.message ?? userUpdateResponse, 'error');
            }
        }
        setLoading(false);

    }

    function validate() {
        let errorMessage = '';
        if(!userMainSettings.usFirstname) {
            errorMessage += 'First Name is Required';
        }
        setFirstNameError(!userMainSettings.usFirstname);

        if(!userMainSettings.usLastname) {
            errorMessage += (errorMessage.length > 0 ? ', ' : '') + 'Last name is required';
        }
        setLastNameError(!userMainSettings.usLastname);

        if(errorMessage.length > 0) {
            setSnackbarProperties(`Error when trying to save: ${errorMessage}`, 'error');
            return false;
        } else {
            return true;
        }
    }

    useEffect(() => {
        if(open) {
            loadThisUser();
        }
    }, [open]);

    return(
            <Dialog
                closeAfterTransition={true}
                sx={{ '& .MuiDialog-paper': { width: '175%' } }}
                maxWidth="lg"
                fullWidth
                open={open}
            >
                <DialogTitle>Edit Your User Profile</DialogTitle>
                <DialogContent dividers>
                    {loading ? <Backdrop open={loading}><CircularProgress/></Backdrop> :
                        <Box>
                            <Box>
                                <Tabs
                                    value={tabValue}
                                    onChange={handleChange}
                                >
                                    <Tab label="Main Settings" {...a11yProps(0)} />
                                    <Tab label="Email Notification Settings" {...a11yProps(1)} />
                                    <Tab label="Change Password" {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            <TabPanel
                                value={tabValue}
                                index={0}
                            >
                                <UserMainSettings
                                    userAdminMainSettings={userMainSettings}
                                    setUserAdminMainSettings={setUserMainSettings}
                                    userId={authenticationManager.getUserId()}
                                    isCurrentUserSuperAdmin={authenticationManager.isSuperAdmin()}
                                    firstNameError={firstNameError}
                                    lastNameError={lastNameError}
                                    editAdmin={false}
                                    setSnackbarProperties={setSnackbarProperties}
                                />
                            </TabPanel>
                            <TabPanel
                                value={tabValue}
                                index={1}
                            >
                                <UserEmailNotificationSettings
                                    userAdminEmailNotification={userEmailNotifications}
                                    setNewUserAdminEmailNotification={setUserEmailNotifications}
                                    applyNotificationChangesRetroactively={applyNotificationChangesRetroactively}
                                    setApplyNotificationChangesRetroactively={setApplyNotificationChangesRetroactively}
                                />
                            </TabPanel>
                            <TabPanel
                                value={tabValue}
                                index={2}
                            >
                                <UserChangePassword
                                    setSnackbarProperties={setSnackbarProperties}
                                    user={thisUser}
                                />
                            </TabPanel>
                        </Box>
                    }

                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        variant='contained'
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant='contained'
                        onClick={handleSave}
                        disabled={tabValue === 3 || loading}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

    );
}

UserSelfServiceModal.propTypes = {
    setSnackbarProperties: PropTypes.func,
};
