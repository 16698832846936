import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import TaskApiService from '../../api/TaskApiService';

const PrinterFriendlyTask = () => {
    const params = useParams();
    const [taskId] = useState(params.taskId);
    const [taskData, setTaskData] = useState({});

    const taskService = new TaskApiService();

    useEffect(() => {
        async function fetchData () {
            const thisTaskData = await taskService.getTask(taskId);
            setTaskData(thisTaskData.data);
        }
        void fetchData();
    }, [taskId]);

    return <div>
        <div>
            <b>
                Task ID:&nbsp;
                <a href={'/Task/' + taskId}>{taskId}</a>
            </b>
        </div>
        <div>
            <b>
                Short description:&nbsp;
                <a href={'/Task/' + taskId}>{taskData?.taskDescription}</a>
            </b>
        </div>
        <div>
            <table id={'printTaskDataTable'} cellSpacing={'0'} cellPadding={'3'} border={'1'}>
                <tbody>
                    <tr>
                        <td>Reported By</td>
                        <td>{taskData.createdByUser}</td>
                    </tr>
                    <tr>
                        <td>Reported On</td>
                        <td>{taskData.createdDate}</td>
                    </tr>
                    {taskData?.taskFields?.map((data) => {
                        return (
                            <tr key={data.fieldLabel}>
                                <td>{data.fieldLabel}</td>
                                <td>{data.selectedOption?.optionValue ??
                                data.stringValue ??
                                data.intValue ??
                                data.dateTimeValue ??
                                data.doubleValue ??
                                ''}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <br/>
            <table id={'posts_table'} cellSpacing={3} cellPadding={0} border={0}>
                <tbody>
                    {taskData?.taskComments?.filter(data => data.taskChangeType === 'Comment' || data.taskChangeType === 'comment')
                        .map((data) => {
                            return (
                                <tr key={data.taskChange}>
                                    <td className={'cmt'}>
                                        <table width={'100%'}>
                                            <tbody>
                                                <tr>
                                                    <td align={'left'}>
                                                        <span className={'pst'}>
                                                comment posted by {data.taskChangeUser} on {data.taskChangeTime}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table border={0}>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <span className={'cmt_text'}>
                                                            <p dangerouslySetInnerHTML={{ __html: data.taskChange }} />
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            );
                        },
                        )}
                </tbody>
            </table>
        </div>
    </div>;
};

export default PrinterFriendlyTask;
