import apiService from './apiService';

export default class AuditChecklistItemApiService {

    async getAuditChecklistItems (auditChecklistId) {
        const auditChecklistItemResponse = await apiService.getData('api/AuditChecklistItem/' + auditChecklistId);
        return auditChecklistItemResponse.data;
    }

    async addAuditChecklistItem (newAuditChecklist) {
        const addAuditChecklistItemResponse = await apiService.postData('api/AuditChecklistItem', newAuditChecklist);
        return addAuditChecklistItemResponse.data;
    }

    async updateAuditChecklistItem (updatedAuditChecklist) {
        const updateAuditChecklistItemResponse = await apiService.putData('api/AuditChecklistItem/' + updatedAuditChecklist.ciId, updatedAuditChecklist);
        return updateAuditChecklistItemResponse.data;
    }

    async deleteAuditChecklistItem (deletedAuditChecklist) {
        const deleteAuditChecklistItemResponse = await apiService.deleteData('api/AuditChecklistItem/' + deletedAuditChecklist.ciId);
        return deleteAuditChecklistItemResponse.data;
    }
}
