import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Box, Button, FormControl, Grid } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import AuditChecklistHistoryApiService from '../../api/AuditChecklistHistoryApiService';
import AuditChecklistHistoryItemApiService from '../../api/AuditChecklistHistoryItemApiService';
import TaskApiService from '../../api/TaskApiService';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Link, useNavigate } from 'react-router-dom';

const auditChecklistHistoryApiService = new AuditChecklistHistoryApiService();
const auditChecklistHistoryItemApiService = new AuditChecklistHistoryItemApiService();
const taskApiService = new TaskApiService();

export default function StartAuditChecklistModal (props) {
    const {
        open,
        currentAuditChecklist,
        onCancel,
        onSave,
        setSnackbarProperties
    } = props;

    const navigate = useNavigate();

    const [auditChecklistItems, setAuditChecklistItems] = useState([]);
    const [openAuditChecklistTasksModal, setOpenAuditChecklistTasksModal] = useState(false);
    const [tasksCreated, setTasksCreated] = useState([]);

    const tasksCreatedColumns = [
        {
            field: 'taskId',
            headerName: 'Task',
            flex: 1,
            renderCell: (params) => {
                return (
                    <Link
                        to={`/Task/${params.value}`}
                        style={{
                            textDecoration: 'underline',
                            color: 'blue',
                            cursor: 'pointer',
                        }}
                    >
                        {params.value}
                    </Link>
                );
            },
        },
        {
            field: 'prompt',
            headerName: 'Checklist Prompt',
            flex: 3,
        },
    ];

    useEffect(() => {
        if (open) {
            for (const checklist of currentAuditChecklist.auditChecklistItems) {
                checklist.response = '';
            }

            setAuditChecklistItems(currentAuditChecklist.auditChecklistItems);
            setTasksCreated([]);
        }
    }, [open]);

    function handleCancel () {
        onCancel();
    }

    async function handleSave () {
        for (const checklist of auditChecklistItems) {
            if (checklist.response === '') {
                setSnackbarProperties('All prompts are required before completing the audit', 'error');
                return;
            }
        }

        let auditChecklistHistoryResponse = await auditChecklistHistoryApiService.addAuditChecklistHistory(getAuditChecklistHistory());
        let auditChecklistHistoryId = auditChecklistHistoryResponse.data.chId;

        await getAuditChecklistHistoryItems(auditChecklistHistoryId);

        setSnackbarProperties(`${currentAuditChecklist.acTitle} complete`);
        onSave();
        setOpenAuditChecklistTasksModal(true);
    }

    function handleOk () {
        setOpenAuditChecklistTasksModal(false);
    }

    async function getAuditChecklistHistoryItems (auditChecklistHistoryId) {
        let tempAuditChecklistItems = [];
        let tasksCreated = [];

        for (const checklist of auditChecklistItems) {
            let basicTaskResponse;
            if (checklist.response === "1") {
                let checklistTask = {
                    shortDescription: checklist.ciPrompt,
                    projectId: currentAuditChecklist.projectId,
                }

                basicTaskResponse = await taskApiService.addNewBasicTask(checklistTask);

                let checklistTaskComment = {
                    taskChange: `Answered 'no' to '${checklist.ciPrompt}' for checklist '${currentAuditChecklist.acTitle}'`,
                    taskId: basicTaskResponse.data,
                    taskChangeType: 'Comment',
                }

                await taskApiService.addTaskComment(checklistTaskComment);

                let taskCreated = {
                    taskId: basicTaskResponse.data,
                    prompt: checklist.ciPrompt
                }

                tasksCreated.push(taskCreated);
            }

            let checklistToSend = {
                hiAuditChecklistHistoryId: auditChecklistHistoryId,
                hiPrompt: checklist.ciPrompt,
                hiSortSeq: checklist.ciSortSeq,
                taskId: basicTaskResponse?.data,
            }

            tempAuditChecklistItems.push(checklistToSend);
        }

        await auditChecklistHistoryItemApiService.addAuditChecklistHistoryItems(tempAuditChecklistItems);

        if (tasksCreated.length > 0) {
            setTasksCreated(tasksCreated);
        }
    }

    function getAuditChecklistHistory () {
        return {
            chTitle: currentAuditChecklist.acTitle,
            chAuditChecklistsId: currentAuditChecklist.acId,
        }
    }

    const handleRadioChange = (index, value) => {
        const updatedChecklistItems = [...currentAuditChecklist.auditChecklistItems];
        updatedChecklistItems[index].response = value;
        setAuditChecklistItems(updatedChecklistItems);
    };

    return (
        <>
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '100%' } }}
                maxWidth="xl"
                fullWidth
                open={open}>
                <DialogTitle>{currentAuditChecklist.acTitle} Audit</DialogTitle>
                <DialogContent dividers>
                    <Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {auditChecklistItems.map((checklist, index) => (
                                    <Grid key={index} item xs={12} justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            {checklist.ciPrompt}
                                        </Grid>
                                        <Grid item>
                                            <FormControl>
                                                <RadioGroup
                                                    row
                                                    onChange={(event) => {
                                                        handleRadioChange(index, event.target.value);
                                                    }}
                                                    value={checklist.response}
                                                >
                                                    <FormControlLabel value={'0'} control={<Radio />} label='Yes' />
                                                    <FormControlLabel value={'1'} control={<Radio />} label='No' />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button variant="contained" onClick={handleSave}>Complete</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                sx={{ '& .MuiDialog-paper': { width: '100%' } }}
                maxWidth="lg"
                fullWidth
                open={openAuditChecklistTasksModal}>
                <DialogTitle>{currentAuditChecklist.acTitle} Tasks Created</DialogTitle>
                <DialogContent dividers>
                    <Box>
                        <DataGridPro
                            rows={tasksCreated}
                            columns={tasksCreatedColumns}
                            getRowId={(row) => row.taskId}
                            localeText={{ noRowsLabel: 'No Tasks Created' }}
                            autoHeight
                            disableColumnMenu
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" autoFocus onClick={handleOk}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    );
}
