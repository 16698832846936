import React, { Component } from 'react';
import loading from '../assets/loading.gif';
import authService from '../auth/AuthService';
import localSettingsManager from '../localSettings/localSettingsManager';

export class Logout extends Component {
    componentDidMount () {
        authService.logout().then(result => {
            if (result) {
                localSettingsManager.setSettingValue('persist:root', '')
                window.location.href = '/';
            }
        });
    }

    render () {
        return (<div>
            <div className="custom-logo">
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-md-4 pb-3 pt-3 offset-md-4 text-center">
                        <div className="logo">
                            <div className="title"><em></em></div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 offset-md-4">
                        <div>
                            <div className="logout-container">
                                <img src={loading}
                                    alt="loading..."
                                    style={{ alignSelf: 'center' }} />
                            </div>
                            <div className='logout-container'>
                                Logging out...
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}
