import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import { FormControl, FormLabel, MenuItem, Select } from '@mui/material';
import TaskApiService from '../../api/TaskApiService';
import { FormGroup } from 'reactstrap';
import { DataGridPro } from '@mui/x-data-grid-pro';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDeleteDialog from './ConfirmDelete';
import PropTypes from 'prop-types';
import BasicUserDto from '../../dtos/Users/BasicUserDto';

const taskApiService = new TaskApiService();

export default function TaskSubscriptionModal (props) {
    const { onClose, open, taskId, setSnackbarProperties, availableUsers, ...other } = props;
    const [selectedSubscription, setSelectedSubscription] = useState({});
    const [subscribers, setSubscribers] = useState([]);
    const [availableUsersToSubscribe, setAvailableUsersToSubscribe] = useState([]);
    const [selectedUser, setSelectedUser] = useState({});

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const [columns] = useState([
        {
            field: 'username',
            headerName: 'User',
            flex: 1,
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
        },
        {
            field: 'email',
            headerName: 'Email',
            minWidth: 110,
            flex: 2,
        },
        {
            field: 'delete',
            headerName: 'Unsubscribe',
            renderCell: (params) => {
                return <IconButton
                    aria-label={'Delete'}
                    onClick={() => {
                        setShowDeleteDialog(true);
                        setSelectedSubscription(params.row);
                    }
                    }
                >
                    <DeleteIcon />
                </IconButton>;
            },
        },
    ]);

    const subscribeUser = async () => {
        const response = await taskApiService.subscribeToTask(taskId, selectedUser);
        if (response.success) {
            setSnackbarProperties('Successfully subscribed user');

            const addedSubscriberList = [...subscribers, response.data];
            setSubscribers(addedSubscriberList);

            const updatedAvailableUsers = filterUserLists(addedSubscriberList, availableUsers);
            setAvailableUsersToSubscribe(updatedAvailableUsers);

            setSelectedUser('');
        } else {
            setSnackbarProperties('Error when subscribing user: ' + response.message, 'error');
        }
    };

    const confirmDelete = async (value) => {
        if (value) {
            const deletedSubscription = await taskApiService.unsubscribeFromTask(selectedSubscription.taskId, selectedSubscription.userId);
            if (deletedSubscription.success) {
                setSnackbarProperties('User Unsubscribed');
                await GetSubscriptions();
                const addedAvailableUser = [...availableUsers, new BasicUserDto(deletedSubscription.data.userId, deletedSubscription.data.username)];
                setAvailableUsersToSubscribe(addedAvailableUser);
            } else {
                // Notification that it wasn't successful
                setSnackbarProperties('Unabled to Unsubscribe User: ' + deletedSubscription.message, 'error');
            }
        }
        setShowDeleteDialog(false);
    };

    const handleClose = () => {
        setSelectedUser('');
        onClose(false);
    };

    const filterUserLists = (subscribers, availableUsers) => {
        const subscriberUserIds = subscribers.map(s => s.userId);
        const availableUsersToSubscribe = availableUsers.filter(user => !subscriberUserIds.includes(user.userId));
        return availableUsersToSubscribe;
    };

    const GetSubscriptions = async () => {
        if(taskId > 0) {
            const response = await taskApiService.getSubscribersForTask(taskId);
            if (response.success) {
                setSubscribers(response.data);
            } else {
                setSnackbarProperties('Unable to retrieve Subscriptions: ' + response.message, 'error');
            }
        }

    };

    const GetAvailableUsers = async () => {
        const subscriberUserIds = subscribers.map(s => s.userId);
        const availableUsersToSubscribe = availableUsers.filter(user => !subscriberUserIds.includes(user.userId));
        setAvailableUsersToSubscribe(availableUsersToSubscribe);
    };

    useEffect(() => {
        async function getData () {
            await GetSubscriptions();
            await GetAvailableUsers();
            setSelectedUser('');
        };

        if(open) {
            void getData();
        }
    }, [open]);

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: 1000, maxHeight: 1000 } }}
            maxWidth="md"
            open={open}
            {...other}
        >
            <DialogTitle>Task Subscriptions</DialogTitle>
            <DialogContent
                dividers
                style={{ width: '100%' }}
            >
                <FormGroup>
                    <FormControl>
                        <FormLabel>Available Users to Subscribe:</FormLabel>
                        <Select
                            value={selectedUser}
                            autoWidth
                            displayEmpty
                            onChange={(event) => setSelectedUser(event.target.value)}
                        >
                            <MenuItem value=""><em>Select a User</em></MenuItem>
                            {availableUsers?.map(option => {
                                return (
                                    <MenuItem
                                        key={option.userId}
                                        value={option.userId}
                                    >
                                        {option.username}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>

                </FormGroup>

                <Button
                    onClick={subscribeUser}
                    disabled={selectedUser === ''}
                >
                    Subscribe User
                </Button>
                <br/><br/>
                <div style={{ height: 300, width: '100%' }}>
                    <DataGridPro
                        columns={columns}
                        rows={subscribers}
                        getRowId={(row) => row.taskId + '' + row.userId}
                    />
                </div>

            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
            <ConfirmDeleteDialog onClose={confirmDelete} open={showDeleteDialog} />
        </Dialog>
    );
}

TaskSubscriptionModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    taskId: PropTypes.number.isRequired,
    setSnackbarProperties: PropTypes.func.isRequired,
    availableUsers: PropTypes.arrayOf(BasicUserDto).isRequired,
};
