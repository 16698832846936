import React, { useEffect, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import Box from '@mui/material/Box';
import ConfirmDeleteDialog from './Modal/ConfirmDelete';
import QueryColumnSelection from './Modal/Queries/QueryColumnSelection';
import { Button } from '@mui/material';
import QueryEditMenuButton from './Queries/QueryEditMenuButton';
import TaskQueryApiService from '../api/TaskQueryApiService';
import PropTypes from 'prop-types';

const Queries = (props) => {
    const { setSnackbarProperties } = props;
    const [queries, setQueries] = useState([]);
    const [loading, setLoading] = useState(true);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [currentSelectedQuery, setCurrentSelectedQuery] = useState(0);
    const [componentKey, setComponentKey] = useState(0);

    const taskQueryApiService = new TaskQueryApiService();

    const columns = [
        { field: 'queryId', headerName: 'id', width: 90, editable: true },
        { field: 'queryName', headerName: 'Name', minWidth: 90, flex: 1 },
        { field: 'taskQueryDescription', headerName: 'Description', minWidth: 150, flex: 3 },
        {
            field: 'edit',
            headerName: 'Edit',
            sortable: false,
            minWidth: 40,
            renderCell: (params) => {
                return <QueryEditMenuButton params={params}
                    setCurrentSelectedQuery={setCurrentSelectedQuery}
                    setShowEditDialog={setShowEditDialog}
                    setShowDeleteDialog={setShowDeleteDialog}
                />;
            },
        },
    ];

    async function onDeleteClick (value) {
        if (value) {
            const deletedQuery = await taskQueryApiService.deleteTaskQuery(currentSelectedQuery);
            if(deletedQuery && deletedQuery.success) {
                setSnackbarProperties('Successfully deleted query');
            } else if(deletedQuery && deletedQuery.message) {
                setSnackbarProperties(`Error deleting Query: ${deletedQuery.message}`, 'error');
            } else {
                setSnackbarProperties(`Error deleting Query: ${deletedQuery}`);
            }
            const componentKeyAdd = componentKey + 1;
            setComponentKey(componentKeyAdd);
            setShowDeleteDialog(false);
        } else {
            setShowDeleteDialog(false);
        }
    }

    async function populateQueries () {
        const data = await taskQueryApiService.getAllQueries();
        setQueries(data.data);
        setLoading(false);
    }

    useEffect(() => {
        if (!showEditDialog) {
            populateQueries();
        }
    }, [componentKey, showEditDialog]);

    return (
        <Box sx={{ height: '80vh', width: '100%' }}>
            <h1>Queries</h1>
            <br/>
            <Button
                variant={'contained'}
                onClick={() => {
                    setCurrentSelectedQuery(0);
                    setShowEditDialog(true);
                }}>Add New Query</Button>
            <DataGridPro
                rows={queries}
                columns={columns}
                pageSize={25}
                loading={loading}
                getRowId={(row) => row.queryId}
            />

            <ConfirmDeleteDialog
                id="confirm-delete"
                keepMounted
                value={currentSelectedQuery}
                open={showDeleteDialog}
                onClose={onDeleteClick}
            />

            <QueryColumnSelection
                open={showEditDialog}
                setIsOpen={setShowEditDialog}
                queryId={currentSelectedQuery}
                setSnackbarProperties={setSnackbarProperties}
            />
        </Box>
    );
};
export default Queries;

Queries.propTypes = {
    setSnackbarProperties: PropTypes.func,
};
