import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { Button, FormControl, Grid, MenuItem, Select, TextField } from '@mui/material';
import { Label } from 'reactstrap';
import DialogActions from '@mui/material/DialogActions';
import OrgApiService from '../../../api/OrgApiService';
import authenticationManager from '../../../auth/AuthenticationManager';
import LocationApiService from '../../../api/LocationApiService';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { DataGridPro } from '@mui/x-data-grid-pro';
import AuditChecklistItemApiService from '../../../api/AuditChecklistItemApiService';
import AuditChecklistApiService from '../../../api/AuditChecklistApiService';
import { compareObjects } from '../../Utilities';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDeleteDialogHazard from '../ConfirmDeleteHazard';

const orgApiService = new OrgApiService();
const locationApiService = new LocationApiService();
const auditChecklistItemApiService = new AuditChecklistItemApiService();
const auditChecklistApiService = new AuditChecklistApiService();

const emptyLocation = {
    projectId: 0,
    projectName: '\u00A0'
}

const emptyOrganization = { ogId: 0, ogName: '\u00A0' }

export default function EditAuditChecklistModal (props) {
    const {
        open,
        currentAuditChecklist,
        onCancel,
        onSave,
        setSnackbarProperties,
    } = props;
    const [title, setTitle] = useState('Create Audit Checklist');
    const [loading, setLoading] = useState(false);
    const [auditChecklistTitle, setAuditChecklistTitle] = useState('');
    const [organizations, setOrganizations] = useState([emptyOrganization]);
    const [selectedOrganization, setSelectedOrganization] = useState(emptyOrganization);
    const [locations, setLocations] = useState([emptyLocation]);
    const [selectedLocation, setSelectedLocation] = useState(emptyLocation);
    const [active, setActive] = useState(false);
    const [auditChecklistItems, setAuditChecklistItems] = useState([]);
    const [currentSelectedAuditChecklistItem, setCurrentSelectedAuditChecklistItem] = useState();
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);

    const columns = [
        {
            field: 'ciPrompt',
            headerName: 'Prompt',
            flex: 1,
            editable: true,
        },
        {
            field: 'ciSortSeq',
            headerName: 'Sort',
            flex: .5,
            editable: true,
        },
        {
            field: 'delete',
            headerName: 'delete',
            flex: 0.25,
            filterable: false,
            sortable: false,
            renderCell: (params) => {
                const handleDeleteClick = () => {
                    setCurrentSelectedAuditChecklistItem(params.row);
                    setShowDeleteDialog(true);
                }

                return (<Button
                    variant="contained"
                    color="error"
                    onClick={handleDeleteClick}
                    size="small"
                    startIcon={<DeleteIcon />}
                >
                    Delete
                </Button>)
            },
        },
    ];

    useEffect(() => {
        if (open) {
            if (currentAuditChecklist) {
                setTitle('Edit Audit Checklist ' + currentAuditChecklist.acTitle);
            }
            else {
                setTitle('Create Audit Checklist');
            }

            setAuditChecklistTitle(currentAuditChecklist?.acTitle || '');
            setActive(currentAuditChecklist?.acIsActive || false);
            setSelectedOrganization(emptyOrganization);
            setSelectedLocation(emptyLocation);
            setAuditChecklistItems([]);

            if (authenticationManager.isSuperAdmin()) {
                orgApiService.getOrgs().then(orgResponse => {
                    let organizationList = [emptyOrganization];
                    organizationList = organizationList.concat(orgResponse.data);

                    setOrganizations(organizationList);

                    setSelectedOrganization(currentAuditChecklist ? {
                        ogId: currentAuditChecklist.organizationId,
                        ogName: currentAuditChecklist.organizationName
                    } : emptyOrganization);
                });
            }

            if (currentAuditChecklist) {
                updateLocationsForOrganization(currentAuditChecklist.organizationId).then(() => {
                    setSelectedLocation({ projectId: currentAuditChecklist.acProject,
                        projectName: currentAuditChecklist.projectName });
                })

                setLoading(true);
                auditChecklistItemApiService.getAuditChecklistItems(currentAuditChecklist.acId).then(auditChecklistItemResponse => {
                    setAuditChecklistItems(auditChecklistItemResponse.data);
                    setLoading(false);
                });
            }
        }
    }, [open, currentAuditChecklist]);

    const dialogTitle = () => {
        return 'Confirm Delete Audit Checklist ' + currentSelectedAuditChecklistItem?.ciPrompt;
    };

    const dialogLabel = () => {
        return 'Are you sure you want to delete the Audit Checklist ' + currentSelectedAuditChecklistItem?.ciPrompt + '?';
    };

    async function onDeleteAuditChecklistItem (deleteValue) {
        if (deleteValue) {
            if (!currentSelectedAuditChecklistItem.newItem) {
                await auditChecklistItemApiService.deleteAuditChecklistItem(currentSelectedAuditChecklistItem);
            }

            setAuditChecklistItems(
                auditChecklistItems.filter(item => {
                    return item.ciId !== currentSelectedAuditChecklistItem.ciId;
                })
            );

            setSnackbarProperties('Successfully deleted Audit Checklist ' + currentSelectedAuditChecklistItem.ciId);

            setShowDeleteDialog(false);
            setCurrentSelectedAuditChecklistItem(null);
        }
    }

    async function handleSave () {
        let newAuditChecklist = getUpdatedAuditChecklist();
        let auditChecklistResponse = { success: true };
        let createAuditChecklist = false;

        if (!currentAuditChecklist) {
            createAuditChecklist = true;
            auditChecklistResponse = await auditChecklistApiService.addAuditChecklist(newAuditChecklist);
        }
        else {
            newAuditChecklist.acId = currentAuditChecklist.acId;
            if (!compareObjects(newAuditChecklist, currentAuditChecklist)) {

                auditChecklistResponse = await auditChecklistApiService.updateAuditChecklist(newAuditChecklist);
            }
        }

        if (auditChecklistResponse.success) {
            for (let item of auditChecklistItems) {
                item.ciAuditChecklist = currentAuditChecklist?.acId || auditChecklistResponse.data;

                let auditChecklistItemResponse = { success: true };
                if (item.updateItem) {
                    auditChecklistItemResponse = await auditChecklistItemApiService.updateAuditChecklistItem(item);
                }
                else if(item.newItem) {
                    auditChecklistItemResponse = await auditChecklistItemApiService.addAuditChecklistItem(item);
                }

                if (!auditChecklistItemResponse.success) {
                    setSnackbarProperties('Unable to save Audit Checklist, try again or contact an administrator.', 'error');
                    return;
                }
            }

            if (createAuditChecklist) {
                setSnackbarProperties('Successfully created new ' + auditChecklistTitle);
            }
            else {
                setSnackbarProperties('Successfully updated ' + auditChecklistTitle);
            }

            onSave();
            return;
        }

        setSnackbarProperties('Unable to save Audit Checklist, try again or contact an administrator.', 'error');
    }

    function getUpdatedAuditChecklist () {
        return {
            acProject: selectedLocation.projectId,
            acIsActive: active,
            acTitle: auditChecklistTitle,
            projectName: selectedLocation.pjName,
            organizationId: selectedOrganization.ogId,
            organizationName: selectedOrganization.ogName,
        };
    }

    async function updateLocationsForOrganization(organizationId) {
        setSelectedLocation(emptyLocation);
        let locationsForOrgResponse = await locationApiService.getLocationsForOrg(organizationId);

        let locationList = [];
        let noSelectedLocation = emptyLocation;
        locationList.push(noSelectedLocation);

        locationList = locationList.concat(locationsForOrgResponse.data.projects);

        // If there's only one Location... easy choice.
        if (locationList.length === 2) {
            setSelectedLocation(locationList[1]);
        }

        setLocations(locationList);
    }

    function handleCellCommit(params) {
        let auditChecklistItem = auditChecklistItems?.find(item => item.ciId === params.row.ciId);

        if (auditChecklistItem) {
            if (params.field === 'ciPrompt') {
                auditChecklistItem.ciPrompt = params.value;
            }
            else {
                auditChecklistItem.ciSortSeq = params.value;
            }

            if (!auditChecklistItem.newItem) {
                auditChecklistItem.updateItem = true;
            }
        }
    }

    function onNewItem() {
        let newAuditChecklistItems = [...auditChecklistItems];

        let newId = 0;
        let newSortSeq = 0;
        newAuditChecklistItems.forEach(item => {
            if (item.ciId > newId) {
                newId = item.ciId;
            }

            if (item.ciSortSeq > newSortSeq) {
                newSortSeq = item.ciSortSeq;
            }
        });

        newAuditChecklistItems.push({
            ciId: ++newId,
            ciPrompt: '',
            ciSortSeq: ++newSortSeq,
            newItem: true,
        });

        setAuditChecklistItems(newAuditChecklistItems);
    }

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '175%', height: '100vh' }}}
            maxWidth="lg"
            fullWidth
            open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Label>Audit Checklist Title</Label>
                            <TextField
                                type="text"
                                value={auditChecklistTitle}
                                onChange={(event) => {
                                    setAuditChecklistTitle(event.target.value);
                                }}
                            />
                        </FormControl>
                    </Grid>
                    {authenticationManager.isSuperAdmin() &&
                        <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Label>Organizations</Label>
                            <Select
                            sx={{ width: 1 }}
                            value={selectedOrganization?.ogId}
                            onChange={(event) => {
                                setSelectedOrganization(organizations.find(org => org.ogId === event.target.value));
                                void updateLocationsForOrganization(event.target.value);
                            }}
                            >
                                {organizations.map((org) => {
                                    return (
                                        <MenuItem
                                            key={org.ogId}
                                            value={org.ogId}
                                        >
                                            {org.ogName}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                            </FormControl>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <Label>Locations</Label>
                            <Select
                                sx={{ width: 1 }}
                                value={selectedLocation?.projectId}
                                onChange={(event) => {
                                    setSelectedLocation(locations.find(location => location.projectId === event.target.value));
                                }}
                            >
                                {locations.map((location) => {
                                    return (
                                        <MenuItem
                                            key={location.projectId}
                                            value={location.projectId}
                                        >
                                            {location.projectName}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={<Checkbox checked={active}
                                               onChange={() => setActive(!active)}
                            />}
                            label="Active"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Label>Audit Checklist Items</Label>
                        </Grid>
                        <Grid item xs={12}>
                            <Button variant="contained" onClick={onNewItem} sx={{ marginBottom: '8px' }}>
                                Add New Item
                            </Button>
                        </Grid>
                        <DataGridPro
                            rows={auditChecklistItems}
                            columns={columns}
                            loading={loading}
                            autoHeight
                            getRowId={(row) => row.ciId}
                            sortModel={[{
                                field: 'ciSortSeq',
                                sort: 'asc',
                            }]}
                            onCellEditCommit={(params) => handleCellCommit(params)}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant="contained" onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleSave}>Save</Button>
            </DialogActions>
            <ConfirmDeleteDialogHazard
                keepMounted
                value={currentSelectedAuditChecklistItem}
                open={showDeleteDialog}
                onClose={onDeleteAuditChecklistItem}
                deleteTitle={dialogTitle()}
                deleteLabel={dialogLabel()}
            ></ConfirmDeleteDialogHazard>
        </Dialog>
    );
}
