import { configureStore } from '@reduxjs/toolkit';
import correcttrackReducer from './correcttrackSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, correcttrackReducer);
export const correcttrackStore = configureStore({
    reducer: {
        correcttrack: persistedReducer,
        middleware: [thunk]
    },
});

export const persistor = persistStore(correcttrackStore);
