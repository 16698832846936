import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';

const ITEM_HEIGHT = 48;

const HazardEditMenuButton = (props) => {
    const { params, setShowDeleteDialog, setCurrentSelectedHazard } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onEditClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking

        window.location.href = '/Hazard/' + params.row.hzId;
    };

    const onDeleteClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking

        setCurrentSelectedHazard(params.row);
        setShowDeleteDialog(true);
        handleClose();
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    return <div>
        <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
        >
            <MoreVertIcon />
        </IconButton>
        <Menu
            id="long-menu"
            MenuListProps={{
                'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                },
            }}
        >

            <MenuItem key={1} onClick={(e) => {
                onEditClick(e);
            }}>
                Edit
            </MenuItem>
            <MenuItem key={2} onClick={(e) => {
                onDeleteClick(e);
            }}>
                Delete
            </MenuItem>

        </Menu>
    </div>;
};

HazardEditMenuButton.propTypes = {
    setShowDeleteDialog: PropTypes.func.isRequired,
    setCurrentSelectedHazard: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
};
export default HazardEditMenuButton;
