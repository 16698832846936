import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import DialogContent from '@mui/material/DialogContent';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import UserMainSettings from './UserSettingTabs/UserMainSettings';
import UserEmailNotificationSettings from './UserSettingTabs/UserEmailNotificationSettings';
import UserPermissionSettings from './UserSettingTabs/UserPermissionSettings';
import { UserAdminDto } from '../../../dtos/Users/UserAdminDto';
import UserChangePassword from './UserSettingTabs/UserChangePassword';
import UserApiService from '../../../api/UserApiService';
import authenticationManager from '../../../auth/AuthenticationManager';
import { UserAdminMainSettingsDto } from '../../../dtos/Users/UserAdminMainSettingsDto';
import { UserAdminPermissionsDto } from '../../../dtos/Users/UserAdminPermissionsDto';
import { UserAdminEmailNotificationsDto } from '../../../dtos/Users/UserAdminEmailNotificationsDto';
import TabPanel from './../TabPanel';
import TaskQueryDto from '../../../dtos/TaskQueries/TaskQueryDto';

export default function ModifyUserModal (props) {
    const { onClose, open, selectedUser, orgs, setSnackbarProperties, userApiService, setCurrentSelectedUser, onCancel, taskQueries, ...other } = props;

    const [tabValue, setTabValue] = useState(0);
    const [userPassword, setUserPassword] = useState(selectedUser?.password ?? '');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [newUserAdminMainSettings, setNewUserAdminMainSettings] = useState();
    const [newUserAdminPermissions, setNewUserAdminPermissions] = useState();
    const [newUserAdminEmailNotifications, setNewUserAdminEmailNotifications] = useState();
    const [applyNotificationChangesRetroactively, setApplyNotificationChangesRetroactively] = useState(false);
    const [isOrgAdmin, setIsOrgAdmin] = useState(false);

    // Validation Errors
    const [orgError, setOrgError] = useState(false);
    const [usernameError, setUsernameError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);

    function a11yProps (index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function handleCancel () {
        onCancel();
        setTabValue(0);
    }

    async function handleSave () {
        if (selectedUser) {
            const updatedUser = JSON.parse(JSON.stringify(selectedUser));
            updatedUser.mainSettingsDto = newUserAdminMainSettings;
            updatedUser.mainSettingsDto.usActive = newUserAdminMainSettings?.usActive ? 1 : 0;
            updatedUser.permissionsDto = newUserAdminPermissions;
            updatedUser.emailNotificationsDto = newUserAdminEmailNotifications;
            const userUpdateResponse = await userApiService.saveUser(updatedUser);
            if (userUpdateResponse && userUpdateResponse.success) {
                setSnackbarProperties('User Updated');
                setCurrentSelectedUser(updatedUser);
                onClose(userUpdateResponse.data);
            } else {
                setSnackbarProperties('Unable to update user: ' + userUpdateResponse.message, 'error');
            }
        } else {
            if (!validateNewUser()) {
                return;
            }
            if (userPassword === '') {
                setSnackbarProperties('You must enter a password', 'error');
            }
            if (userPassword === confirmPassword) {
                const newUser = createNewUserFromInfo();
                newUser.mainSettingsDto.password = userPassword;
                const addUserResponse = await userApiService.addUser(newUser);

                if (addUserResponse && addUserResponse.success) {
                    setSnackbarProperties('Added user');
                    onClose(addUserResponse.data);
                } else {
                    setSnackbarProperties('Unable to add user: ' + addUserResponse.message, 'error');
                }
            }
        }
    }

    function validateNewUser () {
        let errorMessage = '';

        if (!newUserAdminMainSettings.usOrg) {
            errorMessage += 'Organization is required';
        }
        setOrgError(!newUserAdminMainSettings.usOrg);

        if (!newUserAdminMainSettings.usFirstname) {
            errorMessage += (errorMessage.length > 0 ? ', ' : '') + 'First Name is Required';
        }
        setFirstNameError(!newUserAdminMainSettings.usFirstname);

        if (!newUserAdminMainSettings.usLastname) {
            errorMessage += (errorMessage.length > 0 ? ', ' : '') + 'Last name is required';
        }
        setLastNameError(!newUserAdminMainSettings.usLastname);

        if (!newUserAdminMainSettings.usUsername) {
            errorMessage += (errorMessage.length > 0 ? ', ' : '') + 'Username is required';
        }
        setUsernameError(!newUserAdminMainSettings.usUsername);

        if (!userPassword) {
            errorMessage += (errorMessage.length > 0 ? ', ' : '') + 'Password is required';
        } else if (userPassword !== confirmPassword) {
            errorMessage += (errorMessage.length > 0 ? ', ' : '') + 'Passwords Must Match';
        }
        setPasswordError(!userPassword || !confirmPassword || userPassword === confirmPassword);

        if (errorMessage.length > 0) {
            setSnackbarProperties('New User Validation Failed: ' + errorMessage, 'error');
            return false;
        } else {
            return true;
        }
    }

    function createNewUserFromInfo () {
        const newUser = new UserAdminDto();
        newUser.mainSettingsDto = newUserAdminMainSettings;
        newUser.permissionsDto = newUserAdminPermissions;
        newUser.emailNotificationsDto = newUserAdminEmailNotifications;
        return newUser;
    }

    async function checkProjectUserXRefs (orgId) {
        if (!selectedUser) {
            const newProjectUserXRefs = await userApiService.getProjectUserXRefsForNewUser(orgId);
            if (newProjectUserXRefs && newProjectUserXRefs.success) {
                const updatedSettings = { ...newUserAdminPermissions };
                updatedSettings.projectUserXRefs = newProjectUserXRefs.data;
                setNewUserAdminPermissions(updatedSettings);
            }
        }
    }

    function handleChange (event, newValue) {
        setTabValue(newValue);
    }

    useEffect(() => {
        if (open) {
            setTabValue(0);
            setNewUserAdminMainSettings(selectedUser?.mainSettingsDto ?? new UserAdminMainSettingsDto());
            setNewUserAdminPermissions(selectedUser?.permissionsDto ?? new UserAdminPermissionsDto());
            setNewUserAdminEmailNotifications(selectedUser?.emailNotificationsDto ?? new UserAdminEmailNotificationsDto());
        }
    }, [open]);

    return (
        <Dialog
            closeAfterTransition={true}
            sx={{ '& .MuiDialog-paper': { width: '175%' } }}
            maxWidth="lg"
            fullWidth
            open={open}
            {...other}
        >
            <DialogTitle>{selectedUser?.usUsername ? 'Edit ' + selectedUser.usUsername : 'Add New User'}</DialogTitle>
            <DialogContent dividers>
                <Box>
                    <Box>
                        <Tabs
                            value={tabValue}
                            onChange={handleChange}
                        >
                            <Tab label="Main Settings" {...a11yProps(0)} />
                            <Tab label="Email Notification Settings" {...a11yProps(1)} />
                            <Tab label="Permissions" {...a11yProps(2)} />
                            {Boolean(selectedUser) && <Tab label="Change Password" {...a11yProps(3)} />}
                        </Tabs>
                    </Box>
                    <TabPanel
                        value={tabValue}
                        index={0}
                    >
                        <UserMainSettings
                            userAdminMainSettings={newUserAdminMainSettings}
                            setUserAdminMainSettings={setNewUserAdminMainSettings}
                            password={userPassword}
                            setUserPassword={setUserPassword}
                            confirmPassword={confirmPassword}
                            setConfirmPassword={setConfirmPassword}
                            orgs={orgs}
                            userId={selectedUser?.usId ?? 0}
                            isCurrentUserSuperAdmin={authenticationManager.isSuperAdmin()}
                            checkProjectUserXRefs={checkProjectUserXRefs}
                            orgError={orgError}
                            usernameError={usernameError}
                            passwordError={passwordError}
                            firstNameError={firstNameError}
                            lastNameError={lastNameError}
                            isOrgAdmin={isOrgAdmin}
                            setIsOrgAdmin={setIsOrgAdmin}
                            editAdmin={true}
                            setSnackbarProperties={setSnackbarProperties}
                        />
                    </TabPanel>
                    <TabPanel
                        value={tabValue}
                        index={1}
                    >
                        <UserEmailNotificationSettings
                            userAdminEmailNotification={newUserAdminEmailNotifications}
                            setNewUserAdminEmailNotification={setNewUserAdminEmailNotifications}
                            applyNotificationChangesRetroactively={applyNotificationChangesRetroactively}
                            setApplyNotificationChangesRetroactively={setApplyNotificationChangesRetroactively}
                        />
                    </TabPanel>
                    <TabPanel
                        value={tabValue}
                        index={2}
                    >
                        <UserPermissionSettings
                            userAdminPermissions={newUserAdminPermissions}
                            setUserAdminPermissions={setNewUserAdminPermissions}
                        />
                    </TabPanel>
                    {Boolean(selectedUser) &&
                        <TabPanel
                            value={tabValue}
                            index={3}
                        >
                            <UserChangePassword
                                setSnackbarProperties={setSnackbarProperties}
                                user={selectedUser}
                            />
                        </TabPanel>
                    }

                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    variant='contained'
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                <Button
                    variant='contained'
                    onClick={handleSave}
                    disabled={tabValue === 3}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ModifyUserModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    orgs: PropTypes.array,
    selectedUser: PropTypes.objectOf(UserAdminDto),
    setSnackbarProperties: PropTypes.func.isRequired,
    userApiService: PropTypes.objectOf(UserApiService).isRequired,
    setCurrentSelectedUser: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    taskQueries: PropTypes.arrayOf(TaskQueryDto),
};
