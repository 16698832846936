import { Box, ButtonGroup } from '@mui/material';
import { BootstrapButton } from './TaskButtonBarButton';
import PropTypes from 'prop-types';

const TaskCommentButtonBar = (props) => {
    const {style, handleEditComment, handleDeleteComment} = props;
    return (
        <Box
            sx={{
                display:'flex',
                flexDirection: 'column',
                alignItems: 'center',
                '& > *': {
                    m: 1,
                },
            }}
        >
            <ButtonGroup
                style={style}
                size={'small'}
            >
                <BootstrapButton variant={'contained'} size={'small'} onClick={handleEditComment}>Edit</BootstrapButton>
                <BootstrapButton variant={'contained'} size={'small'} onClick={handleDeleteComment}>Delete</BootstrapButton>
            </ButtonGroup>
        </Box>
    )
}

TaskCommentButtonBar.propTypes = {
    style: PropTypes.object,
    handleEditComment: PropTypes.func.isRequired,
    handleDeleteComment: PropTypes.func.isRequired,
};

export default TaskCommentButtonBar;
