export default class PriorityDto {
    /**
     * @type{number}
     * */
    prId;

    /**
     * @type{string}
     */
    prName;

    /**
     * @type{number}
     */
    prSortSeq;

    /**
     * @type{string}
     */
    prBackgroundColor;

    constructor () {
        this.prId = 0;
        this.prName = '';
        this.prSortSeq = 0;
        this.prBackgroundColor = '';
    }
}
