import axios from 'axios';
import authenticationManager from '../auth/AuthenticationManager';

export class ApiService {
    /**
     * Sends an HTTP POST request
     * @param url URL to call
     * @param payload {Object}
     * @return {Promise<axios.AxiosResponse<ServiceResponse>>}
     */
    async postData (url, payload) {
        const _headers = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };
        await authenticationManager.checkTokenExpiration();
        const token = await authenticationManager.getAccessToken();
        if (token) {
            _headers.headers.Authorization = 'Bearer ' + token;
        }
        return await axios.post(url, JSON.stringify(payload), _headers)
            .catch(err => {
                return err.response;
            });
    }

    async postFormData (url, payload) {
        const _headers = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        };
        await authenticationManager.checkTokenExpiration();
        const token = await authenticationManager.getAccessToken();
        if (token) {
            _headers.headers.Authorization = 'Bearer ' + token;
        }
        return await axios.post(url, payload, _headers)
            .catch(err => {
                return err.response;
            });
    }

    /**
     * Sends an HTTP PUT request
     * @param url URL to call
     * @param payload {Object}
     * @return {Promise<axios.AxiosResponse<ServiceResponse>>}
     */
    async putData (url, payload) {
        const _headers = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };
        await authenticationManager.checkTokenExpiration();
        const token = await authenticationManager.getAccessToken();
        if (token) {
            _headers.headers.Authorization = 'Bearer ' + token;
        }
        return await axios.put(url, JSON.stringify(payload), _headers)
            .catch(err => {
                return err.response;
            });
    }

    /**
     * Sends an HTTP GET request
     * @param url URL to call
     * @param headers {Object}
     * @return {Promise<axios.AxiosResponse<ServiceResponse>>}
     */
    async getData (url, headers) {
        let _headers = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        if (headers) {
            _headers = { ..._headers, ...headers };
        }

        // Need to assign this to a variable so that javascript will wait for the async function to execute
        // eslint-disable-next-line no-unused-vars
        const tokenCheck = await authenticationManager.checkTokenExpiration();
        const token = authenticationManager.getAccessToken();
        if (token) {
            _headers.headers.Authorization = 'Bearer ' + token;
        }
        const result = await axios.get(url, _headers).catch(err => {
            return err.response;
        });

        return result;

    }

    /**
     * Sends an HTTP DELETE request
     * @param url {string}
     * @param payload {object}
     * @return {Promise<axios.AxiosResponse<ServiceResponse>>}
     */
    async deleteData (url) {
        const _headers = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        // Need to assign this to a variable so that javascript will wait for the async function to execute
        // eslint-disable-next-line no-unused-vars
        const tokenCheck = await authenticationManager.checkTokenExpiration();
        const token = await authenticationManager.getAccessToken();
        if (token) {
            _headers.headers.Authorization = 'Bearer ' + token;
        }

        const config = { ..._headers };

        const result = await axios.delete(url, config);
        if (result.status === 200) {
            return result;
        }
    }

    /**
     * Gets a refresh token
     * @param url {string}
     * @param payload {Object}
     * @return {Promise<{}>}
     */
    async getRefreshToken (url, payload) {
        const _headers = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };
        let result = {};
        try {
            result = await axios.post(url, JSON.stringify(payload), _headers);
        } catch (e) {
            result.status = 400;
        }

        return result;
    }
}
const apiService = new ApiService();
export default apiService;
