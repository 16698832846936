import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button, FormControl, Grid, Input } from '@mui/material';
import { Label } from 'reactstrap';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { compareObjects } from '../../Utilities';
import OrgApiService from '../../../api/OrgApiService';

const PermissionLevels = {
    None: 0,
    ViewOnly: 1,
    Edit: 2,
};

const organizationApiService = new OrgApiService();

export default function EditOrganizationModal (props) {
    const {
        open,
        currentOrganization,
        onCancel,
        onSave,
        setSnackbarProperties,
    } = props;

    const [organizationName, setOrganizationName] = useState('');
    const [domain, setDomain] = useState('');
    const [active, setActive] = useState(0);
    const [otherOrgsPermissionLevel, setOtherOrgsPermissionLevel] = useState(0);
    const [canSearch, setCanSearch] = useState(0);
    const [externalUsers, setExternalUsers] = useState(0);
    const [ownReported, setOwnReported] = useState(0);
    const [assignedTo, setAssignedTo] = useState(0);
    const [riskManagementModule, setRiskManagementModule] = useState(false);
    const [locationProjectPermission, setLocationProjectPermission] = useState(PermissionLevels.None);
    const [organizationPermission, setOrganizationPermission] = useState(PermissionLevels.None);
    const [priorityPermission, setPriorityPermission] = useState(PermissionLevels.None);
    const [statusPermission, setStatusPermission] = useState(PermissionLevels.None);
    const [assignedToPermission, setAssignedToPermission] = useState(PermissionLevels.None);
    const [tagsPermission, setTagsPermission] = useState(PermissionLevels.None);
    const [duePermission, setDuePermission] = useState(PermissionLevels.None);
    const [isoClausePermission, setIsoClausePermission] = useState(PermissionLevels.None);
    const [auditorPermission, setAuditorPermission] = useState(PermissionLevels.None);
    const [deptPermission, setDeptPermission] = useState(PermissionLevels.None);
    const [processPermission, setProcessPermission] = useState(PermissionLevels.None);
    const [jobPermission, setJobPermission] = useState(PermissionLevels.None);
    const [taskPermission, setTaskPermission] = useState(PermissionLevels.None);
    const [incidentDescriptionPermission, setIncidentDescriptionPermission] = useState(PermissionLevels.None);
    const [requirementPermission, setRequirementPermission] = useState(PermissionLevels.None);
    const [findingPermission, setFindingPermission] = useState(PermissionLevels.None);
    const [equipmentPermission, setEquipmentPermission] = useState(PermissionLevels.None);
    const [riskSourceDescriptionPermission, setRiskSourceDescriptionPermission] = useState(PermissionLevels.None);
    const [proposedAdditionalControlPermission, setProposedAdditionalControlPermission] = useState(PermissionLevels.None);
    const [evidencePermission, setEvidencePermission] = useState(PermissionLevels.None);
    const [correctionPermission, setCorrectionPermission] = useState(PermissionLevels.None);
    const [causePermission, setCausePermission] = useState(PermissionLevels.None);
    const [correctiveActionPermission, setCorrectiveActionPermission] = useState(PermissionLevels.None);
    const [verifierPermission, setVerifierPermission] = useState(PermissionLevels.None);
    const [verificationEvidencePermission, setVerificationEvidencePermission] = useState(PermissionLevels.None);
    const [verificationDatePermission, setVerificationDatePermission] = useState(PermissionLevels.None);
    const [deleteTasks, setDeleteTasks] = useState(0);
    const [editDeletePosts, setEditDeletePosts] = useState(0);
    const [mergeTasks, setMergeTasks] = useState(0);
    const [massEditTasks, setMassEditTasks] = useState(0);
    const [useReports, setUseReports] = useState(0);
    const [createEditReports, setCreateEditReports] = useState(0);
    const [viewTasksTime, setViewTasksTime] = useState(0);
    const [editTasksTime, setEditTasksTime] = useState(0);
    const [assignInternal, setAssignInternal] = useState(0);

    const columns = [
        {
            field: 'fieldPermission',
            headerName: 'Field Permission',
            flex: 1,
        },
        {
            field: 'ogFieldPermission',
            headerName: 'Permissions',
            type: 'singleSelect',
            editable: true,
            flex: 2,
            valueOptions: [
                {
                    value: PermissionLevels.None,
                    label: 'None',
                },
                {
                    value: PermissionLevels.ViewOnly,
                    label: 'View Only',
                },
                {
                    value: PermissionLevels.Edit,
                    label: 'Edit',
                },
            ],
            renderCell: (params) => {
                switch (params.row.ogFieldPermission) {
                    case PermissionLevels.None:
                        return 'None';
                    case PermissionLevels.ViewOnly:
                        return 'View Only';
                    case PermissionLevels.Edit:
                        return 'Edit';
                    default:
                        return '';
                }
            },
        },
    ];

    const rows = [
        {
            rowId: 1,
            fieldPermission: 'Location/Project',
            ogFieldPermission: locationProjectPermission,
            setPermissionValue: function (value) {
                setLocationProjectPermission(value);
            }
        },
        {
            rowId: 2,
            fieldPermission: 'Organization',
            ogFieldPermission: organizationPermission,
            setPermissionValue: function (value) {
                setOrganizationPermission(value);
            }
        },
        {
            rowId: 3,
            fieldPermission: 'Priority',
            ogFieldPermission: priorityPermission,
            setPermissionValue: function (value) {
                setPriorityPermission(value);
            },
        },
        {
            rowId: 4,
            fieldPermission: 'Status',
            ogFieldPermission: statusPermission,
            setPermissionValue: function (value) {
                setStatusPermission(value);
            },
        },
        {
            rowId: 5,
            fieldPermission: 'Assigned To',
            ogFieldPermission: assignedToPermission,
            setPermissionValue: function (value) {
                setAssignedToPermission(value);
            },
        },
        {
            rowId: 7,
            fieldPermission: 'Tags',
            ogFieldPermission: tagsPermission,
            setPermissionValue: function (value) {
                setTagsPermission(value);
            },
        },
        {
            rowId: 8,
            fieldPermission: 'Due',
            ogFieldPermission: duePermission,
            setPermissionValue: function (value) {
                setDuePermission(value);
            },
        },
        {
            rowId: 9,
            fieldPermission: 'ISO Clause',
            ogFieldPermission: isoClausePermission,
            setPermissionValue: function (value) {
                setIsoClausePermission(value);
            },
        },
        {
            rowId: 10,
            fieldPermission: 'Auditor',
            ogFieldPermission: auditorPermission,
            setPermissionValue: function (value) {
                setAuditorPermission(value);
            },
        },
        {
            rowId: 11,
            fieldPermission: 'Dept',
            ogFieldPermission: deptPermission,
            setPermissionValue: function (value) {
                setDeptPermission(value);
            },
        },
        {
            rowId: 12,
            fieldPermission: 'Process',
            ogFieldPermission: processPermission,
            setPermissionValue: function (value) {
                setProcessPermission(value);
            },
        },
        {
            rowId: 13,
            fieldPermission: 'Job',
            ogFieldPermission: jobPermission,
            setPermissionValue: function (value) {
                setJobPermission(value);
            },
        },
        {
            rowId: 14,
            fieldPermission: 'Task',
            ogFieldPermission: taskPermission,
            setPermissionValue: function (value) {
                setTaskPermission(value);
            },
        },
        {
            rowId: 15,
            fieldPermission: 'Incident Description',
            ogFieldPermission: incidentDescriptionPermission,
            setPermissionValue: function (value) {
                setIncidentDescriptionPermission(value);
            },
        },
        {
            rowId: 16,
            fieldPermission: 'Requirement',
            ogFieldPermission: requirementPermission,
            setPermissionValue: function (value) {
                setRequirementPermission(value);
            },
        },
        {
            rowId: 17,
            fieldPermission: 'Finding',
            ogFieldPermission: findingPermission,
            setPermissionValue: function (value) {
                setFindingPermission(value);
            },
        },
        {
            rowId: 18,
            fieldPermission: 'Equipment',
            ogFieldPermission: equipmentPermission,
            setPermissionValue: function (value) {
                setEquipmentPermission(value);
            },
        },
        {
            rowId: 19,
            fieldPermission: 'Risk Source Description',
            ogFieldPermission: riskSourceDescriptionPermission,
            setPermissionValue: function (value) {
                setRiskSourceDescriptionPermission(value);
            },
        },
        {
            rowId: 20,
            fieldPermission: 'Proposed Additional Control',
            ogFieldPermission: proposedAdditionalControlPermission,
            setPermissionValue: function (value) {
                setProposedAdditionalControlPermission(value);
            },
        },
        {
            rowId: 21,
            fieldPermission: 'Evidence',
            ogFieldPermission: evidencePermission,
            setPermissionValue: function (value) {
                setEvidencePermission(value);
            },
        },
        {
            rowId: 22,
            fieldPermission: 'Correction',
            ogFieldPermission: correctionPermission,
            setPermissionValue: function (value) {
                setCorrectionPermission(value);
            },
        },
        {
            rowId: 23,
            fieldPermission: 'Cause',
            ogFieldPermission: causePermission,
            setPermissionValue: function (value) {
                setCausePermission(value);
            },
        },
        {
            rowId: 24,
            fieldPermission: 'Corrective Action',
            ogFieldPermission: correctiveActionPermission,
            setPermissionValue: function (value) {
                setCorrectiveActionPermission(value);
            },
        },
        {
            rowId: 25,
            fieldPermission: 'Verifier',
            ogFieldPermission: verifierPermission,
            setPermissionValue: function (value) {
                setVerifierPermission(value);
            },
        },
        {
            rowId: 26,
            fieldPermission: 'Verification Evidence',
            ogFieldPermission: verificationEvidencePermission,
            setPermissionValue: function (value) {
                setVerificationEvidencePermission(value);
            },
        },
        {
            rowId: 27,
            fieldPermission: 'Verification Date',
            ogFieldPermission: verificationDatePermission,
            setPermissionValue: function (value) {
                setVerificationDatePermission(value);
            },
        },
    ];

    useEffect(() => {
        if (open) {
            setOrganizationName(currentOrganization?.ogName || '');
            setDomain(currentOrganization?.ogDomain || '');
            setActive(currentOrganization?.ogActive || 0);
            setOtherOrgsPermissionLevel(currentOrganization?.ogOtherOrgsPermissionLevel || 0);
            setCanSearch(currentOrganization?.ogCanSearch || 0);
            setExternalUsers(currentOrganization?.ogExternalUser || 0);
            setOwnReported(currentOrganization?.ogCanOnlySeeOwnReported || 0);
            setAssignedTo(currentOrganization?.ogCanBeAssignedTo || 0);
            setRiskManagementModule(currentOrganization?.ogEnableRiskManagement || false);
            setLocationProjectPermission(currentOrganization?.ogProjectFieldPermissionLevel || PermissionLevels.None);
            setOrganizationPermission(currentOrganization?.ogOrgFieldPermissionLevel || PermissionLevels.None);
            setPriorityPermission(currentOrganization?.ogPriorityFieldPermissionLevel || PermissionLevels.None);
            setStatusPermission(currentOrganization?.ogStatusFieldPermissionLevel || PermissionLevels.None);
            setAssignedToPermission(currentOrganization?.ogAssignedToFieldPermissionLevel || PermissionLevels.None);
            setTagsPermission(currentOrganization?.ogTagsFieldPermissionLevel || PermissionLevels.None);
            setDuePermission(currentOrganization?.ogDueDateFieldPermissionLevel || PermissionLevels.None);
            setIsoClausePermission(currentOrganization?.ogIsoClauseFieldPermissionLevel || PermissionLevels.None);
            setAuditorPermission(currentOrganization?.ogAuditorFieldPermissionLevel || PermissionLevels.None);
            setDeptPermission(currentOrganization?.ogDeptFieldPermissionLevel || PermissionLevels.None);
            setProcessPermission(currentOrganization?.ogProcessFieldPermissionLevel || PermissionLevels.None);
            setJobPermission(currentOrganization?.ogJobFieldPermissionLevel || PermissionLevels.None);
            setTaskPermission(currentOrganization?.ogTaskFieldPermissionLevel || PermissionLevels.None);
            setIncidentDescriptionPermission(currentOrganization?.ogIncidentDescriptionFieldPermissionLevel || PermissionLevels.None);
            setRequirementPermission(currentOrganization?.ogRequirementFieldPermissionLevel || PermissionLevels.None);
            setFindingPermission(currentOrganization?.ogFindingFieldPermissionLevel || PermissionLevels.None);
            setEquipmentPermission(currentOrganization?.ogEquipmentFieldPermissionLevel || PermissionLevels.None);
            setRiskSourceDescriptionPermission(currentOrganization?.ogRiskSourceDescriptionFieldPermissionLevel || PermissionLevels.None);
            setProposedAdditionalControlPermission(currentOrganization?.ogProposedAdditionalControlFieldPermissionLevel || PermissionLevels.None);
            setEvidencePermission(currentOrganization?.ogEvidenceFieldPermissionLevel || PermissionLevels.None);
            setCorrectionPermission(currentOrganization?.ogCorrectionFieldPermissionLevel || PermissionLevels.None);
            setCausePermission(currentOrganization?.ogCauseFieldPermissionLevel || PermissionLevels.None);
            setCorrectiveActionPermission(currentOrganization?.ogCorrectiveActionFieldPermissionLevel || PermissionLevels.None);
            setVerifierPermission(currentOrganization?.ogVerifierFieldPermissionLevel || PermissionLevels.None);
            setVerificationEvidencePermission(currentOrganization?.ogVerificationEvidenceFieldPermissionLevel || PermissionLevels.None);
            setVerificationDatePermission(currentOrganization?.ogVerificationDateFieldPermissionLevel || PermissionLevels.None);
            setDeleteTasks(currentOrganization?.ogCanDeleteBug || 0);
            setEditDeletePosts(currentOrganization?.ogCanEditAndDeletePosts || 0);
            setMergeTasks(currentOrganization?.ogCanMergeBugs || 0);
            setMassEditTasks(currentOrganization?.ogCanMassEditBugs || 0);
            setUseReports(currentOrganization?.ogCanUseReports || 0);
            setCreateEditReports(currentOrganization?.ogCanEditReports || 0);
            setViewTasksTime(currentOrganization?.ogCanViewTasks || 0);
            setEditTasksTime(currentOrganization?.ogCanEditTasks || 0);
            setAssignInternal(currentOrganization?.ogCanAssignToInternalUsers || 0);
        }
    }, [open, currentOrganization]);

    function handleCancel () {
        onCancel();
    }

    async function handleSave () {
        let newOrganization = getUpdatedOrganization();

        if (!organizationName) {
            setSnackbarProperties('Organization Name is Required', 'error');
            return;
        }

        if (!domain) {
            setSnackbarProperties('Organization Domain is Required', 'error');
            return;
        }

        if (!currentOrganization) {
            await organizationApiService.addOrganization(newOrganization);
            setSnackbarProperties('Successfully created new ' + organizationName);
        }
        else if (!compareObjects(newOrganization, currentOrganization)) {
            newOrganization.ogId = currentOrganization.ogId;

            await organizationApiService.updateOrganization(newOrganization);
            setSnackbarProperties('Successfully updated ' + currentOrganization.ogName);
        }

        await onSave();
    }

    function getUpdatedOrganization () {
        const formInputs = {
            ogName: organizationName,
            ogDomain: domain,
            ogActive: active,
            ogOtherOrgsPermissionLevel: otherOrgsPermissionLevel,
            ogCanSearch: canSearch,
            ogExternalUser: externalUsers,
            ogCanOnlySeeOwnReported: ownReported,
            ogCanBeAssignedTo: assignedTo,
            ogEnableRiskManagement: riskManagementModule,
            ogProjectFieldPermissionLevel: locationProjectPermission,
            ogOrgFieldPermissionLevel: organizationPermission,
            ogPriorityFieldPermissionLevel: priorityPermission,
            ogStatusFieldPermissionLevel: statusPermission,
            ogAssignedToFieldPermissionLevel: assignedToPermission,
            ogTagsFieldPermissionLevel: tagsPermission,
            ogDueDateFieldPermissionLevel: duePermission,
            ogIsoClauseFieldPermissionLevel: isoClausePermission,
            ogAuditorFieldPermissionLevel: auditorPermission,
            ogDeptFieldPermissionLevel: deptPermission,
            ogProcessFieldPermissionLevel: processPermission,
            ogJobFieldPermissionLevel: jobPermission,
            ogTaskFieldPermissionLevel: taskPermission,
            ogIncidentDescriptionFieldPermissionLevel: incidentDescriptionPermission,
            ogRequirementFieldPermissionLevel: requirementPermission,
            ogFindingFieldPermissionLevel: findingPermission,
            ogEquipmentFieldPermissionLevel: equipmentPermission,
            ogRiskSourceDescriptionFieldPermissionLevel: riskSourceDescriptionPermission,
            ogProposedAdditionalControlFieldPermissionLevel: proposedAdditionalControlPermission,
            ogEvidenceFieldPermissionLevel: evidencePermission,
            ogCorrectionFieldPermissionLevel: correctionPermission,
            ogCauseFieldPermissionLevel: causePermission,
            ogCorrectiveActionFieldPermissionLevel: correctiveActionPermission,
            ogVerifierFieldPermissionLevel: verifierPermission,
            ogVerificationEvidenceFieldPermissionLevel: verificationEvidencePermission,
            ogVerificationDateFieldPermissionLevel: verificationDatePermission,
            ogCanDeleteBug: deleteTasks,
            ogCanEditAndDeletePosts: editDeletePosts,
            ogCanMergeBugs: mergeTasks,
            ogCanMassEditBugs: massEditTasks,
            ogCanUseReports: useReports,
            ogCanEditReports: createEditReports,
            ogCanViewTasks: viewTasksTime,
            ogCanEditTasks: editTasksTime,
            ogCanAssignToInternalUsers: assignInternal,
        };

        return { ...{}, ...formInputs };
    }

    return (
        <Dialog
            sx={{ '& .MuiDialog-paper': { width: '175%' } }}
            maxWidth="lg"
            fullWidth
            open={open}>
            {currentOrganization
                ? (
                    <DialogTitle>Edit {currentOrganization.ogName} Organization</DialogTitle>)
                : (<DialogTitle>Create a new organization</DialogTitle>)}
            <DialogContent dividers>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Label htmlFor="orgName">Organization Name</Label>
                                <Input
                                    type="text"
                                    value={organizationName}
                                    onChange={(event) => {
                                        setOrganizationName(event.target.value);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Label htmlFor="domain">Domain (like, &quot;example.com&quot;)</Label>
                                <Input
                                    type="text"
                                    value={domain}
                                    onChange={(event) => {
                                        setDomain(event.target.value);
                                    }}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox checked={active === 1}
                                                   onChange={() => setActive(active === 0 ? 1 : 0)}
                                />}
                                label="Active"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Label>Can members of this organization view/edit bugs associated with other
                                organizations?</Label>
                            <RadioGroup
                                row
                                onChange={(event) => {
                                    setOtherOrgsPermissionLevel(event.target.value);
                                }}
                                value={otherOrgsPermissionLevel}
                            >
                                <FormControlLabel value={0} control={<Radio/>} label="None"/>
                                <FormControlLabel value={1} control={<Radio/>} label="View Only"/>
                                <FormControlLabel value={2} control={<Radio/>} label="Edit"/>
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                <FormControlLabel
                                    control={<Checkbox checked={canSearch === 1}
                                                       onChange={() => setCanSearch(canSearch === 0 ? 1 : 0)}
                                    />}
                                    label="Can Search"
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={<Checkbox checked={externalUsers === 1}
                                                       onChange={() => setExternalUsers(externalUsers === 0 ? 1 : 0)}
                                    />}
                                    label="External Users Can View"
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={<Checkbox checked={ownReported === 1}
                                                       onChange={() => setOwnReported(ownReported === 0 ? 1 : 0)}
                                    />}
                                    label="Can See Only Own Reported"
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={<Checkbox checked={assignedTo === 1}
                                                       onChange={() => setAssignedTo(assignedTo === 0 ? 1 : 0)}
                                    />}
                                    label="Members of this org appear in &quot;assigned to&quot; dropdown in edit task page"
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={<Checkbox checked={riskManagementModule}
                                                       onChange={() => setRiskManagementModule(!riskManagementModule)}
                                    />}
                                    label="Can access the Risk Management module"
                                />
                            </div>
                            <Typography variant="body2" color="textSecondary">
                                Enabling or disabling the Risk Management module for the organization enables or
                                disables it for all users within the organization. You can still disable the module for
                                individual users if it is enabled for the organization.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{ fontSize: '24px', fontWeight: 'bold' }}>
                                Field Level Permissions
                            </Typography>
                            <Box sx={{
                                height: '50vh',
                                width: '100%',
                                display: 'block',
                            }}>
                                <DataGridPro
                                    columns={columns}
                                    rows={rows}
                                    getRowId={(row) => row.rowId}
                                    onCellEditCommit={(params) => {
                                        rows.find((row) => row.rowId === params.id)?.setPermissionValue(params.value);
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{ fontSize: '24px', fontWeight: 'bold' }}>
                                Non-Admin Permissions
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={deleteTasks === 1}
                                            onChange={() => setDeleteTasks(deleteTasks === 0 ? 1 : 0)}
                                        />
                                    }
                                    label="Can delete tasks"
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={editDeletePosts === 1}
                                            onChange={() => setEditDeletePosts(editDeletePosts === 0 ? 1 : 0)}
                                        />
                                    }
                                    label="Can edit and delete comments and attachments"
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={mergeTasks === 1}
                                            onChange={() => setMergeTasks(mergeTasks === 0 ? 1 : 0)}
                                        />
                                    }
                                    label="Can merge tasks"
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={massEditTasks === 1}
                                            onChange={() => setMassEditTasks(massEditTasks === 0 ? 1 : 0)}
                                        />
                                    }
                                    label="Can mass edit tasks"
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={useReports === 1}
                                            onChange={() => setUseReports(useReports === 0 ? 1 : 0)}
                                        />
                                    }
                                    label="Can use reports"
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={createEditReports === 1}
                                            onChange={() => setCreateEditReports(createEditReports === 0 ? 1 : 0)}
                                        />
                                    }
                                    label="Can create/edit reports"
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={viewTasksTime === 1}
                                            onChange={() => setViewTasksTime(viewTasksTime === 0 ? 1 : 0)}
                                        />
                                    }
                                    label="Can view tasks/time"
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={editTasksTime === 1}
                                            onChange={() => setEditTasksTime(editTasksTime === 0 ? 1 : 0)}
                                        />
                                    }
                                    label="Can edit tasks/time"
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={assignInternal === 1}
                                            onChange={() => setAssignInternal(assignInternal === 0 ? 1 : 0)}
                                        />
                                    }
                                    label="Can assign to internal users (even if external org)"
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button autoFocus variant="contained" onClick={handleCancel}>
                    Cancel
                </Button>
                <Button variant="contained" onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
}
