import React, { useState } from 'react';
import { Navbar, NavbarBrand, NavItem } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import './NavMenu.css';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import authenticationManager from '../auth/AuthenticationManager';
import { AccountCircle } from '@mui/icons-material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import UserSelfServiceModal from './Modal/UserSelfServiceModal';

const NavMenu = (props) => {

    const { setSnackbarProperties, toggleNavMenu } = props;
    const [goToTaskId, setGoToTaskId] = useState(null);
    const [goToRiskId, setGoToRiskId] = useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openUserModal, setOpenUserModal] = useState(false);

    const navigate = useNavigate();

    function goToTask () {
        if (goToTaskId === null || goToTaskId === '' || goToTaskId === 0) {
            // TODO: Notification should say it's an invalid value
        } else {
            window.location.href = '/Task/' + goToTaskId;
            setGoToTaskId('');
        }
    }

    function goToRisk () {
        if (goToRiskId === null || goToRiskId === '' || goToRiskId === 0) {
            // TODO: Notification should say it's an invalid value
        } else {
            window.location.href = '/Hazard/' + goToRiskId;
            setGoToRiskId('');
        }
    }

    const handleRiskEnterKey = e => {
        if (e.keyCode === 13) {
            goToRisk();
        }
    };

    const handleTaskEnterKey = e => {
        if (e.keyCode === 13) {
            goToTask();
        }
    };

    function handleMenu(event) {
        setAnchorEl(event.currentTarget)
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <header>
            <Navbar style={{ display: 'flex', height: '50px' }} className="navbar-expand-lg navbar-toggleable-sm box-shadow mb-0 navbar-dark bg-primary" container light >
                <div className={'navbar-left'}>
                    <IconButton
                        onClick={toggleNavMenu}
                    >
                        <MenuIcon
                            sx={{ color: 'white' }}
                        />
                    </IconButton>
                    <NavbarBrand sx={{ justifyContent: 'left' }} tag={Link} to="/"><DoneOutlinedIcon fontSize="large" /> CorrectTrack</NavbarBrand>
                </div>
                <ul className="navbar-nav flex-grow d-sm-inline-flex navbar-right">
                    <NavItem className={'nav-item go-to-search-nav'}>
                        <input
                            className={'search-input'}
                            type={'text'}
                            value={goToTaskId ?? ''}
                            onKeyDown={ handleTaskEnterKey }
                            placeholder={'Task ID'}
                            onChange={(event) => {
                                const re = /^[0-9\b]+$/;

                                // if value is not blank, then test the regex
                                if (event.target.value === '' || re.test(event.target.value)) {
                                    setGoToTaskId(event.target.value);
                                }
                            }
                            }
                        />
                        <input
                            className={ 'search-button' }
                            type={ 'button' }
                            onClick={ goToTask }
                            onKeyDown={ goToTask }
                            value={ 'Go to Task' }
                        />
                    </NavItem>
                    <NavItem className={ 'nav-item go-to-search-nav' }>
                        <input
                            className={ 'search-input' }
                            type={ 'text' }
                            value={ goToRiskId ?? '' }
                            onKeyDown={ handleRiskEnterKey }
                            placeholder={ 'Risk ID' }
                            onChange={(event) => {
                                const re = /^[0-9\b]+$/;

                                // if value is not blank, then test the regex
                                if (event.target.value === '' || re.test(event.target.value)) {
                                    setGoToRiskId(event.target.value);
                                }
                            }
                            }
                        />
                        <input
                            className={ 'search-button' }
                            type={ 'button' }
                            onClick={ goToRisk }
                            onKeyDown={ goToRisk }
                            value={'Go to Risk'}
                        />
                        <IconButton
                            size={'large'}
                            sx={{marginLeft: 'auto', marginRight: '-5px'}}
                            onClick={handleMenu}
                            color={'inherit'}
                        >
                            <AccountCircle color={'inherit'} sx={{transform: 'scale(1.7)'}}/>
                        </IconButton>
                        <Menu open={Boolean(anchorEl)}
                              onClose={handleClose}
                              keepMounted
                              anchorEl={anchorEl}
                              anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                              }}
                        >
                            <MenuItem disabled onClick={() => {}}>{`Logged in as: ${authenticationManager.getUsername()}`}</MenuItem>
                            <MenuItem onClick={() => setOpenUserModal(true)}>Your Profile Settings</MenuItem>
                            <MenuItem onClick={() => navigate("/Logout")}>Logout</MenuItem>
                        </Menu>
                    </NavItem>
                </ul>
            </Navbar>
            <UserSelfServiceModal open={openUserModal} setSnackbarProperties={setSnackbarProperties} onClose={() => setOpenUserModal(false)} />
        </header>
    );
};

NavMenu.propTypes = {
    toggleNavMenu: PropTypes.func.isRequired,
    setSnackbarProperties: PropTypes.func,
};
export default NavMenu;
