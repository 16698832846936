import apiService from './apiService';

export default class AuditChecklistApiService {

    async getAuditChecklists () {
        const auditChecklistResponse = await apiService.getData('api/AuditChecklist');
        return auditChecklistResponse.data;
    }

    async addAuditChecklist (newAuditChecklist) {
        const addAuditChecklistResponse = await apiService.postData('api/AuditChecklist', newAuditChecklist);
        return addAuditChecklistResponse.data;
    }

    async updateAuditChecklist (updatedAuditChecklist) {
        const updateAuditChecklistResponse = await apiService.putData('api/AuditChecklist/' + updatedAuditChecklist.acId, updatedAuditChecklist);
        return updateAuditChecklistResponse.data;
    }

    async deleteAuditChecklist (deletedAuditChecklist) {
        const deleteAuditChecklistResponse = await apiService.deleteData('api/AuditChecklist/' + deletedAuditChecklist.acId);
        return deleteAuditChecklistResponse.data;
    }
}
