import { FormControl, FormLabel, Grid, MenuItem, Select } from '@mui/material';
import authenticationManager from '../../../../auth/AuthenticationManager';
import React from 'react';
import PropTypes from 'prop-types';

export default function DayOfWeekField(props) {
    const { value, onChange } = props;

    return (
        <Grid item>
            <FormLabel>Day of Week</FormLabel>
            <br/>
            <FormControl sx={{m: 1, minWidth: 150}}>
                <Select
                    value={value}
                    onChange={onChange}
                >
                    <MenuItem value={''}>
                        <em>Select a day</em>
                    </MenuItem>
                    <MenuItem value={0}>
                        Sunday
                    </MenuItem>
                    <MenuItem value={1}>
                        Monday
                    </MenuItem>
                    <MenuItem value={2}>
                        Tuesday
                    </MenuItem>
                    <MenuItem value={3}>
                        Wednesday
                    </MenuItem>
                    <MenuItem value={4}>
                        Thursday
                    </MenuItem>
                    <MenuItem value={5}>
                        Friday
                    </MenuItem>
                    <MenuItem value={6}>
                        Saturday
                    </MenuItem>
                </Select>
            </FormControl>

        </Grid>

    )
}

DayOfWeekField.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};
