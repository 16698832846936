import apiService from './apiService';

export default class CategoryApiService {

    /**
     * Gets all categories
     * @return {Promise<ServiceResponse<Array<CategoryDto>>>}
     */
    async GetCategories() {
        const categories = await apiService.getData('api/Category');

        return categories.data;
    }

    /**
     * Get a category by id
     * @param categoryId {number}
     * @return {Promise<ServiceResponse<CategoryDto>>}
     */
    async GetCategory(categoryId) {
        const category = await apiService.getData('api/Category/' + categoryId);

        return category.data;
    }

    /**
     * Add a new category
     * @param categoryDto {CategoryDto}
     * @return {Promise<ServiceResponse<number>>}
     */
    async createCategory(categoryDto) {
        const newId = await apiService.postData('api/Category', categoryDto);

        return newId.data;
    }

    /**
     * Update a category
     * @param categoryDto {CategoryDto}
     * @return {Promise<ServiceResponse<CategoryDto>>}
     */
    async updateCategory(categoryDto) {
        const updatedCategory = await apiService.putData('api/Category', categoryDto);

        return updatedCategory.data;
    }

    /**
     * Delete a category
     * @param categoryId {number}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async deleteCategory(categoryId) {
        const deletedCategory = await apiService.deleteData('api/Category/' + categoryId);

        return deletedCategory.data;
    }
}
