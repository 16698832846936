class LocalSettingsManager {
    getSettingValue (settingName, defaultValue) {
        const setting = localStorage.getItem(settingName);
        if (setting && setting !== 'undefined') {
            return setting;
        } else {
            localStorage.setItem(settingName, defaultValue);
            return defaultValue;
        }
    };

    setSettingValue (settingName, value) {
        localStorage.setItem(settingName, value);
    }
}
export default new LocalSettingsManager();
