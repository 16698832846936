import React, { useEffect, useState } from 'react';
import { Box, FormLabel } from '@mui/material';
import PropTypes from 'prop-types';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { UserAdminPermissionsDto } from '../../../../dtos/Users/UserAdminPermissionsDto';

const PermissionLevels = {
    None: 0,
    ViewOnly: 1,
    Report: 3,
    All: 2,
};

const PERMISSION_LEVEL_NONE = 'None';
const PERMISSION_LEVEL_VIEW_ONLY = 'View Only';
const PERMISSION_LEVEL_REPORT = 'Report (add and comment only)';
const PERMISSION_LEVEL_ALL = 'All (add and edit)';

export default function UserPermissionSettings (props) {
    const { userAdminPermissions, setUserAdminPermissions } = props;

    const [RiskPermission, setRiskPermission] = useState(2);

    const handleFieldChange = (field, value) => {
        const updatedSettings = { ...userAdminPermissions };
        updatedSettings[field] = value;
        setUserAdminPermissions(updatedSettings);
    };

    const columns = [
        {
            field: 'puProjectName',
            headerName: 'Location/Project',
            flex: 1,
        },
        {
            field: 'puPermissionLevel',
            headerName: 'Permissions',
            type: 'singleSelect',
            editable: true,
            flex: 1,
            valueOptions: [
                {
                    value: PermissionLevels.None,
                    label: PERMISSION_LEVEL_NONE,
                },
                {
                    value: PermissionLevels.ViewOnly,
                    label: PERMISSION_LEVEL_VIEW_ONLY,
                },
                {
                    value: PermissionLevels.Report,
                    label: PERMISSION_LEVEL_REPORT,
                },
                {
                    value: PermissionLevels.All,
                    label: PERMISSION_LEVEL_ALL,
                },
            ],
            renderCell: (params) => {
                switch (params.row.puPermissionLevel) {
                case PermissionLevels.None:
                    return PERMISSION_LEVEL_NONE;
                case PermissionLevels.ViewOnly:
                    return PERMISSION_LEVEL_VIEW_ONLY;
                case PermissionLevels.Report:
                    return PERMISSION_LEVEL_REPORT;
                case PermissionLevels.All:
                    return PERMISSION_LEVEL_ALL;
                default:
                    return '';
                }
            },
        },
    ];

    function setRiskManagementValues (value) {
        const eventValue = parseInt(value);

        const updatedSettings = { ...userAdminPermissions };
        if (eventValue === 0) {
            updatedSettings.usViewRiskManagement = true;
            updatedSettings.usModifyRiskManagement = false;
            setUserAdminPermissions(updatedSettings);
        } else if (eventValue === 1) {
            const updatedSettings = { ...userAdminPermissions };
            updatedSettings.usViewRiskManagement = true;
            updatedSettings.usModifyRiskManagement = true;
            setUserAdminPermissions(updatedSettings);
        } else if (eventValue === 2) {
            const updatedSettings = { ...userAdminPermissions };
            updatedSettings.usViewRiskManagement = false;
            updatedSettings.usModifyRiskManagement = false;
            setUserAdminPermissions(updatedSettings);
        }
    }

    useEffect(() => {
        if (userAdminPermissions.usViewRiskManagement && userAdminPermissions.usModifyRiskManagement) {
            setRiskPermission('1');
        } else if (userAdminPermissions.usViewRiskManagement && !userAdminPermissions.usModifyRiskManagement) {
            setRiskPermission('0');
        } else {
            setRiskPermission('2');
        }
    }, [userAdminPermissions]);

    return (
        <div>
            <div>
                <FormLabel>Risk Management Module</FormLabel>
                <RadioGroup
                    row
                    name='risks-permissions-group'
                    onChange={(event) => {
                        setRiskManagementValues(event.target.value);
                    }}
                    value={RiskPermission.toString()}
                >
                    <FormControlLabel value={'0'} control={<Radio />} label='View' />
                    <FormControlLabel value={'1'} control={<Radio />} label='Modify' />
                    <FormControlLabel value={'2'} control={<Radio />} label='None' />
                </RadioGroup>
                <br/>
                <Box sx={{ height: '50vh', width: '100%', display: 'block' }}>
                    <DataGridPro
                        columns={columns}
                        rows={userAdminPermissions.projectUserXRefs ?? []}
                        getRowId={(row) => row.puId}
                        pagination
                        getRowHeight={() => 'auto'}
                        onCellEditCommit={(params, event) => {
                            const editedProjectUserXRefs = JSON.parse(JSON.stringify(userAdminPermissions.projectUserXRefs));
                            editedProjectUserXRefs.find(x => {
                                return x.puId === params.id;
                            }).puPermissionLevel = event.target.value;
                            handleFieldChange('projectUserXRefs', editedProjectUserXRefs);
                        }}
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'puProjectName', sort: 'asc' }],
                            },
                        }}
                    />
                </Box>
            </div>
            <br/>
        </div>
    );
}

UserPermissionSettings.propTypes = {
    userAdminPermissions: PropTypes.objectOf(UserAdminPermissionsDto),
    setUserAdminPermissions: PropTypes.func.isRequired,
};
