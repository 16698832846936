import authenticationManager from './AuthenticationManager';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function Authorized (props) {
    const navigate = useNavigate();

    const { authorized, setSnackbarProperties } = props;

    useEffect(() => {
        if(!authenticationManager.isAuthorized) {
            navigate('/');
        }
    }, []);

    return (
        <>
            {authenticationManager.isAuthorized && React.cloneElement(props.authorized, {setSnackbarProperties})}
        </>
    );
}

Authorized.propTypes = {
    authorized: PropTypes.element.isRequired,
    setSnackbarProperties: PropTypes.func,
};
