import apiService from './apiService';

export default class CustomColumnApiService {

    async getCustomColumns () {
        const customColumnResponse = await apiService.getData('api/CustomColumn');
        return customColumnResponse.data;
    }

    async addCustomColumn (newCustomColumn) {
        const customColumnResponse = await apiService.postData('api/CustomColumn', newCustomColumn);
        return customColumnResponse.data;
    }

    async updateCustomColumn (updatedCustomColumn) {
        const customColumnResponse = await apiService.putData('api/CustomColumn/' + updatedCustomColumn.customColumnId, updatedCustomColumn);
        return customColumnResponse.data;
    }

    async deleteCustomColumn (deletedCustomColumn) {
        const customColumnResponse = await apiService.deleteData('api/CustomColumn/' + deletedCustomColumn.customColumnId);
        return customColumnResponse.data;
    }
}
