import apiService from './apiService';

export default class HazardApiService {

    /**
     * Get hazard data
     * @param hazardId {string}
     * @return {Promise<ServiceResponse<HazardDto>>}
     */
    async getHazardData(hazardId) {
        const hazardData = await apiService.getData(`api/Hazard/${hazardId}`);

        return hazardData.data;
    }

    /**
     * Get Hazard Control Types
     * @return {Promise<ServiceResponse<Array<ControlTypeDto>>>}
     */
    async getHazardControlTypes() {
        const controlTypes = await apiService.getData('api/Hazard/controlTypes');

        return controlTypes.data;
    }

    /**
     * Get hazard audits for this hazard
     * @param hazardId {string}
     * @return {Promise<ServiceResponse<Array<AuditDto>>>}
     */
    async getHazardAudits(hazardId) {
        const hazardAudits = await apiService.getData(`api/Hazard/hazardAudits?hazardId=${hazardId}`);

        return hazardAudits.data;
    }

    /**
     * Get departments for autocomplete for a project
     * @param projectId {number}
     * @return {Promise<ServiceResponse<Array<string>>>}
     */
    async getAutoCompleteDepartments(projectId) {
        const departments = await apiService.getData(`api/Hazard/project/${projectId}/departments`);

        return departments.data;
    }

    /**
     * Get processes for autocomplete for a project
     * @param projectId {number}
     * @return {Promise<ServiceResponse<Array<string>>>}
     */
    async getAutoCompleteProcesses(projectId) {
        const departments = await apiService.getData(`api/Hazard/project/${projectId}/processes`);

        return departments.data;
    }

    /**
     * Get jobs for autocomplete for a project
     * @param projectId {number}
     * @return {Promise<ServiceResponse<Array<string>>>}
     */
    async getAutoCompleteJobs(projectId) {
        const departments = await apiService.getData(`api/Hazard/project/${projectId}/jobs`);

        return departments.data;
    }

    /**
     * Get tasks for autocomplete for a project
     * @param projectId {number}
     * @return {Promise<ServiceResponse<Array<string>>>}
     */
    async getAutoCompleteTasks(projectId) {
        const departments = await apiService.getData(`api/Hazard/project/${projectId}/tasks`);

        return departments.data;
    }

    /**
     * Get equipment for autocomplete for a project
     * @param projectId {number}
     * @return {Promise<ServiceResponse<Array<string>>>}
     */
    async getAutoCompleteEquipment(projectId) {
        const departments = await apiService.getData(`api/Hazard/project/${projectId}/equipment`);

        return departments.data;
    }

    /**
     * Get OHS Hazards for autocomplete for a project
     * @param projectId {number}
     * @return {Promise<ServiceResponse<Array<string>>>}
     */
    async getAutoCompleteOhsHazards(projectId) {
        const departments = await apiService.getData(`api/Hazard/project/${projectId}/ohs-hazards`);

        return departments.data;
    }

    /**
     * Gets all likelihoods
     * @return {Promise<ServiceResponse<Array<LikelihoodDto>>>}
     */
    async getAllLikelihoods() {
        const likelihoods = await apiService.getData('api/Likelihood');

        return likelihoods.data;
    }

    /**
     * Gets all severities
     * @return {Promise<ServiceResponse<Array<SeverityDto>>>}
     */
    async getAllSeverities() {
        const severities = await apiService.getData('api/Severity');

        return severities.data;
    }

    /**
     * Gets UDFs
     * @return {Promise<ServiceResponse<Array<UserDefinedAttributeDto>>>}
     */
    async getUserDefinedAttributes() {
        const udfs = await apiService.getData('api/UserDefinedAttribute');

        return udfs.data;
    }

    /**
     * Adds a new hazard
     * @param newHazard {HazardDto}
     * @return {Promise<ServiceResponse<HazardDto>>}
     */
    async addNewHazard(newHazard) {
        const response = await apiService.postData('api/Hazard', newHazard);

        return response.data;
    }

    /**
     * Updates a hazard
     * @param updatedHazard {HazardDto}
     * @return {Promise<ServiceResponse<HazardDto>>}
     */
    async updateHazard(updatedHazard){
        const response = await apiService.putData('api/Hazard', updatedHazard);

        return response.data;
    }

    async deleteHazardControl(controlId) {
        const response = await apiService.deleteData(`api/Hazard/hazardControl?id=${controlId}`);

        return response.data;
    }
}
