import apiService from './apiService';

export default class UserApiService {
    /**
     * Gets a list of all users
     * @return {Promise<ServiceResponse<Array<UserDto>>>}
     */
    async getUsers () {
        const taskData = await apiService.getData('api/User');
        return taskData.data;
    }

    /**
     * Gets a list of all users available for a new task
     * @return {Promise<ServiceResponse<Array<BasicUserDto>>>}
     */
    async getUsersForNewTask () {
        const taskData = await apiService.getData('api/User/newTask');
        return taskData.data;
    }

    /**
     * Change User's password
     * @param userId {number}
     * @param newPassword {string}
     * @return {Promise<ServiceResponse<boolean>>>}
     */
    async changeUserPassword (userId, newPassword) {
        const changePasswordResult = await apiService.putData('api/User/ChangePassword/' + userId, newPassword);
        return changePasswordResult.data;
    }

    /**
     * Deactivates the user
     * @param userId {number}
     * @return {Promise<ServiceResponse<UserAdminDto>>}
     */
    async deactivateUser (userId) {
        const deactivateUser = await apiService.postData('api/User/Deactivate/' + userId);
        return deactivateUser.data;
    }

    /**
     * Activates the user
     * @param userId {number}
     * @return {Promise<ServiceResponse<UserAdminDto>>}
     */
    async activateUser (userId) {
        const activateUser = await apiService.putData('api/User/Activate/' + userId);
        return activateUser.data;
    }

    /**
     * Saves user changes
     * @param userData {UserAdminDto}
     * @return {Promise<ServiceResponse<UserAdminDto>>}
     */
    async saveUser (userData) {
        const saveUserResponse = await apiService.putData('api/User/' + userData.usId, userData);
        return saveUserResponse.data;
    }

    /**
     * Adds a new user
     * @param userData {UserAdminDto}
     * @return {Promise<ServiceResponse<UserAdminDto>>}
     */
    async addUser (userData) {
        const addUserResponse = await apiService.postData('api/User', userData);
        return addUserResponse.data;
    }

    /**
     * Gets new ProjectUserXrefs for a new user and org
     * @param orgId {number}
     * @return {Promise<ServiceResponse<ProjectUserXRefDto>>>}
     */
    async getProjectUserXRefsForNewUser (orgId) {
        const newProjectUserXrefs = await apiService.getData('api/User/ProjectXRefsForNewUser/' + orgId);
        return newProjectUserXrefs.data;
    }

    /**
     * Get this specific user
     * @param userId {number}
     * @return {Promise<ServiceResponse<UserAdminDto>>}
     */
    async getThisUser(userId) {
        const thisUser = await apiService.getData(`api/User/${userId}`);

        return thisUser.data;
    }

    /**
     * Get available users for an org
     * @param orgId {number}
     * @return {Promise<ServiceResponse<Array<BasicUserDto>>>}
     */
    async getSelectableUsersForOrg(orgId) {
        const users = await apiService.getData(`api/User/org/${orgId}`);

        return users.data;
    }
}
