import apiService from './apiService';

export default class ResourceApiService {

    /**
     * Get all resources
     * @return {Promise<ServiceResponse<Array<ResourceDto>>>}
     */
    async getResources() {
        const resources = await apiService.getData('api/Resource');

        return resources.data;
    }

    /**
     * Gets all Topics
     * @return {Promise<ServiceResponse<Array<ResourceTopicDto>>>}
     */
    async getTopics() {
        const topics = await apiService.getData('api/Resource/topics');

        return topics.data;
    }

    /**
     * Get all Types
     * @return {Promise<ServiceResponse<Array<ResourceTypeDto>>>}
     */
    async getTypes() {
        const types = await apiService.getData('api/Resource/types');

        return types.data;
    }

    /**
     * Delete a resource
     * @param id {number}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async deleteResource(id) {
        const resourceDeleted = await apiService.deleteData('api/Resource/' + id);

        return resourceDeleted.data;
    }

    /**
     * Add a new resource
     * @param newResource {ResourceDto}
     * @return {Promise<ServiceResponse<int>>}
     */
    async addResource(newResource) {
        const resourceAdded = await apiService.postData('api/Resource', newResource);

        return resourceAdded.data;
    }

    /**
     * Update a resource
     * @param updatedResource {ResourceDto}
     * @return {Promise<ServiceResponse<boolean>>}
     */
    async updateResource(updatedResource) {
        const resourceModified = await apiService.putData('api/Resource', updatedResource);

        return resourceModified.data;
    }

    async addResourceAttachment(file) {
        const formData = new FormData();

        formData.append('fileName', file.name);
        formData.append('id', 0);
        // TODO: When attachments are more mature, the type should be detected and added
        formData.append('attachmentTypeId', file.type.includes('image') ? 1 : 2);
        formData.append('file', file);

        const newResourceAttachment = await apiService.postFormData('api/Resource/attachment', formData);

        return newResourceAttachment.data;
    }

    /**
     * Get a resource
     * @param resourceId {number}
     * @return {Promise<ServiceResponse<ResourceDto>>}
     */
    async getResource(resourceId) {
        const resource = await apiService.getData('api/Resource/' + resourceId);

        return resource.data;
    }



}
