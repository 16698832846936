export const DAILY_TASK = '0';
export const WEEKLY_TASK = '1';
export const MONTHLY_TASK = '2';
export const QUARTERLY_TASK = '3';
export const SEMI_ANNUAL_TASK = '4';
export const ANNUAL_TASK = '5';
export const X_ANNUAL_TASK = '6';

export const NORMAL_QUARTERLY = '100';
export const SPECIAL_QUARTERLY = '200';

function convertDayOfWeekNumberToString(dayOfWeek) {
    switch (dayOfWeek) {
    case '0':
        return 'Sunday';
    case '1':
        return 'Monday';
    case '2':
        return 'Tuesday';
    case '3':
        return 'Wednesday';
    case '4':
        return 'Thursday';
    case '5':
        return 'Friday';
    case '6':
        return 'Saturday';
    default:
        return '';
    }
}

function convertMonthNumberToString(monthOfYear) {
    switch (monthOfYear) {
    case '1':
        return 'January';
    case '2':
        return 'February';
    case '3':
        return 'March';
    case '4':
        return 'April';
    case '5':
        return 'May';
    case '6':
        return 'June';
    case '7':
        return 'July';
    case '8':
        return 'August';
    case '9':
        return 'September';
    case '10':
        return 'October';
    case '11':
        return 'November';
    case '12':
        return 'December';
    }
}

/**
 * Gets the ordinal ending for the number
 * @param day
 * @return {string}
 */
function getOrdinalNumberEnding(day) {
    if(day === '11') {
        return '11th';
    } else if(day === '12') {
        return '12th';
    } else if(day === '13') {
        return '13th';
    } else if(day === '99') {
        return 'Last Day';
    }

    switch (day[day.length-1]) {
    case '1':
        return `${day}st`;
    case '2':
        return `${day}nd`;
    case '3':
        return `${day}rd`;
    case '0':
    case '4':
    case '5':
    case '6':
    case '7':
    case '8':
    case '9':
        return `${day}th`;
    default:
        return '';
    }
}

/**
 * Returns the description of the recurrence into a human-readable format
 * @param recurrenceString {string}
 * @return {string}
 */
export function convertRecurrenceStringToDescription(recurrenceString) {
    const splitRecurrenceString = recurrenceString.split('|');
    const startOfDescription = 'Reoccurs';
    if(splitRecurrenceString[0] === DAILY_TASK) {
        return `${startOfDescription} Daily`;
    } else if(splitRecurrenceString[0] ===  WEEKLY_TASK) {
        return `${startOfDescription} every ${convertDayOfWeekNumberToString(splitRecurrenceString[1])} (Weekly)`;
    } else if(splitRecurrenceString[0] ===  MONTHLY_TASK) {
        return `${startOfDescription} every ${getOrdinalNumberEnding(splitRecurrenceString[1])} of the Month`;
    } else if(splitRecurrenceString[0] ===  QUARTERLY_TASK) {
        if(splitRecurrenceString[1] ===  NORMAL_QUARTERLY) {
            return `${startOfDescription} on the ${getOrdinalNumberEnding(splitRecurrenceString[3])} day on the ${getOrdinalNumberEnding(splitRecurrenceString[2])} month every Quarter`;
        } else if(splitRecurrenceString[1] ===  SPECIAL_QUARTERLY) {
            return `${startOfDescription} every ${convertDayOfWeekNumberToString(splitRecurrenceString[3])} of the ${getOrdinalNumberEnding(splitRecurrenceString[2])} week in the ${getOrdinalNumberEnding(splitRecurrenceString[4])} month every Quarter`;
        }
    } else if(splitRecurrenceString[0] ===  SEMI_ANNUAL_TASK) {
        return `${startOfDescription} every ${getOrdinalNumberEnding(splitRecurrenceString[2])} day of the ${getOrdinalNumberEnding(splitRecurrenceString[1])} month in a Semi-Annual period`;
    } else if(splitRecurrenceString[0] ===  ANNUAL_TASK) {
        return `${startOfDescription} on the ${getOrdinalNumberEnding(splitRecurrenceString[2])} of ${convertMonthNumberToString(splitRecurrenceString[1])} every Year`;
    } else if(splitRecurrenceString[0] ===  X_ANNUAL_TASK) {
        return `${startOfDescription} on the ${getOrdinalNumberEnding(splitRecurrenceString[2])} of${convertMonthNumberToString(splitRecurrenceString[1])} every ${splitRecurrenceString[3]}} Years`;
    }
}
