export default class CategoryDto {
    /**
     * @type{number}
     * */
    ctId;

    /**
     * @type{string}
     */
    ctName;

    /**
     * @type{number}
     */
    ctSortSeq;

    /**
     * @type{boolean}
     */
    recurring;

    constructor () {
        this.ctId = 0;
        this.ctName = '';
        this.ctSortSeq = 0;
    }
}
