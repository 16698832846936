import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { UserAdminEmailNotificationsDto } from '../../../../dtos/Users/UserAdminEmailNotificationsDto';

export default function UserEmailNotificationSettings (props) {
    const {
        userAdminEmailNotification, setNewUserAdminEmailNotification, applyNotificationChangesRetroactively, setApplyNotificationChangesRetroactively,
    } = props;

    const handleFieldChange = (field, value) => {
        const updatedSettings = { ...userAdminEmailNotification };

        updatedSettings[field] = value;

        setNewUserAdminEmailNotification(updatedSettings);
    };

    return (
        <div>
            <div>
                <FormControlLabel
                    control={<Checkbox
                        checked={userAdminEmailNotification.usEnableNotifications}
                        onChange={() => handleFieldChange('usEnableNotifications', !userAdminEmailNotification.usEnableNotifications === true ? 1 : 0) }
                    />}
                    label="Enable notifications"
                />
            </div>
            <br/>
            <div>
                <FormControlLabel
                    control={<Checkbox
                        disabled={!userAdminEmailNotification.usEnableNotifications}
                        checked={userAdminEmailNotification.usAutoSubscribe}
                        onChange={() => handleFieldChange('usAutoSubscribe', !userAdminEmailNotification.usAutoSubscribe === true ? 1 : 0)}
                    />}
                    label="Auto-subscribe to all items"
                />
            </div>
            <br/>
            <div>
                <FormControlLabel
                    control={<Checkbox
                        disabled={!userAdminEmailNotification.usEnableNotifications}
                        checked={userAdminEmailNotification.usAutoSubscribeOwnTasks}
                        onChange={() => handleFieldChange('usAutoSubscribeOwnTasks', !userAdminEmailNotification.usAutoSubscribeOwnTasks === true ? 1 : 0)}
                    />}
                    label="Auto-subscribe to Tasks Assigned To you"
                />
            </div>
            <br/>
            <div>
                <FormControlLabel
                    control={<Checkbox
                        disabled={!userAdminEmailNotification.usEnableNotifications}
                        checked={userAdminEmailNotification.usAutoSubscribeReportedTasks}
                        onChange={() => handleFieldChange('', !userAdminEmailNotification.usAutoSubscribeReportedTasks === true ? 1 : 0)}
                    />}
                    label="Auto-subscribe to Tasks Reported By you"
                />
            </div>
            <br/>
            <div>
                <FormControlLabel
                    control={<Checkbox
                        disabled={!userAdminEmailNotification.usEnableNotifications}
                        checked={userAdminEmailNotification.usAutoSubscribeVerifierTasks}
                        onChange={() => handleFieldChange('', !userAdminEmailNotification.usAutoSubscribeVerifierTasks === true ? 1 : 0)}
                    />}
                    label="Auto-subscribe to Tasks where you are the Verifier"
                />
            </div>
            <br/>
            <div>
                <FormControlLabel
                    control={<Checkbox
                        disabled={!userAdminEmailNotification.usEnableNotifications}
                        checked={userAdminEmailNotification.usAutoSubscribeAuditorTasks}
                        onChange={() => handleFieldChange('', !userAdminEmailNotification.usAutoSubscribeAuditorTasks === true ? 1 : 0) }
                    />}
                    label="Auto-subscribe to Tasks where you are the Auditor"
                />
            </div>
            <br/>
            <div>
                <FormControlLabel
                    control={<Checkbox
                        checked={applyNotificationChangesRetroactively}
                        onChange={() => setApplyNotificationChangesRetroactively(!applyNotificationChangesRetroactively) }
                    />}
                    label="Retroactively Apply on save"
                />
            </div>
            <br/>
        </div>

    );
}

UserEmailNotificationSettings.propTypes = {
    userAdminEmailNotification: PropTypes.objectOf(UserAdminEmailNotificationsDto),
    setNewUserAdminEmailNotification: PropTypes.func.isRequired,
    applyNotificationChangesRetroactively: PropTypes.bool.isRequired,
    setApplyNotificationChangesRetroactively: PropTypes.func.isRequired,

};
