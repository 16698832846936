import apiService from './apiService';

export default class AuditChecklistHistoryApiService {

    async getAuditChecklistHistory (checklistId) {
        const auditChecklistResponse = await apiService.getData('api/AuditChecklistHistory/' + checklistId);
        return auditChecklistResponse.data;
    }

    async addAuditChecklistHistory (newAuditChecklist) {
        const addAuditChecklistResponse = await apiService.postData('api/AuditChecklistHistory', newAuditChecklist);
        return addAuditChecklistResponse.data;
    }
}
