import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import TaskIcon from '@mui/icons-material/Task';
import WarningIcon from '@mui/icons-material/Warning';
import PaddingIcon from '@mui/icons-material/Padding';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import authenticationManager from './auth/AuthenticationManager';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { Category, PriorityHigh } from '@mui/icons-material';
import SpeedIcon from '@mui/icons-material/Speed';
import ChecklistIcon from '@mui/icons-material/Checklist';
import NewspaperIcon from '@mui/icons-material/Newspaper';

const sidebarColor = '#4D5061';
const sidebarHoverColor = '#65687c';
const iconColor = '#FFFFFF';
function CTSidebar (props) {
    const { collapseSidebar, collapsed } = useProSidebar();
    const navigate = useNavigate();

    const [isAdminMenuExpanded, setIsAdminMenuExpanded] = useState(false);

    useEffect(() => {
        collapseSidebar(props.navMenuCollapsed);
    }, [props.navMenuCollapsed]);

    const handleMenuItemClick = (menuItem) => {
        navigate(menuItem);
    };

    return (
        <div style={{ display: 'flex', minHeight: '97vh'}}>
            <Sidebar
                backgroundColor={sidebarColor}
                sx={{ height: '100%', marginTop: '5vh'}}
            >
                <Menu
                    menuItemStyles={{
                        button: {
                            backgroundColor: sidebarColor,
                            '&:hover': {
                                backgroundColor: sidebarHoverColor,
                            },
                        },
                    }}
                >
                    <MenuItem
                        icon={<TaskIcon sx={{ color: iconColor }} />}
                        onClick={() => handleMenuItemClick('/Tasks')}
                        style={{ color: iconColor }}
                    >
                        Tasks
                    </MenuItem>
                    <MenuItem
                        icon={<WarningIcon sx={{ color: iconColor }} />}
                        onClick={() => handleMenuItemClick('/Hazards')}
                        style={{ color: iconColor }}
                    >
                        Risks
                    </MenuItem>
                    {authenticationManager.isAuthorized && (authenticationManager.isSuperAdmin() || authenticationManager.isAdmin()) &&
                        <MenuItem
                            icon={<PaddingIcon sx={{ color: iconColor }} />}
                            onClick={() => handleMenuItemClick('/Queries')}
                            style={{ color: iconColor }}
                        >
                            Queries
                        </MenuItem>
                    }
                    <MenuItem
                        icon={<ChecklistIcon sx={{ color: iconColor }} />}
                        onClick={() => handleMenuItemClick('/AuditChecklists')}
                        style={{ color: iconColor }}
                    >
                        Audit Checklists
                    </MenuItem>
                    <MenuItem
                        icon={<NewspaperIcon sx={{ color: iconColor }} />}
                        onClick={() => handleMenuItemClick('/resources')}
                        style={{ color: iconColor }}
                    >
                        Resources
                    </MenuItem>
                    {(authenticationManager.isAdmin() || authenticationManager.isSuperAdmin()) &&
                        <SubMenu label="Admin" icon={<AdminPanelSettingsIcon/>} style={{ color: iconColor }} open={isAdminMenuExpanded} onClick={() => setIsAdminMenuExpanded(!isAdminMenuExpanded)}>
                            <MenuItem
                                active={isAdminMenuExpanded}
                                icon={<SupervisorAccountIcon sx={{ color: iconColor }}/>}
                                onClick={() => {
                                    handleMenuItemClick('/admin/Users');
                                    setIsAdminMenuExpanded(collapsed ? !isAdminMenuExpanded : isAdminMenuExpanded);
                                }}
                                style={{ color: iconColor }}
                            >
                                Users
                            </MenuItem>
                            {authenticationManager.isSuperAdmin() &&
                                <MenuItem
                                    icon={<LocationCityIcon sx={{ color: iconColor }}/>}
                                    onClick={() => handleMenuItemClick('/admin/Organizations')}
                                    style={{ color: iconColor }}
                                >
                                    Organizations
                                </MenuItem>
                            }
                            {authenticationManager.isSuperAdmin() &&
                                <MenuItem
                                    icon={<Category sx={{ color: iconColor }}/>}
                                    onClick={() => handleMenuItemClick('/admin/Categories')}
                                    style={{ color: iconColor }}
                                >
                                    Categories
                                </MenuItem>
                            }
                            {authenticationManager.isSuperAdmin() &&
                                <MenuItem
                                    icon={<PriorityHigh sx={{ color: iconColor }}/>}
                                    onClick={() => handleMenuItemClick('/admin/Priorities')}
                                    style={{ color: iconColor }}
                                >
                                    Priorities
                                </MenuItem>
                            }
                            {authenticationManager.isSuperAdmin() &&
                                <MenuItem
                                    icon={<SpeedIcon sx={{ color: iconColor }}/>}
                                    onClick={() => handleMenuItemClick('/admin/Statuses')}
                                    style={{ color: iconColor }}
                                >
                                    Statuses
                                </MenuItem>
                            }
                            {authenticationManager.isSuperAdmin() &&
                                <MenuItem
                                    icon={<LocationOnIcon sx={{ color: iconColor }}/>}
                                    onClick={() => handleMenuItemClick('/admin/Locations')}
                                    style={{ color: iconColor }}
                                >
                                    Locations
                                </MenuItem>
                            }
                            { authenticationManager.isSuperAdmin() &&
                                <MenuItem
                                    icon={<ViewColumnIcon sx={{ color: iconColor }}/>}
                                    onClick={() => handleMenuItemClick('/admin/CustomColumns')}
                                    style={{ color: iconColor }}
                                >
                                    Custom Columns
                                </MenuItem>
                            }
                            { authenticationManager.isSuperAdmin() &&
                                <MenuItem
                                    icon={<ChecklistIcon sx={{ color: iconColor }}/>}
                                    onClick={() => handleMenuItemClick('/admin/AuditChecklistAdmin')}
                                    style={{ color: iconColor }}
                                >
                                    Audit Checklists
                                </MenuItem>
                            }
                        </SubMenu>
                    }
                </Menu>
            </Sidebar>
        </div>
    );
}

CTSidebar.propTypes = {
    navMenuCollapsed: PropTypes.bool.isRequired,
};

export default CTSidebar;
