import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import CTSidebar from '../CTSidebar';
import { ProSidebarProvider } from 'react-pro-sidebar';
import PropTypes from 'prop-types';
import localSettingsManager from '../localSettings/localSettingsManager';
import * as settingConstants from '../constants/settingConstants';
import authenticationManager from '../auth/AuthenticationManager';

const Layout = (props) => {
    const [navMenuCollapsed, setNavMenuCollapsed] = useState(localSettingsManager.getSettingValue(settingConstants.navBarCollapsedSetting, false) === 'true');
    const { setSnackbarProperties, children } = props;
    const [isAuthorized, setIsAuthorized] = useState(authenticationManager.isAuthorized);
    function toggleNavMenu () {
        setNavMenuCollapsed(!navMenuCollapsed);
        localSettingsManager.setSettingValue(settingConstants.navBarCollapsedSetting, !navMenuCollapsed);
    }

    useEffect(() => {
        const updateAuthorizationStatus = () => {
            setIsAuthorized(authenticationManager.isAuthorized);
        };

        // Subscribe to changes in the isAuthorized property
        authenticationManager.subscribe(updateAuthorizationStatus);

        // Clean up the subscription when the component unmounts
        return () => {
            authenticationManager.unsubscribe(updateAuthorizationStatus);
        };
    }, []);

    return (<div>
        {isAuthorized && <NavMenu toggleNavMenu={toggleNavMenu} setSnackbarProperties={setSnackbarProperties} />}
        <div style={{display:'flex'}}>
            {isAuthorized && <ProSidebarProvider>
                <CTSidebar navMenuCollapsed={navMenuCollapsed} />
            </ProSidebarProvider> }
            <div style={{ flex: 1 }}>
                <Container tag={'main'}>
                    {children}
                </Container>
            </div>
        </div>
    </div>);
};

Layout.propTypes = {
    children: PropTypes.object.isRequired,
};

export default Layout;
