import React, { useEffect, useState } from 'react';
import { Box, Button, Grid } from '@mui/material';
import AuditChecklistApiService from '../api/AuditChecklistApiService';
import StartAuditChecklistModal from './Modal/StartAuditChecklistModal';
import AuditChecklistHistoryModal from './Modal/AuditChecklistHistoryModal';

const auditChecklistApiService = new AuditChecklistApiService();

const AuditChecklists = (props) => {
    const { setSnackbarProperties } = props;
    const [auditChecklists, setAuditChecklists] = useState([]);
    const [selectedAuditChecklist, setSelectedAuditChecklist] = useState({});
    const [openStartChecklistModal, setOpenStartChecklistModal] = useState(false);
    const [openAuditChecklistHistoryModal, setOpenAuditChecklistHistoryModal] = useState(false);

    useEffect(() => {
        auditChecklistApiService.getAuditChecklists().then((auditChecklistResponse) => {
            setAuditChecklists(auditChecklistResponse.data);
        });
    }, []);

    function handleHistoryClick(checklist) {
        setSelectedAuditChecklist(checklist);
        setOpenAuditChecklistHistoryModal(true);
    }

    function handleStartClick(checklist) {
        setSelectedAuditChecklist(checklist);
        setOpenStartChecklistModal(true);
    }

    return (
        <div style={{ marginTop: '16px' }}>
            <h1>Audit Checklists</h1>
            <Grid container spacing={3}>
                {auditChecklists.map((checklist, index) => (
                    <Grid key={index} item xs={12} md={6}>
                        <Box key={checklist.acId} border={1} borderRadius={4} p={2} mb={2}>
                            <h2 style={{ fontWeight: 'bold' }}>
                                {checklist.acTitle}
                            </h2>
                            <h4>{checklist.projectName}</h4>
                            <p style={{ fontSize: '14px', color: 'gray' }}>
                                {checklist.auditChecklistItems.length} items
                            </p>
                            <Button
                                size="large"
                                variant="contained"
                                onClick={() => {
                                    handleHistoryClick(checklist);
                                }}
                                sx={{ marginRight: '16px' }}
                            >
                                History
                            </Button>
                            <Button
                                size="large"
                                variant="contained"
                                onClick={() => {
                                    handleStartClick(checklist);
                                }}
                            >
                                Start &gt;
                            </Button>
                        </Box>
                    </Grid>
                ))}
            </Grid>
            <StartAuditChecklistModal
                open={openStartChecklistModal}
                onCancel={() => {
                    setOpenStartChecklistModal(false);
                }}
                currentAuditChecklist={selectedAuditChecklist}
                setSnackbarProperties={setSnackbarProperties}
                onSave={() => {
                    setOpenStartChecklistModal(false);
                }}
            />
            <AuditChecklistHistoryModal
                open={openAuditChecklistHistoryModal}
                onCancel={() => {
                    setOpenAuditChecklistHistoryModal(false);
                }}
                currentSelectedAuditChecklist={selectedAuditChecklist}
            />
        </div>
    );
};

export default AuditChecklists;
